/**Fonts**/

@font-face {
  font-family: 'icomoon';
  src: url('~assets/fonts/icomoon.eot?ig1nm4');
  src: url('~assets/fonts/icomoon.eot?ig1nm4#iefix') format('embedded-opentype'),
    url('~assets/fonts/icomoon.ttf?ig1nm4') format('truetype'),
    url('~assets/fonts/icomoon.woff?ig1nm4') format('woff'),
    url('~assets/fonts/icomoon.svg?ig1nm4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/****/

.img-bg-box {
  background: #bcbcbc;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
}

.read-more {
  color: #8ffe26 !important;
  font-size: 12px;
}

.footer-cookies {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $max-tab) {
    display: block;
  }

  p {
    a {
      color: #8ffe26;
      text-decoration: underline;
    }
  }
}

body {
  font-family: $popins;
  font-size: 14px;
  color: $light-black;
  position: relative;
  padding: 100px 0 0;

  @media screen and (max-width: $tab) {
    padding: 60px 0 0;
  }
}

.body-height {
  min-height: calc(100vh - 151px);

  @media screen and (max-width: $min-tab) {
    min-height: calc(100vh - 118px);
  }

  @media screen and (max-width: $tab) {
    min-height: calc(100vh - 149px);
  }

  @media screen and (max-width: $mob) {
    min-height: calc(100vh - 135px);
  }
}

a:focus,
*:focus {
  outline: none;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border-top: 1px solid $br-color;
}

ul {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    list-style: none;
  }
}

.icon-info:before {
  content: '\e900';
}

.icon-angle-arrow-down:before {
  content: '\e901';
}

.icon-arrow:before {
  content: '\e902';
}

.icon-calendar:before {
  content: '\e903';
}

.icon-check:before {
  content: '\e904';
}

.icon-clock:before {
  content: '\e905';
}

.icon-close:before {
  content: '\e906';
}

.icon-close-circle:before {
  content: '\e907';
}

.icon-correct:before {
  content: '\e908';
}

.icon-doller:before {
  content: '\e909';
}

.icon-drop-down:before {
  content: '\e90a';
}

.icon-edit:before {
  content: '\e90b';
}

.icon-email:before {
  content: '\e90c';
}

.icon-facebook:before {
  content: '\e90d';
}

.icon-fancy-loader:before {
  content: '\e90e';
}

.icon-file-download:before {
  content: '\e90f';
}

.icon-filter:before {
  content: '\e910';
}

.icon-filter-line:before {
  content: '\e911';
}

.icon-forword:before {
  content: '\e912';
}

.icon-google-plus:before {
  content: '\e913';
}

.icon-gps:before {
  content: '\e914';
}

.icon-heart:before {
  content: '\e915';
}

.icon-heart-fill:before {
  content: '\e916';
}

.icon-hide-pass:before {
  content: '\e917';
}

.icon-home:before {
  content: '\e918';
}

.icon-instagram:before {
  content: '\e919';
}

.icon-loader:before {
  content: '\e91a';
}

.icon-locality:before {
  content: '\e91b';
}

.icon-mobile:before {
  content: '\e91c';
}

.icon-more:before {
  content: '\e91d';
}

.icon-next-arrow:before {
  content: '\e91e';
}

.icon-password:before {
  content: '\e91f';
}

.icon-phone:before {
  content: '\e920';
}

.icon-pin:before {
  content: '\e921';
}

.icon-place:before {
  content: '\e922';
}

.icon-play:before {
  content: '\e923';
}

.icon-refresh:before {
  content: '\e924';
}

.icon-search:before {
  content: '\e925';
}

.icon-send:before {
  content: '\e926';
}

.icon-share:before {
  content: '\e927';
}

.icon-ship:before {
  content: '\e928';
}

.icon-show-pass:before {
  content: '\e929';
}

.icon-star:before {
  content: '\e92a';
}

.icon-state:before {
  content: '\e92b';
}

.icon-trash:before {
  content: '\e92c';
}

.icon-twitter:before {
  content: '\e92d';
}

.icon-up-arrow:before {
  content: '\e92e';
}

.icon-upload-video:before {
  content: '\e92f';
}

.icon-user:before {
  content: '\e930';
}

.icon-user-b:before {
  content: '\e931';
}

.icon-video:before {
  content: '\e932';
}

.icon-view:before {
  content: '\e933';
}

.icon-view-product:before {
  content: '\e934';
}

.icon-www:before {
  content: '\e935';
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

/**Header**/

header {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  padding: 10px 0;
  display: flex;
  align-items: center;
  background: $light-black;
  z-index: 9;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  @media screen and (max-width: $tab) {
    padding: 10px 0 0;
    height: auto;
    background: $light-black;
    box-shadow: 0 20px 30px rgba($black, 0.08);
  }

  .container {
    @media screen and (max-width: $tab) {
      max-width: 100% !important;
      padding: 0px 0px;
    }
  }

  &.fixed-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    background: $light-black;
    padding: 0 0;
    min-height: inherit;
    height: auto;
    box-shadow: 0 0 20px rgb($black, 0.08);

    @media screen and (max-width: $tab) {
      padding: 10px 0 0;
    }

    .navbar {
      .navbar-brand {
        img {
          max-width: 150px;
          max-height: 65px;

          @media screen and (max-width: $tab) {
            max-width: 80px;
            max-height: 55px;
          }
        }
      }

      ul {
        li {
          a {
            color: $white !important;
            font-size: 15.3px;

            &.login-btn {
              margin-left: 15px;

              @media screen and (max-width: $tab) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .navbar {
    padding: 0;

    .mobile-header {
      line-height: 0;

      @media screen and (max-width: $tab) {
        max-width: 95%;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px 15px 10px;
      }
    }

    .navbar-brand {
      padding: 0;
      margin: 0;
      width: 200px;

      &:focus {
        box-shadow: none;
        outline: none;
      }

      @media screen and (max-width: $tab) {
        padding: 0;
        margin: 0;
      }

      @media screen and (max-width: $min-tab) {
        width: 50px;
      }

      .image-ratio1-1 {
        transition: all 150ms ease-in-out;
        -webkit-transition: all 150ms ease-in-out;
      }

      img {
        max-width: 200px;
        max-height: 80px;
        -webkit-transition: all 0.2s ease-in-out 0s;
        -moz-transition: all 0.2s ease-in-out 0s;
        transition: all 0.2s ease-in-out 0s;

        @media screen and (max-width: $tab) {
          max-height: 70px;
        }

        @media screen and (max-width: $min-tab) {
          max-height: 50px;
        }

        @media screen and (max-width: $mob) {
          max-height: 48px;
        }

        &.logo-sroll {
          display: none;

          @media screen and (max-width: $tab) {
            display: block;
          }
        }
      }
    }

    .navbar-toggler {
      background: transparent;
      border: none;
      padding: 0;
      border-radius: 0;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      span.navbar-icon {
        background: $white;
        height: 4px;
        width: 35px;
        display: block;
        border-radius: 5px;

        @media screen and (max-width: $tab) {
          height: 2px;
          width: 31px;
        }

        @media screen and (max-width: $min-tab) {
          height: 1px;
          width: 25px;
        }

        &.two {
          margin: 5px 0;
        }
      }
    }

    .navbar-collapse {
      .collapse {
        .navbar-nav {
          @media screen and (max-width: $tab) {
            display: none;
          }
        }
      }
    }

    .navbar-collapse {
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $white;

            @media screen and (max-width: $tab) {
              text-align: left;
            }
          }
        }
      }

      @media screen and (max-width: $tab) {
        background: #1d252d;
        padding: 15px 35px !important;
        box-shadow: none;
      }

      @media screen and (max-width: $min-tab) {
        padding: 15px 25px !important;
      }
    }

    ul {
      &.navbar-nav {
        @media screen and (max-width: $tab) {
          display: inherit;
        }
      }

      li {
        margin: 0;

        &.dropdown {
          padding: 0;

          @media screen and (max-width: $tab) {
            margin-top: 0;
          }

          .dropdown-toggle {
            &:hover {
              background: transparent !important;
              color: $white !important;
              text-decoration: none;

              @media screen and (max-width: $tab) {
                color: $white !important;
              }
            }

            @media screen and (max-width: $tab) {
              padding: 0;
            }
          }
        }

        .nav-profile {
          width: 35px;
          overflow: hidden;
          border-radius: 50%;
        }

        .nav-name {
          p {
            margin: 0;
          }
        }

        .dropdown-menu {
          left: auto !important;
          right: 0 !important;
          padding: 4px 0;
          min-width: 120px;
          border-radius: 7px;
          border: none;
          box-shadow: 0px 0px 5px rgba($black, 0.08);
          top: 50px;

          &:after {
            content: '';
            height: 10px;
            width: 10px;
            position: absolute;
            background: $white;
            right: 15px;
            top: -5px;
            z-index: -1;
            transform: rotate(45deg);

            @media screen and (max-width: $tab) {
              display: none;
            }
          }

          @media screen and (max-width: $tab) {
            padding: 0 0 0 20px;
            width: 100%;
            border: none;
            background: transparent;
            box-shadow: none;
          }

          h4 {
            font-size: 16px;
            margin: 0 0 5px;
          }

          p {
            font-size: 13px;
            margin: 0;
            padding-bottom: 8px;
            border-bottom: 1px solid $gray-25;
            white-space: nowrap;
          }

          li {
            margin: 0;

            a {
              padding: 5px 15px !important;
              color: $light-black !important;
              font-size: 13px;
              -webkit-transition: all 0.2s ease-in-out 0s;
              -moz-transition: all 0.2s ease-in-out 0s;
              transition: all 0.2s ease-in-out 0s;

              @media screen and (max-width: $tab) {
                padding: 6px 0 !important;
                color: $white !important;
              }

              &.active {
                background: $green-th !important;

                @media screen and (max-width: $tab) {
                  color: $green-th !important;
                  background: transparent !important;
                }
              }

              &:hover,
              &:active,
              &:focus {
                color: $white;
                cursor: pointer;
                outline: none;
                background: $green-th;

                @media screen and (max-width: $tab) {
                  color: $green-th !important;
                  background: transparent;
                }
              }
            }
          }
        }

        a {
          font-size: 18px;
          font-weight: 400;
          color: $white !important;
          padding: 8px 10px !important;
          letter-spacing: 0.2px;
          -webkit-transition: all 0.2s ease-in-out 0s;
          -moz-transition: all 0.2s ease-in-out 0s;
          transition: all 0.2s ease-in-out 0s;

          @media screen and (max-width: $min-desk) {
            font-size: 16px;
          }

          @media screen and (max-width: $tab) {
            padding: 5px 0 !important;
            color: $white !important;
            font-size: 14px;
          }

          &.login-btn {
            background: #fd9902;
            border-radius: 35px;
            padding: 5px 25px !important;
            display: block;
            font-size: 16px;
            font-weight: 500;
            box-shadow: 0 5px 5px rgba($black, 0.15);
            color: $white !important;
            margin-left: 30px;

            &:hover {
              background: $green-th;
              color: $light-black !important;

              @media screen and (max-width: $tab) {
                background: transparent;
                color: $green-th !important;
              }
            }

            @media screen and (max-width: $tab) {
              margin-left: 0;
              background: transparent;
              padding: 5px 0 !important;
              box-shadow: none;
              font-size: 14px;
              color: #fff !important;
              font-weight: 400;
            }
          }

          &:hover {
            color: $green-th !important;

            .caret {
              color: $teal !important;
            }

            @media screen and (max-width: $tab) {
              position: relative;
            }
          }

          &.active {
            position: relative;
            color: $green-th !important;

            @media screen and (max-width: $tab) {
              background: transparent;
              color: $green-th !important;
            }

            &:before {
              content: '';
              height: 1px;
              background: $green-th;
              position: absolute;
              bottom: 0;
              left: 0;
              width: calc(100% - 0px);
              margin: 0 0px;

              @media screen and (max-width: $tab) {
                margin: 0;
                width: 100%;
                height: 0;
              }
            }
          }
        }
      }
    }
  }
}

/****/

.my_radio {
  position: relative;

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
  }

  input[type='radio']:checked~.radio::before {
    background: $green-th;
  }

  input[type='radio']:checked~.radio {
    background: $cyan;
  }

  input[type='checkbox']:checked~.check,
  input[type='radio']:checked~.check {
    border: 1px solid #0DC0DD;
    background: #0DC0DD;
  }

  input[type='checkbox']:checked~.radio {
    background: $cyan;

    &::before {
      background: #0DC0DD;
    }
  }

  input[type='checkbox']:checked~.check::before,
  input[type='radio']:checked~.check::before {
    border-color: $white;
    opacity: 1;
  }

  label {
    display: inline-block;
    white-space: nowrap;
    position: relative;
    font-size: 14px;
    padding: 0 0 0 30px;
    height: 20px;
    color: $black;
    font-weight: 400;
    margin: 0;
    z-index: 6;
    cursor: pointer;
    transition: all 0.25s linear;
    -webkit-transition: all 0.25s linear;

    @media screen and (max-width: $min-mob) {
      font-size: 12px;
    }
  }

  .check {
    display: block;
    position: absolute;
    border: 1px solid $gray-100;
    background: $white;
    border-radius: 3px;
    height: 20px;
    width: 20px;
    top: 0;
    left: 2px;
    z-index: 4;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;

    @media screen and (max-width: $mob) {
      height: 16px;
      width: 16px;
    }

    &::before {
      display: block;
      position: absolute;
      content: '';
      width: 7px;
      height: 14px;
      border-right: 2px solid rgba($gray-500, 0.5);
      border-bottom: 2px solid rgba($gray-500, 0.5);
      transform: rotate(45deg);
      left: 6px;
      top: 0;
      opacity: 0;
      transition: all ease-in 150ms;
      -webkit-transition: all ease-in 150ms;

      @media screen and (max-width: $mob) {
        width: 5px;
        height: 10px;
        left: 5px;
        top: 1px;
      }
    }
  }

  .radio {
    display: block;
    position: absolute;
    background: $gray-500;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    top: 2px;
    left: 2px;
    z-index: 4;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;

    &::before {
      display: block;
      content: '';
      text-align: center;
      height: 11px;
      width: 11px;
      background: #b2b2b2;
      margin: 0 auto;
      left: 0;
      top: 6px;
      right: 0;
      position: absolute;
      border-radius: 20px;
      transition: all ease-in 150ms;
      -webkit-transition: all ease-in 150ms;
    }
  }
}

/****/

.slide-banner {
  img {
    position: absolute;
    top: 0;
    bottom: 0px;
    max-width: 100%;
    max-height: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }

  @media screen and (max-width: $min-tab) {
    height: 100%;
    background-size: 100%;
  }

  @media screen and (max-width: $mob) {
    padding-bottom: 0px;
  }

  &:after {
    @media screen and (max-width: $min-desk) {
      transform: skew(0deg, -1.5deg);
      height: 80px;
      bottom: -48px;
    }

    @media screen and (max-width: $tab) {
      height: 40px;
      bottom: -22px;
    }

    @media screen and (max-width: $mob) {
      display: none;
    }
  }

  .banner-content {
    left: 0;
    right: 0;
    max-width: 500px;
    margin: 0px auto;
    position: absolute;
    bottom: 75px;

    @media screen and (min-width: $desktop) {
      margin-bottom: 45px;
    }

    @media screen and (max-width: $tab) {
      max-width: 440px;
    }

    @media screen and (max-width: $min-tab) {
      padding: 0 15px;
      bottom: 50px;
    }

    @media screen and (max-width: $mob) {
      bottom: 35px;
    }

    @media screen and (max-width: $m-mob) {
      bottom: 15px;
    }

    .icon-search {
      top: 23px;
      left: 17px;

      @media screen and (max-width: $min-desk) {
        top: 17px;
        left: 15px;
      }

      @media screen and (max-width: $tab) {
        top: 17px;
      }

      @media screen and (max-width: $min-tab) {
        top: 12px;
      }

      @media screen and (max-width: $mob) {
        top: 8px;
      }
    }

    .serch-in {
      max-width: 500px;
      width: 100%;
      margin: 10px auto 0px;

      @media screen and (max-width: $min-tab) {
        max-width: 400px;
        width: 100%;
      }

      input {
        padding: 20px 50px;

        @media screen and (max-width: $min-desk) {
          padding: 16px 50px;
        }

        @media screen and (max-width: $tab) {
          padding: 15px 50px 15px 50px;
        }

        @media screen and (max-width: $min-tab) {
          padding: 10px 50px 10px 50px;
        }

        @media screen and (max-width: $mob) {
          padding: 7px 50px 7px 50px;
        }
      }
    }

    h1 {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 0;
      font-weight: bold;
      text-align: center;
      color: $green-th;

      @media screen and (max-width: $tab) {
        font-size: 30px;
        line-height: 26px;
      }

      @media screen and (max-width: $min-tab) {
        font-size: 25px;
        line-height: 26px;
      }

      @media screen and (max-width: $mob) {
        line-height: 18px;
        font-size: 18px;
      }
    }

    h2 {
      font-size: 40px;
      line-height: 55px;
      margin-bottom: 25px;
      font-weight: bold;
      color: $black;
      text-align: center;

      @media screen and (max-width: $tab) {
        font-size: 30px;
      }

      @media screen and (max-width: $min-tab) {
        font-size: 25px;
        line-height: 40px;
        margin: 0 0 10px;
      }

      @media screen and (max-width: $mob) {
        margin: 0px;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}

.serch-in {
  position: relative;
  max-width: 485px;
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: $tab) {
    max-width: 400px;
  }

  @media screen and (max-width: $min-tab) {
    max-width: 360px;
  }

  @media screen and (max-width: $mob) {
    position: relative;
  }

  @media screen and (max-width: $min-mob) {
    margin: 0 auto;
    max-width: 360px;
  }

  span.icon-close {
    position: absolute;
    // right: 20px;
    top: 22px;
    left: 25px;
    font-size: 17px;
    color: #2c2c2c;
    cursor: pointer;

    @media screen and (max-width: $min-desk) {
      top: 22px;
      left: 20px;
    }

    @media screen and (max-width: $tab) {
      // right: 16px;
      font-size: 17px;
      top: 18px;
      left: 23px;
    }

    @media screen and (max-width: $min-tab) {
      // right: 15px;
      top: 11px;
      left: 19px;
    }

    @media screen and (max-width: $mob) {
      left: 11px;
      font-size: 13px;
      top: 14px;
    }
  }

  span.icon-search {
    font-size: 22px;
    color: rgba($light-black, 0.36);
    position: absolute;
    top: 18px;
    left: 20px;

    @media screen and (max-width: $tab) {
      top: 16px;
      left: 20px;
    }

    @media screen and (max-width: $min-tab) {
      left: 15px;
      top: 11px;
    }
  }

  span.icon-arrow {
    font-size: 18px;
    color: $light-black;
    position: absolute;
    top: 14px;
    right: 14px;
    height: 38px;
    width: 38px;
    border-radius: 28px;
    background: $green-th;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 32px;

    @media screen and (max-width: $min-desk) {
      top: 10px;
      right: 10px;
    }

    @media screen and (max-width: $tab) {
      top: 10px;
      right: 10px;
    }

    @media screen and (max-width: $min-tab) {
      top: 4px;
      right: 4px;
    }

    @media screen and (max-width: $mob) {
      top: 5px;
      right: 5px;
      height: 30px;
      width: 30px;
    }

    &:hover {
      background: $light-black;
      color: $white;
      cursor: pointer;
    }
  }

  input {
    padding: 15px 50px 15px 50px;
    border-radius: 21.5px;
    width: 100%;
    box-shadow: none;
    outline: none;
    border: 1px solid #7d7d7d;
    font-size: 16px;
    color: $light-black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background: rgba(255, 255, 255);

    @media screen and (max-width: $tab) {
      padding: 13px 15px 13px 50px;
    }

    @media screen and (max-width: $min-tab) {
      padding: 8px 30px 8px 45px;
    }

    @media screen and (max-width: $mob) {
      padding: 7px 36px 7px 27px;
    }

    &::placeholder {
      color: $light-black;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      opacity: 1;

      &::first-letter {
        text-transform: uppercase;
      }

      @media screen and (max-width: $min-tab) {
        font-size: 13px;
      }
    }

    &:focus {
      background-color: #ffffff;
    }

    &:-ms-input-placeholder {
      color: $light-black;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;

      &::first-letter {
        text-transform: uppercase;
      }

      @media screen and (max-width: $min-tab) {
        font-size: 13px;
      }
    }

    &::-ms-input-placeholder {
      color: $light-black;
      font-weight: 400;
      letter-spacing: 0.8px;
      line-height: 25px;
      line-height: 16px;

      &::first-letter {
        text-transform: uppercase;
      }

      @media screen and (max-width: $min-tab) {
        font-size: 13px;
      }
    }

    &.open-suggestion-box {
      border-radius: 21.5px 21.5px 0 0;
      border-bottom: 1px solid #ff000000 !important;
    }
  }
}

.title {
  margin-bottom: 15px;

  @media screen and (max-width: $min-tab) {
    margin-bottom: 5px;
  }

  h2 {
    margin: 0;
    font-size: 30px;
    line-height: 37px;
    word-break: break-word;

    @media screen and (max-width: $tab) {
      font-size: 25px;
    }

    @media screen and (max-width: $min-tab) {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 5px;
    }
  }

  img {
    max-width: 70px;
    width: 100%;
  }

  h3 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 42px;

    @media screen and (max-width: $tab) {
      font-size: 22px;
      line-height: 36px;
    }

    @media screen and (max-width: $min-tab) {
      line-height: 25px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: $mob) {
      font-size: 20px;
    }
  }

  p {
    font-size: 20px;
    margin-bottom: 0;
    color: rgba($light-black, 0.62);

    @media screen and (max-width: 1400px) {
      font-size: 16px;
    }

    @media screen and (max-width: $mob) {
      font-size: 14px;
    }
  }
}

.our-story {
  padding: 80px 0px;

  @media screen and (max-width: $min-desk) {
    padding: 65px 0;
  }

  @media screen and (max-width: $min-tab) {
    text-align: center;
    padding: 45px 0;
  }

  @media screen and (max-width: $mob) {
    padding: 30px 0 40px;
  }

  h3 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 50px;

    @media screen and (max-width: $tab) {
      font-size: 28px;
      line-height: 35px;
    }

    @media screen and (max-width: $min-tab) {
      margin-bottom: 15px;
      font-size: 22px;
      line-height: 28px;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    color: $light-black;
    line-height: 28px;

    @media screen and (max-width: $tab) {
      font-size: 14px;
    }

    a {
      color: $green-th;
      font-size: 13px;
    }
  }

  .story-img {
    text-align: center;

    @media screen and (max-width: $min-tab) {
      padding-top: 20px;

      .video,
      #player-wrapper>div,
      .video video {
        height: auto !important;
      }
    }

    .video {
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -khtml-border-radius: 20px;
      overflow: hidden;
      background: $light-black;

      div {
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -khtml-border-radius: 20px;
      }

      img {
        width: 100%;
      }

      video {
        height: 325px;
        width: 100%;
      }
    }
  }
}

.howitwork {
  padding: 50px 0;
  background: rgba($br-color, 0.4);

  .serch-in input {
    border-color: #7d7d7d;
    padding-right: 15px;

    @media screen and (max-width: $mob) {
      padding-right: 45px;
    }
  }

  @media screen and (max-width: $tab) {
    padding: 35px 0;
  }

  .title {
    margin-bottom: 65px;

    @media screen and (max-width: $tab) {
      margin-bottom: 10px;
    }

    p {
      margin: 20px auto 0;
      max-width: 930px;

      @media screen and (max-width: $min-tab) {
        margin-bottom: 10px;
      }
    }
  }

  .pro-box,
  .step-box {
    padding: 0 30px;
    text-align: center;

    @media screen and (max-width: $max-tab) {
      padding: 0 0px;
    }

    @media screen and (max-width: $tab) {
      padding: 0 0 15px 0;
    }

    .img {
      img {
        max-width: 318px;
        width: 100%;

        @media screen and (max-width: $min-tab) {
          max-width: 250px;
        }
      }
    }

    .content {
      padding: 15px;
      text-align: center;

      h4 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        padding-bottom: 10px;
        position: relative;

        @media screen and (max-width: $tab) {
          font-size: 18px;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
          height: 3px;
          width: 50px;
          background: $green-th;
        }
      }
    }
  }

  &.product-list {
    background: $white;
    padding: 40px 0 10px;

    @media screen and (max-width: $tab) {
      padding: 70px 0px;
    }

    @media screen and (max-width: $min-tab) {
      padding: 30px 0px;
    }

    .title {
      margin-bottom: 35px;

      @media screen and (max-width: $min-tab) {
        margin-bottom: 0px;
        margin-top: 25px;
      }

      h2 {
        font-weight: 600;
      }

      h3 {
        margin-bottom: 0;
        font-weight: 600;
        color: #1e4976 !important;
      }
    }

    .col-md-4 {
      margin-bottom: 30px;
    }

    .pro-box {
      background: $white;
      border: 1px solid #d8d8d8;
      overflow: hidden;
      border-radius: 5px;
      padding: 0;
      text-align: center;
      margin: 0 auto;
      position: relative;
      height: 100%;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      transition: all ease-in-out 0.2s;

      @media screen and (max-width: $tab) {
        padding: 0 0 65px 0;
      }

      &:hover {
        transform: scale(1.02);
        transition: all ease-in-out 0.2s;
      }

      .img {
        padding-bottom: 56.25%;
        width: 100%;
        overflow: hidden;
        background: #bcbcbc;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          max-width: 100%;
        }

        ul {
          position: absolute;
          right: 05px;
          top: 05px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          z-index: 8;

          li {
            a {
              color: #ababab;
              padding: 0;
              background: $white;
              margin-left: 10px;
              font-size: 20px;
              height: 30px;
              width: 30px;
              border-radius: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0 0 5px rgb(0, 0, 0, 0.1);

              span {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              &:hover {
                text-decoration: none;
                color: $light-black;
              }

              .icon-heart-fill {
                color: $danger;
              }
            }
          }
        }
      }

      .hover-box {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: $black;
        display: flex;
        align-items: flex-end;
        padding: 15px 20px 35px;
        justify-content: center;
        visibility: hidden;
        transition: all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;
        opacity: 0;
        border-radius: 5px;
        background: rgba($light-black, 0);
        background: -moz-linear-gradient(top,
        rgba($light-black, 0) 0%,
        rgba($light-black, 0) 0%,
        rgba($light-black, 0) 48%,
        rgba($light-black, 0.58) 100%);
    
        background: -webkit-gradient(left top,
            left bottom,
            color-stop(0%, rgba($black, 0)),
            color-stop(0%, rgba($black, 0)),
            color-stop(48%, rgba($light-black, 0)),
            color-stop(100%, rgba($light-black, 0.58)));
        
        background: -webkit-linear-gradient(top,
            rgba($light-black, 0) 0%,
            rgba($light-black, 0) 0%,
            rgba($light-black, 0) 48%,
            rgba($light-black, 0.58) 100%);
        
        background: -o-linear-gradient(top,
            rgba($light-black, 0) 0%,
            rgba($light-black, 0) 0%,
            rgba($light-black, 0) 48%,
            rgba($light-black, 0.58) 100%);
        
        background: -ms-linear-gradient(top,
            rgba($light-black, 0) 0%,
            rgba($light-black, 0) 0%,
            rgba($light-black, 0) 48%,
            rgba($light-black, 0.58) 100%);
        
        background: linear-gradient(to bottom,
            rgba($light-black, 0) 0%,
            rgba($light-black, 0) 0%,
            rgba($light-black, 0) 48%,
            rgba($light-black, 0.58) 100%);

        //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='$black', endColorstr='$black', GradientType=0);
        @media screen and (max-width: $tab) {
          background: transparent;
          bottom: 0;
          opacity: 1;
          visibility: visible;
          height: auto;
          width: auto;
          padding: 0 0 20px;
        }

        .btn {
          padding: 7px 15px;
          border-radius: 40px;
          width: 100%;
          max-width: 180px;

          @media screen and (max-width: $min-tab) {
            padding: 5px 8px;
            font-size: 14px;
          }
        }
      }

      &:hover {
        border-color: #89cadb;

        .hover-box {
          visibility: visible;
          opacity: 1;
        }
      }

      .content {
        h4 {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 8px;
          padding-bottom: 0;

          @media screen and (max-width: $tab) {
            font-size: 16px;
            margin-bottom: 5px;
          }

          &:after {
            display: none;
          }
        }

        h5 {
          color: #38b6ff;
          font-size: 16px;
          margin: 0 0 7px;

          @media screen and (max-width: $tab) {
            font-size: 14px;
            margin-bottom: 5px;
          }
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: rgba($light-black, 0.6);
          margin: 0;

          @media screen and (max-width: 1400px) {
            font-size: 14px;
          }

          @media screen and (max-width: $tab) {
            font-size: 14px;
          }
        }
      }
    }

    .product-spiner {
      ngx-spinner {
        .ngx-spinner-overlay {
          background: transparent !important;
          position: inherit !important;

          .la-square-jelly-box {
            position: relative;
            top: 30px;
            left: 0;
            margin: auto;
          }
        }
      }
    }
  }

  p {
    color: rgba($light-black, 0.62);
  }

  .download-part {
    text-align: center;

    h5 {
      font-size: 20px;
      text-align: center;
    }

    .btn {
      padding: 0;
      margin: 15px 10px 0;

      @media screen and (max-width: $mob) {
        margin: 15px 2px 0;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      img {
        @media screen and (max-width: $mob) {
          max-width: 125px;
        }
      }
    }
  }
}

.getin-touch {
  padding: 50px 0;

  @media screen and (max-width: $tab) {
    padding: 20px 0 40px;
  }

  @media screen and (max-width: $min-tab) {
    padding: 20px 0;
  }

  @media screen and (max-width: $mob) {
    padding: 20px 0 0px;
  }

  h3 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 50px;

    @media screen and (max-width: $tab) {
      font-size: 32px;
      line-height: 38px;
    }

    @media screen and (max-width: $tab) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .get-form {
    max-width: 670px;
    margin: 0 auto;
    padding: 30px 70px;
    background: $green-th;
    border-radius: 20px;

    @media screen and (max-width: $min-tab) {
      padding: 30px;
      max-width: 530px;
    }

    @media screen and (max-width: $mob) {
      border-radius: 0;
      max-width: 100%;
      border: none;
      padding: 15px;
    }

    h4 {
      font-size: 21px;
      margin: 0 0 30px;
      text-align: center;
      font-weight: 600;

      @media screen and (max-width: $mob) {
        font-size: 20px;
      }
    }

    input {
      border-color: rgba($green, 0.2);
      color: $light-black;
      -webkit-appearance: none;
      appearance: none;
      -moz-appearance: none;

      &::placeholder {
        color: rgba($light-black, 0.4);
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        opacity: 1;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      &:-ms-input-placeholder {
        color: rgba($light-black, 0.4);
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      &::-ms-input-placeholder {
        color: rgba($light-black, 0.4);
        font-weight: 400;
        letter-spacing: 0.8px;
        line-height: 25px;
        line-height: 14px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    textarea {
      border-color: rgba($green, 0.2);
      resize: none;
      padding: 10px;

      &::placeholder {
        color: rgba($light-black, 0.4);
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        opacity: 1;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      &:-ms-input-placeholder {
        color: rgba($light-black, 0.4);
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      &::-ms-input-placeholder {
        color: rgba($light-black, 0.4);
        font-weight: 400;
        letter-spacing: 0.8px;
        line-height: 25px;
        line-height: 14px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}

.btn {
  &.theme-btn {
    background: $light-black;
    color: $white;
    padding: 7px 35px;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: $min-tab) {
      font-size: 14px;
      padding: 5px 30px;
    }

    &:hover {
      background: $light-black;
      color: $white;
    }
  }

  &.btn-yellow {
    background: $yellow;
    color: $white;
    padding: 7px 35px;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 400;

    &:hover {
      background: $white;
      color: $light-black;
    }
  }

  &.navi-btn {
    background: $light-black;
    color: $green-th;
    padding: 7px 35px;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      background: $white;
      color: $light-black;
    }
  }

  &.border-btn {
    background: $white;
    color: $light-black;
    border: 1px solid $light-black;
    border-radius: 50px;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.inner-head {
  background: $home-banner;
  padding: 132px 0 25px;
  background-position: top -20px center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: $tab) {
    padding: 40px 0 40px;
  }

  @media screen and (max-width: $mob) {
    background-position: top 0 center;
  }

  .inner-search {
    @media screen and (max-width: $min-mob) {
      display: block !important;
      text-align: center;
    }
  }

  .product-share-btn {
    background: $white;
    color: $black;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    margin-left: 15px;
    min-width: 65px;
    font-size: 25px;
    line-height: 62px;

    @media screen and (max-width: $tab) {
      height: 50px;
      width: 50px;
      min-width: 50px;
      font-size: 20px;
      line-height: 42px;
    }

    @media screen and (max-width: $min-tab) {
      height: 37px;
      width: 37px;
      min-width: 37px;
      font-size: 18px;
      line-height: 40px;
      margin-left: 6px;
      padding: 0;
    }

    &.after-fliter {
      color: $green-th;
    }
  }

  .search-btn {
    background: $green-th;
    color: $light-black;
    border-radius: 50%;
    height: 65px;
    min-width: 65px;
    margin-left: 15px;
    font-size: 20px;
    line-height: 55px;
    padding: 4px 15px;

    @media screen and (max-width: $tab) {
      height: 50px;
      min-width: 50px;
      font-size: 16px;
      line-height: 38px;
      font-weight: 500;
      padding: 0;
    }

    @media screen and (max-width: $min-tab) {
      height: 37px;
      min-width: 37px;
      font-size: 16px;
      line-height: 37px;
      margin-left: 6px;
      padding: 0;
    }

    @media screen and (max-width: $min-mob) {
      margin-left: 0;
    }
  }
}

.product-search {
  text-align: center;
  width: 100%;
  margin-bottom: 50px;

  @media screen and (max-width: $min-tab) {
    padding: 20px 0px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: $mob) {
    background-position: top 0 center;
  }

  .serch-in {
    span.icon-search {
      @media screen and (max-width: $mob) {
        left: 9px;
        top: 13px;
        font-size: 14px;
      }
    }
  }

  .product-share-btn {
    position: relative;
    background: $light-black;
    color: #ffa710;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin-left: 15px;
    min-width: 60px;
    font-size: 24px;
    line-height: 50px;

    @media screen and (max-width: $tab) {
      height: 50px;
      width: 50px;
      min-width: 50px;
      font-size: 20px;
      line-height: 42px;
    }

    @media screen and (max-width: $min-tab) {
      height: 33px;
      width: 33px;
      min-width: 33px;
      font-size: 16px;
      line-height: 34px;
      margin-left: 6px;
      padding: 0;
    }

    @media screen and (max-width: $mob) {
      margin-left: 3px;
    }

    &.after-fliter {
      color: $gray-100;
      background-color: $light-black;
    }

    .icon-check {
      color: $green-th;
      position: absolute;
      right: 9px;
      top: 9px;

      @media screen and (max-width: $min-tab) {
        right: 4px;
        top: 4px;
        font-size: 14px;
      }
    }
  }

  .search-btn {
    background: #ffa710;
    color: $light-black;
    border-radius: 50%;
    height: 60px;
    min-width: 60px;
    width: 60px;
    margin-left: 15px;
    font-size: 18px;
    line-height: 50px;
    padding: 4px 15px;

    @media screen and (max-width: $tab) {
      height: 50px;
      min-width: 50px;
      width: 50px;
      font-size: 16px;
      line-height: 38px;
      font-weight: 500;
      padding: 0;
    }

    @media screen and (max-width: $min-tab) {
      height: 33px;
      min-width: 33px;
      width: 33px;
      font-size: 14px;
      line-height: 33px;
      margin-left: 6px;
      padding: 0;
    }

    @media screen and (max-width: $mob) {
      position: absolute;
      top: 5px;
      right: 5px;
      height: 30px;
      min-width: 30px;
      width: 30px;
      font-size: 12px;
      /* line-height: 33px; */
      margin-left: 6px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: $min-mob) {
      margin-left: 0;
    }
  }
}

.mobile-head {
  @media screen and (max-width: $min-tab) {
    display: none;
  }
}

.about-img {
  text-align: center;
  display: inline-block;
  width: 100%;
  height: 100%;

  .video-js .vjs-big-play-button {
    left: 0;
    right: 0;
    top: 50% !important;
    margin: 0 auto;
  }

  img {
    margin: 0 auto;
    max-width: 100%;
  }
}

.product-detail {
  padding: 35px 0;
  display: inline-block;
  width: 100%;

  @media screen and (max-width: $tab) {
    padding: 70px 0;
  }

  @media screen and (max-width: $min-tab) {
    padding: 30px 0;
  }

  &.details-inner {
    .container {
      @media screen and (max-width: $min-tab) {
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        .row {
          padding-left: 0px;
          padding-right: 0px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  .details {
    @media screen and (max-width: $min-tab) {
      padding: 10px 0 0 0;
    }
  }

  .product-detail-dark {
    background: $light-black;
    padding: 20px;
    border-radius: 15px;

    @media screen and (max-width: $min-tab) {
      padding: 10px 10px;
    }

    @media screen and (max-width: $mob) {
      border-radius: 0px;
      padding: 10px 0px;
    }

    p {
      color: #e9e9e9;
      padding-top: 0px;

      .icon-check {
        font-size: 17px;
        color: #8ffe26;
      }
    }

    .price-transparency-tab {
      color: $white;

      h5 {
        font-weight: 600;
        font-size: 20px;
      }

      h6 {
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
      }

      a {
        font-size: 18px;
        display: block;
        padding: 6px 0 0 7px;
        text-decoration: none;
        color: $white;
      }

      .cost-info {
        width: 250px;
        position: absolute;
        left: 0;
        top: 30px;
        font-size: 12px;
        font-weight: 400;
        color: $black;
        padding: 5px;
        border-radius: 5px;
        background: $white;
        display: none;
        z-index: 2;
      }

      a:hover~.cost-info {
        display: block;
      }

      .cost-progress-bar {
        margin: auto;
        width: 100px;
        position: relative;

        mat-progress-spinner {
          border-radius: 50%;
          box-shadow: inset 0px 0px 0px 10px rgba(154, 158, 162, 1);

          svg {
            circle {
              stroke: #8ffe26;
            }
          }
        }

        .percent-text {
          position: absolute;
          text-align: center;
          left: 0;
          right: 0;
          margin: auto;
          top: 29px;
          line-height: 15px;
          color: rgb(255, 255, 255);
          z-index: 1;
          font-size: 10px;

          .h5 {
            font-weight: 400;
          }
        }
      }
    }
  }

  .simmer-animation {
    height: 202.5px;
    max-width: 360px;
    margin: 40px auto;
    width: 100%;
    left: 0;
    right: 0;
    border-radius: 25px;

    &.content-shimmer {
      max-width: 100%;
      height: 20px;
      border-radius: 0;
      margin-top: 0;
    }

    &.tab-animation {
      max-width: 150px;
      height: 50px;
      border-radius: 50px;
      margin: 0 10px 10px;
    }

    span {
      font-size: 35px;
      background: #e9e9e9;
      color: #ffffff;
      border-radius: 30px;
      height: 60px;
      width: 60px;
      line-height: 70px;
      transform: rotate(30deg);
      text-align: center;
    }
  }

  .details-img {
    padding-bottom: 56.25%;
    width: 100%;
    overflow: hidden;
    background: #bcbcbc;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 15px;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 100%;

      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 18px rgba(255, 255, 255, 0.3);
      }
    }
  }

  .details {
    color: $white;

    h2 {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 0;
      color: $white;

      @media screen and (max-width: $min-tab) {
        font-size: 16px;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 30px;
      line-height: 25px;

      @media screen and (max-width: 1400px) {
        font-size: 14px;
      }

      @media screen and (max-width: 767px) {
        font-size: 14px;
        margin-bottom: 13px;
      }

      span {
        color: #38b6ff;
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    .product-detail-share-icon {
      display: flex;

      li {
        display: block;

        a {
          color: #c5cbcf;
          font-size: 34px;
          padding: 0 5px;
          text-decoration: none;
          box-shadow: none;
          display: block;
          line-height: 0;

          @media screen and (max-width: $min-tab) {
            font-size: 22px;
          }

          .icon-heart-fill {
            color: $danger;
          }
        }
      }
    }
  }

  .detail-page {
    max-width: 1000px;
    position: relative;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    overflow: hidden;
    height: 100%;
    background: $white;

    @media screen and (max-width: $min-desk) {
      max-width: 950px;
    }

    @media screen and (max-width: $min-tab) {
      margin: 0 auto 30px;
      max-width: 750px;
      height: auto;
    }

    &.video {
      height: auto !important;
      border-radius: 25px;
    }

    .video-custume-class {
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      height: 100%;

      &>div {
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        overflow: hidden;
        height: 100% !important;

        @media screen and (max-width: $min-tab) {
          width: 100%;
        }
      }

      .video-js {
        min-width: 100% !important;
        min-height: 100%;

        .vjs-big-play-button {
          left: 0;
          margin: 0 auto;
          right: 0;
          top: 180px;
        }

        .vjs-poster {
          border: 0px;
          outline: none;
        }
      }
    }

    video {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
    }
  }

  .tab-details {
    &.recipe-detail-header {
      padding: 6px 0 !important;

      .mat-tab-header {
        display: none !important;
      }

      .mat-expansion-panel {
        margin-bottom: 0px !important;
      }
    }

    padding: 20px 0 0;

    @media screen and (max-width: $tab) {
      padding: 30px 0 0;
    }

    @media screen and (max-width: $mob) {
      padding: 5px 0 0;
    }

    .detail-box {
      border: 0px solid #414a53;
      padding: 0px;
      background: $dark-black;
      border-radius: 6px;

      &.source-tab {
        cursor: default !important;
      }

      @media screen and (max-width: $min-tab) {
        padding: 10px;
      }

      .serving-size {
        ul {
          li {
            padding-left: 5px !important;
            font-size: 18px !important;
            padding-right: 58px !important;
            margin-bottom: 7px !important;

            &::after {
              display: none;
            }
          }
        }

        hr {
          border-top: 1px solid #3c3c3c !important;
        }
      }

      .video-custume-class-img {
        padding-bottom: 56.25%;
        width: 100%;
        overflow: hidden;
        background: #bcbcbc;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 15px;
        margin-bottom: 15px;
        cursor: pointer;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          max-width: 100%;
        }
      }

      .source-image {
        width: 150px;
        height: 150px;
        overflow: hidden;
        min-width: 150px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 15px;

        img {
          object-fit: cover;
          object-position: center;
          height: 100%;
          width: 100%;
        }
      }

      .farmer-video {
        width: 450px;
        background: $light-black;
        border-radius: 15px;
        overflow: hidden;

        video {
          width: 100%;
        }

        @media screen and (max-width: $tab) {
          width: 100%;
          width: 100%;
          margin: 0 auto 30px;
        }

        img {
          width: 100%;
        }
      }

      .farmer-details {
        width: 100%;
        padding-left: 25px;
        cursor: default;

        @media screen and (max-width: $tab) {
          width: 100%;
          padding-left: 0;
        }

        h5 {
          font-size: 20px;
          font-weight: 500;
          color: #c0f0f7;

          @media screen and (max-width: $mob) {
            font-size: 16px;
          }
        }

        p {
          font-size: 16px;
          color: $white;
          margin-bottom: 0px;

          strong {
            font-weight: 600;
          }

          &.location {
            span.icon-place {
              font-size: 17px;
              margin-right: 5px;
              position: relative;
              top: 2px;
            }

            label {
              margin: 0;
            }
          }

          @media screen and (max-width: 1400px) {
            font-size: 14px;
          }
        }
      }
    }

    .recipe-img-outer {
      width: 80px;
      height: 80px;
    }

    .recipe {
      padding: 0;
      background: transparent;
      width: 100%;
      cursor: default;

      &.undefined-cursor {
        cursor: pointer !important;
      }

      h3 {
        font-size: 20px;
        color: #c0f0f7;

        @media screen and (max-width: $mob) {
          font-size: 14px;
          position: absolute;
          top: -80px;
          left: 90px;
        }
      }

      h4 {
        font-size: 20px;
        color: #c0f0f7;
      }

      h5 {
        font-size: 16px;
        color: #fff;
      }

      .recipe-img-outer {
        width: 80px;
        margin-right: 10px;
      }

      .recipe-img {
        padding-top: 100%;
        width: 100%;
        overflow: hidden;
        background: #bcbcbc;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 10px;
        min-width: 80px;
        cursor: pointer;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          max-width: 100%;
        }

        .discount-label {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 99;
          background: #8ffe26;
          color: #000;
          font-size: 12px !important;
          padding: 3px 5px 1px 6px !important;
          font-weight: bold;
          border-radius: 0 0 0px 10px;
        }
      }

      .recipe-detail {
        @media screen and (max-width: $mob) {
          width: 100%;
          padding: 13px 0 0;
        }

        h3 {
          color: $white;
          margin: 0 0 10px;
          font-weight: bold;
          font-size: 16px;

          @media screen and (max-width: $mob) {
            margin: 0 0 10px;
            font-size: 18px;
          }
        }

        .nutrains {
          ul {
            display: flex;
            align-content: center;

            li {
              padding: 0 0;
              margin-right: 35px;

              @media screen and (max-width: $mob) {
                margin-right: 10px;
              }

              &.discount-list {
                margin-right: 80px;

                @media screen and (max-width: $mob) {
                  margin-right: 30px;
                }
              }

              h4 {
                color: $white;
                margin: 0 0 5px;
                font-weight: 600;
                font-size: 14px;

                @media screen and (max-width: $mob) {
                  font-size: 14px;
                }
              }

              p {
                color: rgba($white, 0.5);
                font-size: 16px;
                margin: 0;
                font-weight: 400;
                line-height: 16px;
                margin-bottom: 0;

                @media screen and (max-width: 1400px) {
                  font-size: 14px;
                }

                @media screen and (max-width: $mob) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    .mat-tab-header-pagination {
      box-shadow: 0px 0px 10px rgb(255 255 255 / 20%);

      .mat-tab-header-pagination-chevron {
        border-color: #fff;
      }
    }

    .mat-expansion-panel-header {
      height: auto !important;
      padding: 0;
      align-items: self-start !important;
    }

    .mat-expansion-indicator::after,
    .mat-expansion-panel-header-description {
      color: rgba(255, 255, 255, 0.54);
    }

    .mat-expansion-panel-header-title {
      margin-right: 0;

      @media screen and (max-width: $mob) {
        display: inline-block;
      }
    }

    mat-expansion-panel.mat-expansion-panel {
      padding: 17px;
      border: 0px solid #414a53;
      border-radius: 6px;
      background: #1a2128;
      margin: 0 5px 20px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

      &:last-child {
        margin-bottom: 6px;
      }

      @media screen and (max-width: $min-tab) {
        padding: 10px;
        margin: 0 0px 20px;
      }

      .percent-off {
        span {
          color: $white !important;
          font-weight: bold;
        }
      }

      .mat-tab-label {
        font-size: 13px !important;
        padding: 4px 21px !important;
        margin: 8px 10px !important;

        @media screen and (max-width: $mob) {
          font-size: 12px !important;
          padding: 1px 15px !important;
        }
      }
    }

    .mat-expansion-panel {
      .tracibility-tab {
        .mat-tab-label {
          margin: 0px 10px !important;
        }
      }
    }

    .mat-expansion-panel-content {
      font-family: $popins;
    }

    .mat-expansion-panel-body {
      padding: 10px 0 0 0 !important;
    }

    .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
    .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
      background: transparent;
    }

    .mat-tab-group {
      font-family: $popins;

      .discount-label {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
        background: #8ffe26;
        color: #000;
        font-size: 12px !important;
        padding: 3px 5px 1px 6px !important;
        font-weight: bold;
        border-radius: 0 0 0px 10px;
      }

      .mat-tab-body-content {
        overflow: hidden;
      }

      .mat-tab-header {
        padding-bottom: 20px;
        overflow: hidden;

        .mat-tab-label-container {
          overflow-x: auto;
          padding-bottom: 15px;
        }
      }

      @media screen and (max-width: $min-tab) {
        .mat-tab-header {
          padding-bottom: 15px;
          overflow: hidden;
        }
      }

      .mat-tab-labels {
        justify-content: center;

        .mat-tab-label {
          background: transparent;
          height: auto;
          min-width: auto;
          border: 2px solid $br-color;
          margin: 0 10px;
          border-radius: 50px;
          font-size: 16px;
          font-family: $popins;
          color: $white;
          padding: 7px 25px;

          .mat-ripple-element {
            display: none !important;
          }

          @media screen and (max-width: $min-tab) {
            padding: 5px 30px;
            margin: 0px 3px;
            font-size: 14px;
          }

          &:hover {
            background: #ffde59;
            color: $light-black;
            border-color: #ffde59;
            opacity: 0.65;
          }

          &.mat-tab-label-active {
            background: #ffa710;
            opacity: 1;
            color: $light-black;
            border-color: #ffa710;
            position: relative;
            overflow: hidden;

            &:after {
              content: '';
              position: absolute;
              height: 25px;
              width: 2px;
              top: 38px;
              background: #ffde59;
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
        }
      }

      .mat-tab-header,
      .mat-tab-nav-bar {
        border: none;
      }
    }

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
      display: none;
    }

    .food-contet {
      h4 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
        color: $green-th;
      }

      &.ingredients-items {
        border: 1px solid #414a53;
        padding: 20px !important;
        background: $dark-black;
        border-radius: 0;
        margin: 0 0 20px;

        &:last-child {
          margin: 0 0 0px;
        }

        ul {
          li {
            padding-left: 22px;
            margin-bottom: 15px;
            font-family: $popins;

            &:last-child {
              margin-bottom: 0px !important;
            }
          }
        }
      }

      ul {
        li {
          position: relative;
          padding-left: 25px;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 30px;
          color: $white;

          &:after {
            content: '';
            height: 8px;
            width: 8px;
            background: $white;
            border-radius: 5px;
            position: absolute;
            left: 5px;
            top: 5px;
          }

          h6 {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 5px;
            font-family: $popins;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 0;
            font-family: $popins;

            @media screen and (max-width: 1400px) {
              font-size: 14px;
            }
          }
        }
      }

      &.nutrition-data {
        ul {
          padding: 10px 10px;
          display: flex;
          flex-wrap: wrap;
          max-width: 90%;

          @media screen and (max-width: $mob) {
            padding: 0px 0px 10px 0px;
            max-width: 100%;
          }

          li {
            display: flex;
            align-items: flex-start;
            padding: 0 60px 0 25px;
            margin-bottom: 5px;
            flex: 0 0 50%;
            justify-content: space-between;
            max-width: 50%;

            &:first-child {
              max-width: 50.1%;
              flex: 50.1%;
            }

            @media screen and (max-width: $min-tab) {
              flex: 0 0 100% !important;
              max-width: 100% !important;
            }

            span {
              width: 50%;

              &:nth-last-child(1) {
                text-align: right;
              }
            }

            .yes {
              background: #1E4976;
              border-radius: 50%;
              height: 20px;
              width: 20px;
              display: flex;
              color: $dark-black;
              align-items: center;
              justify-content: center;
              font-size: 26px;
              overflow: hidden;
            }
          }
        }
      }

      &.source-traceblity {
        background: transparent !important;

        .traceblity-list {
          border-bottom: 1px solid rgb($white, 0.12);
          margin-bottom: 15px;
          padding-bottom: 10px;
          word-break: break-all;

          .date {
            font-weight: 500;
            font-size: 14px;
            display: flex;

            i {
              font-size: 17px;
              color: $green-th;
            }
          }

          .text-secondary {
            font-weight: 500;
            padding-left: 18px;
          }

          &:last-child {
            margin-bottom: 0px !important;
          }
        }
      }
    }

    .brand {
      padding: 20px;

      @media screen and (max-width: $min-tab) {
        padding: 0;
      }

      .brand-logo {
        min-width: 150px;
        border-radius: 10px;
        width: 150px;
        overflow: hidden;

        img {
          object-fit: cover;
          object-position: center;
          height: 100%;
          width: 100%;
        }
      }

      .brand-details {
        padding: 0 0 0 30px;

        @media screen and (max-width: $min-tab) {
          padding: 15px 0 0;
          width: 100%;
        }

        strong {
          font-weight: 600;
          color: rgba($light-black, 1);
        }

        p {
          font-size: 16px;
          color: rgba($light-black, 0.8);
          font-weight: 400;
          margin-bottom: 10px;

          @media screen and (max-width: 1400px) {
            font-size: 14px;
          }
        }

        .add {
          font-size: 16px;

          strong {
            font-weight: 400;
            color: rgba($white, 0.62);
            font-size: 14px;
          }

          p {
            display: flex;
            align-items: flex-start;
            color: $white;
            font-size: 16px;
            word-break: break-word;

            span {
              margin-right: 5px;
              font-size: 20px;
              color: $white;
            }

            a {
              color: $white;
            }
          }
        }
      }
    }

    .tracibility-date-list {
      padding-left: 30px;

      li {
        font-size: 16px;
        font-weight: 500;
        color: $white;
        position: relative;
        padding: 20px 10px;

        &:last-child {
          &::after {
            display: none;
          }
        }

        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          left: -11px;
          top: 35px;
          background: #38b6ff;
        }

        &::before {
          content: '';
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          left: -18px;
          top: 21px;
          background: #38b6ff;
        }
      }
    }

    .video-content {
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;
    }

    .nutrition-box {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      margin-bottom: 20px;
      height: 100%;

      .nutrition-data {
        ul {
          li {
            color: #9a9ea2;
            font-size: 16px;
            font-weight: 800;

            span {
              &:last-child {
                font-weight: 500;
              }
            }
          }
        }

        hr {
          margin: 0 0 15px;
          border-color: #252f39;
          border-width: 5px;
        }

        .serving-size {
          padding: 16px 10px 16px;
          background: $black;
          border-radius: 10px 10px 0 0;

          h2 {
            font-weight: 800;
            font-size: 20px;
            color: $white;
          }
        }

        .dieatry-icon-list {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 100%;
          padding: 12px 0 0;

          li {
            width: 50px;
            height: 50px;
            max-width: 50px !important;
            flex: auto !important;
            padding: 0 !important;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 10px 20px;

            @media screen and (min-width: 767px) and (max-width: 1200px) {
              width: 38px;
              height: 38px;
              max-width: 38px !important;
            }

            @media screen and (max-width: 1300px) {
              margin: 10px 5px;
            }

            @media screen and (max-width: 991px) {
              margin: 10px 2px;
            }

            &:nth-child(2) {
              border-color: #f18c24;
            }

            &:nth-child(3) {
              border-color: #3dbdd6;
            }

            &::after {
              display: none;
            }

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .title {
    h3 {
      font-size: 32px;
      margin-bottom: 0;
    }

    p {
      font-size: 16px;
      color: $black;
    }
  }

  .dietary-col {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;

    @media screen and (max-width: 767px) {
      flex: 100%;
      max-width: 100%;
    }
  }
}

.about-page {
  .aboutus-image {
    padding: 125px 0 0;
    height: 269px;
    // background: linear-gradient(95.33deg,
    //     rgba(154, 158, 162, 0.8) 2.8%,
    //     rgba(133, 236, 74, 0.8) 97.3%);
    background-image: url('/assets/images/meetourteam-background.png');

    h3 {
      font-weight: 500;
      font-size: 50px;
      color: #ffffff;

      @media screen and (max-width: 575px) {
        font-size: 32px;
      }
    }

    @media screen and (max-width: 991px) {
      height: 300px;
    }

    @media screen and (max-width: 575px) {
      padding: 45px 0 0;
      height: 225px;
    }
  }

  .player-sect {
    padding: 0 0 50px;
    margin-top: -280px;
    text-align: center;

    @media screen and (max-width: 991px) {
      margin-top: -130px;
    }

    @media screen and (max-width: 575px) {
      padding: 0 0 20px;
      margin-top: -115px;
    }

    .about-video {
      margin-bottom: 40px;
      overflow: hidden;
      border-radius: 10px;

      @media screen and (max-width: 575px) {
        margin-bottom: 20px;
      }
    }
  }

  .client-about-sect {
    padding: 60px 0;
    overflow: hidden;

    @media screen and (max-width: 991px) {
      padding: 20px 0 !important;
      text-align: center;
    }

    .row {
      align-items: center;
    }

    h6 {
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 25px;
      color: #00f900;

      @media screen and (max-width: 991px) {
        font-size: 22px;
      }
    }

    &:nth-child(3),
    &:nth-child(5) {
      img {
        border-right: 8px solid #2da34c;

        @media screen and (max-width: 991px) {
          border-bottom: 8px solid #2da34c;
          border-right: 0;
          margin-bottom: 20px;
          padding-bottom: 20px;
        }
      }
    }

    &:nth-child(4) {
      @media screen and (max-width: 991px) {
        .row {
          flex-direction: column-reverse;
        }
      }

      img {
        border-left: 8px solid #2da34c;

        @media screen and (max-width: 991px) {
          border: 0;
          border-bottom: 8px solid #2da34c;
          margin-bottom: 20px;
          padding-bottom: 20px;
        }
      }
    }

    &:nth-child(5) {
      padding-bottom: 125px;
    }
  }

  p {
    font-size: 16px;

    @media screen and (max-width: 575px) {
      font-size: 14px;
    }

    span {
      color: green;
    }
  }
}

.feedback {
  padding: 20px 0 20px 0;
  background: $green-th;

  a.ap-btn {
    padding: 0 20px 0 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    @media screen and (max-width: 991px) {
      padding: 0 10px 0 0;
    }

    @media screen and (max-width: 768px) {
      padding: 0 10px 20px 0;
    }

    &:last-child {
      padding-right: 0;
    }

    img {
      max-width: 156px;
      width: 100%;
      height: 100%;
    }
  }

  .title {
    max-width: 270px;
    margin: 0 0 0;
    text-align: center;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    h3 {
      margin-bottom: 0;
      color: $white;
    }

    h2 {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 30px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      @media screen and (max-width: 768px) {
        margin-bottom: 5px;
      }
    }
  }

  form {
    max-width: 450px;
    margin: 0 auto;

    label {
      margin-bottom: 10px;
    }

    textarea {
      background: $white;
      height: 90px;
      border: none;
      color: $black;
      font-size: 14px;
      font-weight: 400;
      resize: none;
      border: 1px solid $gray-100;

      &:focus {
        border: 1px solid $gray-100 !important;
        background: $white;
        border: none;
        color: $light-black;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .theme-btn {
      &:hover {
        background: $white;
        color: $light-black;
      }
    }
  }

  .theme-btn {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.help-support {
  padding: 50px 0 0;

  @media screen and (max-width: $tab) {
    padding: 70px 0px 0px;
  }

  @media screen and (max-width: $min-tab) {
    padding: 30px 0px;
  }

  .title {
    max-width: 700px;
    margin: 0 auto 0;

    @media screen and (max-width: $tab) {
      margin: 0 auto 15px;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  .helpsection {
    padding: 0 0 10px;

    @media screen and (max-width: $min-tab) {
      padding: 0;
    }

    .help-content {
      padding: 20px 20px;

      @media screen and (max-width: $tab) {
        text-align: center;
        padding: 20px 0 0;
      }

      h2 {
        font-weight: 600;
        font-size: 30px;
        padding: 10px 70px 30px 0;
        margin: 0;

        @media screen and (max-width: $tab) {
          padding: 0 0 5px;
          font-size: 22px;
          line-height: 25px;
        }
      }

      p {
        font-size: 16px;

        @media screen and (max-width: 1400px) {
          font-size: 14px;
        }

        @media screen and (max-width: $tab) {
          margin-bottom: 0;
        }
      }
    }

    .help-img {
      text-align: center;

      img {
        width: 100%;
        max-width: 250px;

        @media screen and (max-width: $min-tab) {
          max-width: 300px;
        }

        @media screen and (max-width: $mob) {
          max-width: 200px;
        }
      }
    }
  }

  .conatct-info {
    ul {
      display: flex;
      align-items: center;

      li {
        margin-right: 15px;

        a {
          border: 1px solid $br-color;
          padding: 15px 0;
          background: #f8f8f8;
          border-radius: 2px;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $light-black;
          text-decoration: none;
          font-size: 20px;
        }
      }
    }

    p {
      display: flex;
      font-size: 16px;

      strong {
        margin-right: 15px;
        font-weight: 500;
        min-width: 80px;
      }

      @media screen and (max-width: 1400px) {
        font-size: 14px;
      }
    }
  }

  h4 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .support {
    max-width: 680px;
    margin: 0 auto;

    .contact-form {
      .form-group {
        margin-bottom: 13px;
      }

      textarea {
        height: 80px;
        resize: none;
      }
    }

    .form-control {
      border-color: #d1e4d1;
    }

    textarea {
      height: 120px;
      border-color: #d1e4d1;
    }
  }
}

.about-us {
  ul {
    margin-left: 40px;

    li {
      list-style-type: inherit;
    }
  }

  .title {
    max-width: 700px;
    margin: 0 auto;

    h3 {
      margin-bottom: 10px;

      @media screen and (max-width: $tab) {
        margin-bottom: 15px;
        font-size: 25px;
        line-height: 28px;
      }

      @media screen and (max-width: $min-tab) {
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 25px;
      }
    }

    p {
      font-size: 16px;
      color: $light-black;
      margin-bottom: 15px;

      @media screen and (max-width: 1400px) {
        font-size: 14px;
      }
    }
  }

  .details {
    p {
      color: $light-black;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.2px;
    }

    li {
      list-style: initial;
      list-style-position: inside;
    }
  }

  .details-img {
    padding: 0;
    border-radius: 25px;
    background: $light-black;

    .video {
      video {
        width: 100%;
        height: 280px;
        margin: 0;

        @media screen and (max-width: $min-tab) {
          height: 200px;
        }
      }
    }
  }
}

.content-pages {
  padding: 60px 0 35px;

  .add-content {
    ul {
      padding-left: 30px;

      li {
        padding-left: 0;
        margin: 0 0 15px;
        list-style: upper-alpha;
      }
    }

    h5 {
      font-size: 15px;
      margin: 0 0 10px;
    }

    ol {
      padding: 10px 0 0 30px;

      li {
        list-style: decimal-leading-zero !important;
      }
    }

    a {
      color: #5dbf01;
    }
  }

  @media screen and (max-width: $min-tab) {
    padding: 30px 0;
  }

  .title {
    max-width: 700px;
    margin: 0 auto 40px;

    @media screen and (max-width: $tab) {
      margin-bottom: 15px;
    }
  }

  .add-content {
    p {
      font-size: 14px;
      line-height: 25px;

      @media screen and (max-width: 1400px) {
        font-size: 14px;
      }

      @media screen and (max-width: $min-tab) {
        font-size: 14px;
      }
    }
  }
}

.faq-row {
  box-shadow: 0 0 15px rgba($black, 0.05);
  padding: 15px;
  margin-bottom: 25px;
}

.faq-row h3 {
  font-size: 25px;
}

.mat-tab-label,
.mat-tab-link {
  font-family: $popins;
}

section.profile-section {
  padding: 35px 0 0;
  background: #f8f8f8;

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    display: none;
  }

  .mat-tab-label-active {
    border: 1px solid $br-color;
    border-bottom: none;
    background: $white !important;
    position: relative;
    z-index: 9;
    border-radius: 8px 8px 0 0;
    height: 40px;
    opacity: 1;
  }

  .mat-tab-label {
    height: 40px;
    font-weight: 400;
    font-size: 15px;
    min-width: inherit;
  }

  .mat-tab-header,
  .mat-tab-nav-bar {
    border-bottom: none;
    background: #f8f8f8;
  }

  .mat-tab-body-wrapper {
    border-top: 1px solid $br-color;
    margin: -1px 0 0;
    background: $white;
  }

  .profile-box {
    max-width: 460px;
    margin: 0 auto;

    .profile {
      min-width: 100px;
      width: 100px;
      border-radius: 15px;
      overflow: hidden;
      background: $white;
      padding: 6px;
      border: 1px solid $br-color;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
        overflow: hidden;
      }
    }

    .profile-detail {
      padding-left: 20px;

      h2 {
        font-size: 38px;
        margin-bottom: 10px;
        font-weight: 600;

        @media screen and (max-width: $min-tab) {
          font-size: 20px;
        }
      }

      p {
        font-size: 14px;
        margin-bottom: 0;

        @media screen and (max-width: 1400px) {
          font-size: 13px;
          margin: 0;
        }
      }

      label {
        font-size: 12px;
        margin: 0;
      }
    }
  }

  .profile-tab {
    background: $white;

    .edit-box {
      padding: 30px 15px;
      max-width: 650px;
      margin: 0 auto;
      min-height: 235px;

      @media screen and (min-width: $desktop) {
        padding: 70px 17px;
      }

      @media screen and (max-width: $mob) {
        padding: 20px 15px 30px;
      }

      &.change-pass {
        max-width: 450px;

        .profile-detail {
          padding: 0;
        }
      }

      .profile {
        min-width: 160px;
        width: 160px;
        height: 160px;
        border-radius: 15px;
        overflow: hidden;
        background: $white;
        position: relative;
        border: 1px solid #dedede;

        @media screen and (max-width: $mob) {
          margin: 0 auto 20px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          overflow: hidden;
        }

        label {
          position: absolute;
          background: #8ffe26;
          color: #ffffff;
          bottom: 0;
          width: 100%;
          margin: 0;
          height: 25px;
          text-align: center;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          height: 33px;
          width: 33px;
          border-radius: 50%;
          top: 5px;
          right: 5px;

          &:hover {
            background: rgba($light-black, 0.62);
          }

          input {
            opacity: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            -webkit-appearance: none;
            appearance: none;
            -moz-appearance: none;
            cursor: pointer;
          }
        }
      }

      .profile-detail {
        padding-left: 20px;

        @media screen and (max-width: $mob) {
          padding: 0;
          max-width: 400px;
          margin: 0 auto;
        }

        .btn.theme-btn {
          padding: 11px 40px;
        }

        label {
          font-size: 12px;
          margin: 0;
        }
      }
    }

    .favorites-list {
      @media screen and (min-width: $desktop) {
        padding: 70px 17px;
      }
    }
  }

  .mat-tab-body-content {
    overflow: hidden;
    font-family: $popins;
  }
}

.location {
  position: relative;
  display: inline-block;
  padding-right: 30px;

  button.location-btn {
    background: #38b6ff;
    color: $light-black;
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.source-location {
      top: 0px;
    }
  }
}

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 9;
}

/******/

.simmer-animation {
  animation: 1s forwards infinite linear;
  -webkit-animation: 1s forwards infinite linear;
  animation-name: placeHolderShimmer;
  -webkit-animation-name: placeHolderShimmer;
  background: #f6f7f9;
  background-image: linear-gradient(to right,
      #f6f7f9 0%,
      #e9ebee 20%,
      #f6f7f9 40%,
      #f6f7f9 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  //position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

div.a {
  height: 40px;
  left: 40px;
  right: auto;
  top: 0;
  width: 8px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.slide-banner {
  position: relative;
  padding-bottom: 43.75%;
  clip-path: polygon(0 0, 100% 0%, 100% 94%, 0% 100%);
  background-color: #000000;

  @media screen and (max-width: $tab) {
    margin-top: 30px;
  }

  @media screen and (max-width: $min-tab) {
    margin-top: 8px;
  }

  .simmer-animation {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;

    .home-search {
      width: 500px;
      margin: 0 auto;

      @media screen and (max-width: $mob) {
        width: 100%;
        padding: 0 20px;
      }
    }

    .s-search {
      width: 500px;
      height: 60px;
      background: #fff;
      margin: 0 auto;
      border-radius: 80px;

      @media screen and (max-width: $mob) {
        width: 100%;
      }
    }

    .text {
      width: 90%;
      height: 20px;
      margin: 0 auto 25px;
      background: $white;

      &.w-80 {
        width: 80%;
      }

      &.head {
        height: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.video,
.story-img {
  height: 100%;
  position: relative;

  .simmer-animation {
    height: 325px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;

    span {
      font-size: 35px;
      background: $br-color;
      color: $white;
      border-radius: 30px;
      height: 60px;
      width: 60px;
      line-height: 70px;
      transform: rotate(30deg);
      text-align: center;
      position: absolute;
      top: calc(50% - 30px);
    }
  }
}

.pro-box,
.step-box {
  position: relative;

  .simmer-animation {
    height: 400px;
    width: 100%;
    margin: 0 auto;
    opacity: 1;
    position: relative;
    padding: 15px 0;

    .img-h {
      height: 220px;
      width: 90%;
      border: 2px solid #fff;
      margin: 0 auto;
    }

    .sh-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 6;

      .text {
        width: 90%;
        height: 10px;
        margin: 0 auto 18px;
        background: $white;

        &.w-80 {
          width: 80%;
        }

        &.head {
          height: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.story-code {
  .simmer-animation {
    height: 180px;
    padding: 30px;
    border-radius: 10px;
    text-align: left;
  }

  .text {
    width: 45%;
    height: 18px;
    margin: 0 0 15px;
    background: $white;

    &.w-80 {
      width: 75%;
      height: 32px;
      margin-bottom: 16px;
    }

    &.head {
      height: 20px;
      margin-bottom: 20px;
    }
  }
}

.about-us {
  .details-img {
    .simmer-animation {
      height: 325px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 18px;

      span {
        font-size: 35px;
        background: $br-color;
        color: $white;
        border-radius: 30px;
        height: 60px;
        width: 60px;
        line-height: 70px;
        transform: rotate(30deg);
        text-align: center;
      }
    }
  }
}

.player-poster[data-poster] {
  .play-wrapper[data-poster] {
    svg {
      width: 40px;
    }
  }
}

/**Login**/

.login-page {
  height: 100vh;
  position: relative;
  margin: -101px 0 0 0;
  z-index: 5;
  background: $white;

  @media screen and (max-width: $tab) {
    margin: -73px 0 0 0;
  }

  .login-logo {
    padding: 20px 0 0px 60px;

    @media screen and (max-width: $max-tab) {
      padding: 20px 0 0 20px;
    }

    @media screen and (max-width: $tab) {
      padding: 10px;
      margin: 0 auto 0;
    }

    @media screen and (max-width: $min-tab) {
      text-align: center;
      padding: 0;
    }

    .mob-logo {
      display: none;

      @media screen and (max-width: $min-tab) {
        display: inherit;
      }
    }

    img {
      @media screen and (max-width: $min-tab) {
        display: none;
      }
    }

    a.navbar-brand {
      margin: 0;
      padding: 0px;
      width: 80px;

      @media screen and (max-width: $tab) {
        width: 100%;
      }

      img {
        max-width: 180px;
        max-height: 150px;
      }
    }
  }

  &.signup-page {
    .login-content {
      width: calc(100% - 430px);

      @media screen and (max-width: $tab) {
        width: calc(100% - 340px);
      }

      @media screen and (max-width: $min-tab) {
        width: 100%;
      }
    }

    .user-form {
      width: 430px;

      @media screen and (max-width: $tab) {
        position: relative;
        width: 340px;
      }

      @media screen and (max-width: $min-tab) {
        width: 100%;
      }

      .loginnow {
        height: calc(100vh - 165px);
        overflow-y: auto;
        overflow-x: hidden;

        @media screen and (max-width: $tab) {
          height: calc(100vh - 136px);
        }

        .form-box {
          .btn {
            width: 150px;
          }

          p {
            font-size: 12px;
            color: $teal;
          }

          .signup-text {
            margin-top: 40px;
            padding: 20px 0 0 0;
          }

          .my_radio {
            label {
              font-size: 12px !important;
              line-height: 22px;
              padding-left: 25px;

              a {
                color: $light-black;
                text-decoration: underline;
              }
            }

            .check {
              height: 18px;
              width: 18px;

              &:before {
                width: 6px;
                height: 12px;
                left: 4px;
              }
            }
          }
        }
      }
    }
  }

  .login-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: calc(100% - 430px);
    background: $light-black $login-banner;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 10px;

    @media screen and (max-width: $tab) {
      width: calc(100% - 350px);
    }

    @media screen and (max-width: $min-tab) {
      width: 100%;
      height: auto;
      background: $light-black;
      position: sticky;
      top: 0;
      z-index: 9;
    }

    .co-box {
      max-width: 540px;
      margin: 0 auto;
      height: calc(100% - 80px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      @media screen and (max-width: $min-tab) {
        display: none;
      }

      p {
        font-size: 24px;
        color: $white;
        line-height: 35px;
        margin-bottom: 20px;

        @media screen and (max-width: $tab) {
          padding: 0 15px;
        }

        @media screen and (max-width: $mob) {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    .btn {
      border-radius: 8px;
      font-size: 16px;
      padding: 6px 40px;
      background: $yellow;
      color: $white;
      font-weight: 500;
      border-radius: 40px;
    }
  }

  .user-form {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 430px;
    display: flex;
    align-items: center;

    @media screen and (max-width: $tab) {
      width: 350px;
    }

    @media screen and (max-width: $min-tab) {
      position: relative;
      height: auto;
      width: 100%;
      padding: 0;
    }

    .loginnow {
      padding: 50px;
      width: 100%;
      background: $white;
      overflow-y: auto;
      max-height: 100%;

      @media screen and (max-width: $min-desk) {
        padding: 15px 45px;
      }

      @media screen and (max-width: $tab) {
        padding: 15px 25px;
        max-height: 100%;
      }

      @media screen and (max-width: $min-tab) {
        padding: 30px 25px;
        max-width: 430px;
        margin: 0 auto;
      }

      @media screen and (max-width: $mob) {
        overflow: hidden;
        padding: 25px 25px 15px;
      }

      @media screen and (max-width: $min-mob) {
        padding: 20px 15px 20px;
      }

      .form-box {
        width: 100%;

        .forgot-img {
          padding: 0 0 40px;
          max-width: 100%;
        }

        .head {
          margin-bottom: 45px;

          @media screen and (max-width: $tab) {
            margin-bottom: 30px;
          }

          h3 {
            font-size: 24px;
            font-weight: 500;
            color: $light-black;
            margin: 0;

            @media screen and (max-width: $mid-desk) {
              font-size: 25px;
            }

            @media screen and (max-width: $tab) {
              font-size: 22px;
            }

            @media screen and (max-width: $mob) {
              font-size: 18px;
            }
          }

          a {
            font-size: 12px;
            color: #1E4976;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
          }

          a:hover {
            color: #0DC0DD;
          }

          span {
            font-weight: 600;
            margin: 0 0 0 5px;
            transform: rotate(180deg);
          }
        }

        .form-group {
          position: relative;
          margin-bottom: 20px;

          label {
            font-size: 14px;
            margin: 0;
            color: $light-black;
            letter-spacing: 0.4px;
          }

          .otp-inputs {
            margin: 0 -15px;

            .form-group {
              padding: 0 15px;

              input {
                text-align: center;
              }
            }
          }
        }

        p {
          color: $danger;
          margin-bottom: 2px;
        }

        .links {
          text-align: right;

          a {
            color: $light-black;
            font-size: 14px;
            font-weight: 400;

            @media screen and (max-width: $min-mob) {
              font-size: 12px;
            }
          }
        }

        .signup-text {
          text-align: center;
          padding: 0;

          @media screen and (max-width: $tab) {
            padding: 10px 0 0 0;
          }

          p {
            color: 14px;
            margin-bottom: 0;
            font-size: 14px;
            color: $light-black;
            padding: 15px 0 0 0;

            a {
              color: #1E4976;
            }

            a:hover {
              color: #0DC0DD;
            }
          }
        }

        .btn {
          border-radius: 50px;
          width: 100%;
          font-size: 16px;
          font-weight: 500;
          padding: 12px 15px;
          background: #0DC0DD;
          color: white;

          @media screen and (max-width: $min-tab) {
            padding: 8px 10px;
            max-width: 185px;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }

          &:hover {
            background-color:#1E4976
          }
        }

        .social-links {
          padding: 20px 0;

          @media screen and (max-width: $tab) {
            padding: 0 0 10px;
          }

          h5 {
            text-align: center;
            position: relative;
            font-weight: 500;
            color: $light-black;
            font-size: 16px;
            margin: 10px 0 23px;
            text-transform: uppercase;

            @media screen and (max-width: $tab) {
              margin: 25px 0 25px;
            }

            &:after {
              content: '';
              height: 1px;
              width: calc(50% - 18px);
              position: absolute;
              top: 50%;
              margin: -0.5 0 0 0;
              left: 0;
              background: $gray-800;
            }

            &:before {
              content: '';
              height: 1px;
              width: calc(50% - 18px);
              position: absolute;
              top: 50%;
              margin: -0.5 0 0 0;
              right: 0;
              background: $gray-800;
            }
          }

          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 100px;
            margin: 0 4px;
            padding: 8px 15px;
            border-radius: 50px;
            font-size: 14px;
            font-weight: 500;

            span {
              font-size: 16px;
              margin-right: 8px;
              display: inline-block;
            }

            
          }
        }
      }
    }
  }
}

// Google Sign-in button CSS
#buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%
}

.form-group {
  margin-bottom: 20px;
  position: relative;

  .input-error-border {
    border: solid 1px red !important;
  }

  .reset-link {
    padding: 2px 0 0;

    a {
      color: #fc9901;
      font-size: 13px;
      padding-left: 20px;
      position: relative;

      [class^='icon-'],
      [class*=' icon-'] {
        position: absolute;
        left: 3px;
        top: 2px;
      }
    }
  }

  &.ic-pass {
    position: relative;

    span {
      position: absolute;
      top: 30px;
      right: 10px;
      font-size: 24px;
      cursor: pointer;
    }

    input {
      padding-right: 35px;
    }
  }

  label {
    font-size: 14px;
    margin: 0;
    color: $gray-600;
    letter-spacing: 0.3px;
    width: 100%;

    @media screen and (max-width: $min-tab) {
      font-size: 14px;
    }

    &.placeholder {
      font-size: 14px;
    }
  }

  textarea {
    width: 100%;
    height: 70px;
    background: $white;
    color: $light-black;
    border-radius: 4px;
    border: 1px solid $br-color;
    padding: 15px;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;

    &:focus {
      outline: none;
      box-shadow: none;
      color: $light-black;
      border-color: $light-black;
    }
  }

  input {
    width: 100%;
    padding: 6px 10px;
    font-size: 14px;
    height: 40px;
    background: $white;
    color: $light-black;
    border-radius: 4px;
    border: 1px solid $br-color;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;

    &:focus {
      outline: none;
      box-shadow: none;
      color: $light-black;
      border-color: $light-black;
    }

    &.with-icon {
      padding-right: 40px;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid $light-black;
    -webkit-text-fill-color: $light-black;
    box-shadow: 0 0 0px 1000px $white inset;
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &.error {
    position: relative;
    margin-bottom: 23px;

    input {
      border-color: $danger;
    }
  }

  .validation,
  .validation_div {
    position: absolute;

    p {
      color: $danger;
      margin: 0;
      font-size: 11px !important;
    }
  }

  .cn-no {
    width: 90px !important;

    input {
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }

  .ph-no {
    width: calc(100% - 90px) !important;

    input {
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }

  .pass-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: $cyan;
  }

  input::placeholder {
    color: $gray-100;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    opacity: 1;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  input:-ms-input-placeholder {
    color: $gray-100;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  input::-ms-input-placeholder {
    color: $gray-100;
    font-weight: 400;
    letter-spacing: 0.8px;
    font-size: 14px;
    line-height: 16px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  textarea::placeholder {
    color: $gray-100;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    opacity: 1;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  textarea:-ms-textarea-placeholder {
    color: $gray-100;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  textarea::-ms-textarea-placeholder {
    color: $gray-100;
    font-weight: 400;
    letter-spacing: 0.8px;
    font-size: 14px;
    line-height: 16px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.successpop {
  position: fixed;
  z-index: 9999;
  background: $white;
  padding: 15px;
  border-radius: 10px;
  right: 10px;
  top: 80px;

  @media screen and (max-width: $min-mob) {
    left: 10px;
    margin: 0 auto;
  }

  max-width: 400px;
  text-align: center;
  // width: 100%;
  box-shadow: 0 8px 20px rgba($black, 0.08);
  display: none;

  &.active {
    display: block;
  }

  img {
    margin: 0;
    min-width: 50px;
    width: 50px;
  }

  h4 {
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
  }

  span {
    font-size: 65px;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
}

/**footer**/

footer.copyright {
  background: $light-black;
  padding: 15px 0;
  box-shadow: 0 0 20px rgba($black, 0.1);

  .bottom-links {
    text-align: right;

    @media screen and (max-width: $tab) {
      text-align: center;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0 -10px;
      padding: 0;
      justify-content: flex-end;

      @media screen and (max-width: $min-tab) {
        justify-content: center;
      }

      li {
        position: relative;

        &:after {
          content: '|';
          position: absolute;
          right: -2px;
          color: #fff;
        }

        &:nth-last-child(1):after {
          display: none;
        }

        a {
          padding: 0 10px;
        }
      }
    }
  }

  @media screen and (max-width: $tab) {
    padding: 12px 0;
  }

  @media screen and (max-width: $min-tab) {
    text-align: center;
  }

  img {
    max-width: 150px;

    @media screen and (max-width: $tab) {
      max-width: 100px;
    }

    @media screen and (max-width: $min-tab) {
      max-width: 90px;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    color: $white;
    font-weight: 400;

    @media screen and (max-width: $min-tab) {
      margin: 5px 0px;
      text-align: center;
    }
  }

  a {
    display: inline-block;
    color: $white;
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;

    @media screen and (max-width: $min-tab) {
      text-align: center;
    }

    &:hover {
      color: $green-th;
    }
  }
}

.fixed-footer-bottom {
  background-color: #252f39;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  @media screen and (max-width: $min-tab) {
    display: block;
    text-align: center;
    padding: 10px;
  }

  @media screen and (max-width: $mob) {
    margin: 10px;
    border-radius: 7px;
    box-shadow: 0px 0px 17px 0px rgb(0 0 0 / 50%);
  }

  p {
    color: #fff;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 300;
    margin-right: 20px;
  }

  .cookies-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $min-tab) {
      display: block;
      text-align: center;
      margin-top: 15px;
    }

    .btn {
      background: #fd9902;
      color: #fff;
      font-size: 13px;
      border-radius: 35px;
      padding: 5px 25px;
      border: 1px solid #fd9902;

      &:hover {
        background-color: #8ffe26;
        border-color: #8ffe26;
        color: #000;
      }
    }

    .btn.decline {
      background-color: transparent;
      border: 1px solid #fd9902;
      color: #fff;

      &:hover {
        border-color: #8ffe26;
      }
    }
  }
}

.no-data img {
  max-width: 160px;
  margin: 0 0 20px;
}

.feedback-dilog {
  .mat-dialog-title {
    margin: -9px -24px 15px;
    display: block;
    padding: 0 24px 15px;
    border-bottom: 1px solid $br-color;
    position: relative;
  }

  .mat-dialog-actions {
    margin: 0 -24px -24px;
    display: block;
    padding: 15px 24px;
    border-top: 1px solid $br-color;
  }

  h2 {
    font-size: 22px;
    margin: 0;
    color: #404040;
    font-weight: 600;
    font-family: $popins;
  }

  a {
    color: #ccc;
    text-decoration: none;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  p {
    font-size: 14px;
    color: #707070;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 22px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  label {
    font-size: 14px;
    color: $light-black;
    font-weight: 600;
    margin: 0;
  }

  .form-group {
    label {
      color: rgba($light-black, 0.7);
    }
  }

  .btn {
    padding: 4px 20px;
  }
}

.filter {
  padding: 10px 0;

  h5 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      margin: 0 0 15px;

      label {
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
}

.social-list {
  display: flex !important;
  position: absolute;
}

.social-list {
  display: flex !important;
  position: absolute;
}

.fab-container {
  position: static;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  >div {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 5px;

    button {
      margin-bottom: 17px;
    }
  }
}

.fab-toggler {
  float: right;
  z-index: 100;
}

ul.social-list {
  top: 40px !important;
  right: 18px !important;

  &.recipe-social-list {
    top: -7px !important;
    right: 37px !important;

    @media screen and (max-width: $mob) {
      top: -52px !important;
      right: 0px !important;
      left: 0;
    }
  }

  &.coupons-social-list {
    top: 0px !important;
    right: 37px !important;

    @media screen and (max-width: $mob) {
      top: -52px !important;
      right: 0px !important;
      left: 0;
    }
  }
}

ul.social-list .fab-secondary {
  background: #fff;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 5px;
}

.fab-secondary .sb-facebook {
  background: #395693;
  color: #fff;
  border: none;
  width: 35px;
  height: 35px;
}

.fab-secondary .sb-twitter {
  background: #1c9cea;
  color: #fff;
  border: none;
  width: 35px;
  height: 35px;
}

.video-js .vjs-big-play-button {
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 40% !important;
}

.video-js {
  &:hover {
    .vjs-big-play-button {
      background: rgb(143, 254, 38, 0.32) !important;
    }
  }

  .vjs-big-play-button {
    left: 0 !important;
    right: 0 !important;
    top: calc(50% - 30px) !important;
    height: 60px !important;
    width: 60px !important;
    min-width: 60px !important;
    border-radius: 100% !important;
    background: #8ffe26 !important;
    line-height: 60px !important;
    border: none !important;
    margin: 0 auto;

    .vjs-icon-placeholder {
      &:before {
        font-size: 45px !important;
      }
    }
  }
}

.vjs-error {
  .vjs-error-display {
    &:before {
      display: none !important;
    }
  }
}

.mat-tab-body.mat-tab-body-active {
  animation: fade 0.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.lb-dataContainer {
  padding-top: 0px !important;
  background: $light-black;
}

.lb-data {
  padding: 5px 10px;
  color: #ccc;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.lightbox .lb-image {
  border-radius: 0px;
}

.video-js,
.vjs-playlist {
  min-width: 272px !important;
}

.container {
  @media (min-width: 1400px) {
    max-width: 1270px;
  }

  @media (min-width: 1600px) {
    max-width: 1470px;
  }
}

.lb-data .lb-close {
  background-size: 15px !important;
  position: relative;
  top: 7px !important;
}

.lb-data .lb-caption {
  color: #8ffe26 !important;
}

.color-white {
  color: $white !important;
}

.product-detail-image {
  width: 500px;

  @media screen and (max-width: $min-tab) {
    width: 100%;
  }

  &.coupon-detail-image {
    width: 80px;
    height: 80px;
  }

  .coupon-image-box {
    width: 80px;
    height: 80px;
  }
}

.image-ratio1-1 {
  padding-bottom: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative !important;
  cursor: pointer;

  img {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    max-width: 100% !important;
  }
}

.image-ratio16-9 {
  padding-bottom: 56.25% !important;
  width: 100% !important;
  overflow: hidden !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative !important;

  img,
  .video-ratio {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    max-width: 100% !important;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 95% !important;
  }
}

@media (max-width: 575px) {
  .container {
    max-width: 100% !important;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-text-fill-color: #000 !important;
}

input:-moz-autofill {
  -moz-text-fill-color: #000 !important;
  -moz-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}

input:-o-autofill {
  -o-text-fill-color: #000 !important;
  -o-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}

input:-khtml-autofill {
  -khtml-text-fill-color: #000 !important;
  -khtml-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}

.postion-relative {
  position: relative;
}

.recipe-share {

  //margin-right: 10px;
  @media screen and (max-width: $mob) {
    margin-right: 10px;
    border-top: 1px solid #3d3d3d;
    padding-top: 10px;
    margin-top: 10px;
    width: 100%;
  }

  .product-detail-share-icon {
    display: flex;

    li {
      display: block;

      a {
        color: #c5cbcf;
        font-size: 25px;
        padding: 0 5px;
        text-decoration: none;
        box-shadow: none;
        display: block;
        line-height: 0;

        @media screen and (max-width: $min-tab) {
          font-size: 22px;
        }

        .icon-heart-fill {
          color: $danger;
        }
      }
    }
  }
}

.fav-recipes {
  @media screen and (max-width: $tab) {
    padding: 30px 0 0;
  }

  @media screen and (max-width: $mob) {
    padding: 5px 0 0;
  }

  .recipe-img-outer {
    width: 80px;
    height: 80px;
  }

  .recipe {
    padding: 0;
    background: transparent;
    width: 100%;
    cursor: default;

    h3 {
      font-size: 16px;
      color: $green-th;

      @media screen and (max-width: $mob) {
        font-size: 14px;
        position: absolute;
        top: -80px;
        left: 90px;
      }
    }

    .recipe-img-outer {
      width: 80px;
      margin-right: 10px;
    }

    .recipe-img {
      padding-top: 100%;
      width: 100%;
      overflow: hidden;
      background: #bcbcbc;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 10px;
      min-width: 80px;
      cursor: pointer;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100%;
      }

      .discount-label {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
        background: #8ffe26;
        color: #000;
        font-size: 12px !important;
        padding: 3px 5px 1px 6px !important;
        font-weight: bold;
        border-radius: 0 0 0px 10px;
      }
    }

    .recipe-detail {
      @media screen and (max-width: $mob) {
        width: 100%;
        padding: 13px 0 0;
      }

      h3 {
        color: $light-black;
        margin: 0 0 10px;
        font-weight: bold;
        font-size: 16px;

        @media screen and (max-width: $mob) {
          margin: 0 0 10px;
          font-size: 18px;
        }
      }

      .nutrains {
        ul {
          display: flex;
          align-content: center;

          li {
            padding: 0 0;
            margin-right: 35px;

            @media screen and (max-width: $mob) {
              margin-right: 10px;
            }

            &.discount-list {
              margin-right: 80px;

              @media screen and (max-width: $mob) {
                margin-right: 30px;
              }
            }

            h4 {
              color: $white;
              margin: 0 0 5px;
              font-weight: 600;
              font-size: 14px;

              @media screen and (max-width: $mob) {
                font-size: 14px;
              }
            }

            p {
              color: rgba($white, 0.5);
              font-size: 16px;
              margin: 0;
              font-weight: 400;
              line-height: 16px;
              margin-bottom: 0;

              @media screen and (max-width: 1400px) {
                font-size: 14px;
              }

              @media screen and (max-width: $mob) {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }

  .mat-tab-header-pagination {
    box-shadow: 0px 0px 10px rgb(255 255 255 / 20%);
    background: #252f39;

    .mat-tab-header-pagination-chevron {
      border-color: #fff;
    }
  }

  .mat-expansion-panel-header {
    height: auto !important;
    padding: 0;
    align-items: self-start !important;

    &:hover {
      background: transparent !important;
    }
  }

  .mat-expansion-indicator::after,
  .mat-expansion-panel-header-description {
    color: rgba(255, 255, 255, 0.54);
  }

  .mat-expansion-panel-header-title {
    margin-right: 0;

    @media screen and (max-width: $mob) {
      display: inline-block !important;
    }
  }

  mat-expansion-panel.mat-expansion-panel {
    padding: 17px;
    border: 0px solid #414a53;
    border-radius: 6px;
    background: #1a2128;
    margin: 0 5px 20px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

    @media screen and (max-width: $min-tab) {
      padding: 10px;
      margin: 0 0px 20px;
    }
  }

  .mat-expansion-panel-content {
    font-family: $popins;
  }

  .mat-expansion-panel-body {
    padding: 20px 0 0 0 !important;
  }

  .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
  .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    background: transparent;
  }

  .mat-tab-group {
    font-family: $popins;

    .mat-tab-body-content {
      overflow: hidden;
    }
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    display: none;
  }

  .food-contet {
    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
      color: $green-th;
    }

    &.ingredients-items {
      border: 1px solid #414a53;
      padding: 25px !important;
      background: $dark-black;
      border-radius: 0;
      margin: 0 0 20px;

      ul {
        li {
          padding-left: 22px;
          margin-bottom: 15px;
          font-family: $popins;
        }
      }
    }

    ul {
      li {
        position: relative;
        padding-left: 25px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 30px;
        color: $white;

        &:after {
          content: '';
          height: 8px;
          width: 8px;
          background: $white;
          border-radius: 5px;
          position: absolute;
          left: 5px;
          top: 5px;
        }

        h6 {
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 5px;
          font-family: $popins;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 0;
          font-family: $popins;

          @media screen and (max-width: 1400px) {
            font-size: 14px;
          }
        }
      }
    }

    &.nutrition-data {
      ul {
        padding: 10px 10px 0px;
        display: flex;
        flex-wrap: wrap;
        max-width: 600px;

        @media screen and (max-width: $mob) {
          padding: 0px;
        }

        li {
          display: flex;
          align-items: flex-start;
          padding: 0 60px 0 25px;
          margin-bottom: 20px;
          flex: 0 0 50%;
          justify-content: space-between;
          max-width: 50%;

          @media screen and (max-width: $min-tab) {
            flex: 0 0 100%;
            max-width: 100%;
          }

          span {
            width: 50%;

            &:nth-last-child(1) {
              text-align: right;
            }
          }

          .yes {
            background: #1E4976;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            display: flex;
            color: $dark-black;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            overflow: hidden;
          }
        }
      }
    }

    &.source-traceblity {
      background: transparent !important;

      .traceblity-list {
        border-bottom: 1px solid rgb($white, 0.12);
        margin-bottom: 15px;
        padding-bottom: 10px;
        word-break: break-all;

        .date {
          font-weight: 500;
          font-size: 14px;
          display: flex;

          i {
            font-size: 17px;
            color: $green-th;
          }
        }

        .text-secondary {
          font-weight: 500;
          padding-left: 18px;
        }

        &:last-child {
          margin-bottom: 0px !important;
        }
      }
    }
  }

  .brand {
    padding: 20px;

    @media screen and (max-width: $min-tab) {
      padding: 0;
    }

    .brand-logo {
      min-width: 150px;
      border-radius: 10px;
      width: 150px;
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
      }
    }

    .brand-details {
      padding: 0 0 0 30px;

      @media screen and (max-width: $min-tab) {
        padding: 15px 0 0;
        width: 100%;
      }

      strong {
        font-weight: 600;
        color: rgba($light-black, 1);
      }

      p {
        font-size: 16px;
        color: rgba($light-black, 0.8);
        font-weight: 400;
        margin-bottom: 10px;

        @media screen and (max-width: 1400px) {
          font-size: 14px;
        }
      }

      .add {
        font-size: 16px;

        strong {
          font-weight: 400;
          color: rgba($white, 0.62);
          font-size: 14px;
        }

        p {
          display: flex;
          align-items: flex-start;
          color: $white;
          font-size: 16px;
          word-break: break-word;

          span {
            margin-right: 5px;
            font-size: 20px;
            color: $white;
          }

          a {
            color: $white;
          }
        }
      }
    }
  }

  .mat-accordion>.mat-expansion-panel-spacing:last-child,
  .mat-accordion> :last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
    margin-bottom: 20px;
  }
}

.up-arrow {
  .icon-up-arrow {
    &::before {
      transform: rotate(180deg);
    }
  }
}

.mat-expansion-indicator::after {
  padding: 6px !important;
  border-width: 0 3px 3px 0 !important;
  border-color: #c5cbcf !important;
}

.mat-action-row {
  border: none !important;

  @media screen and (max-width: 575px) {
    padding: 0px !important;
  }
}

// .favorite-inner{
//   .mat-tab-group {
//     .mat-tab-labels {
//       .mat-tab-label {
//         &.mat-tab-label-active{
//           background: #8FFE26;
//           opacity: 1;
//           color: #252f39;
//           border-color: #8FFE26;
//           position: relative;
//           overflow: visible;
//         }
//       }
//     }
//    }
// }

.my-fav-tab {
  .mat-tab-header {
    background: transparent !important;
    padding: 35px 0 0px !important;

    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label {
            background: transparent;
            height: auto;
            min-width: auto;
            border: 2px solid #e9e9e9;
            margin: 0 10px;
            border-radius: 50px;
            font-size: 16px;
            font-family: 'Poppins', sans-serif;
            color: #000;
            padding: 7px 25px;
            overflow: hidden !important;

            @media screen and (max-width: 575px) {
              padding: 7px 10px;
              font-size: 13px;
            }

            &.mat-tab-label-active {
              background: #8ffe26 !important;
              opacity: 1;
              color: #252f39;
              border-color: #8ffe26;
              position: relative;
              overflow: hidden !important;
            }
          }
        }
      }
    }
  }

  .mat-tab-body-wrapper {
    border-top: 0px !important;
  }
}

.coupons-detail {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  padding: 13px;
  border-radius: 10px;

  h3 {
    color: #8ffe26;
    font-size: 25px;
  }

  .discount-label-coupon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    background: #8ffe26;
    color: #000;
    font-size: 12px !important;
    padding: 3px 5px 1px 6px !important;
    font-weight: bold;
    border-radius: 0 0 0px 10px;
  }
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
  background: transparent !important;
}

.coupon-image {
  max-width: 150px;
  max-height: 120px;
}

.content-detail-source p {
  display: inline;
}

.disount-detail-list {
  margin-bottom: 20px;

  h4 {
    font-size: 14px !important;
    margin-bottom: 6px;
  }

  p {
    font-size: 14px !important;
    color: rgb($white, 0.5) !important;
  }
}

.mat-expansion-panel-header {
  font-family: 'Poppins', sans-serif !important;
}

.color-gray20 {
  color: #e9e9e9;
}

.coupon-detail-box {
  h5 {
    position: relative;

    @media screen and (max-width: $mob) {
      position: absolute;
      top: 20px;
      left: 122px;
    }
  }

  .disount-detail-list {
    @media screen and (max-width: $mob) {
      margin-top: 15px;
    }
  }
}

.smartbanner {
  height: 100px !important;
  background: #252f39 !important;

  .smartbanner__info {
    height: auto !important;

    .smartbanner__info--title {
      color: #fff !important;
      font-weight: 700 !important;
    }

    .smartbanner__info--author,
    .smartbanner__info--price {
      color: #ccc !important;
    }
  }

  .smartbanner__icon {
    background-repeat: no-repeat;
    width: 55px;
    height: 55px;
    background-size: 55px 55px;
  }

  .smartbanner__button {
    color: #000 !important;
    background: #83e92a !important;
    border: 0 !important;
    min-width: 12%;
    border-radius: 50px;
    align-self: flex-end;
    margin-bottom: 10px;
    font-size: 14px !important;
    padding: 5px 10px !important;
    font-weight: 700 !important;
  }

  .smartbanner__exit {
    position: absolute !important;
    right: 9px;
    top: 10px;
    background: #ffffff !important;
    box-shadow: none !important;
    color: #000000 !important;
    width: 18px !important;
    height: 18px !important;
    border-radius: 14px !important;
    margin-right: 0 !important;
    padding: 0 !important;

    &::after,
    &::before {
      background: #000000 !important;
      top: 3px !important;
      left: 8px !important;
      width: 2px !important;
      height: 11px !important;
    }
  }
}

/***** search option style ****/

.search-option {
  max-height: 400px !important;
  border-radius: 0 0 21.5px 21.5px !important;
  border: 1px solid #7d7d7d;

  .mat-option {
    line-height: inherit;
    padding: 10px 30px;
    height: auto;
    border-top: 1px solid #7d7d7d;

    &:first-child {
      border: none !important;
    }

    .mat-option-text {
      display: flex;
      align-items: center;

      .product-img {
        width: 100px;
        min-width: 100px;
        overflow: hidden;
        border-radius: 3px;
        margin-right: 10px;
        position: relative;

        &::before {
          display: block;
          content: '';
          padding-top: 56.25%;
        }

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h6 {
        font-weight: 400;
        font-size: 16px;
        color: #313131;
        margin-bottom: 5px;
      }

      small {
        font-weight: 400;
        font-size: 12px;
        color: #32cd32;
      }
    }
  }
}

//New header CSS
.headers {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  padding: 10px 0;
  display: flex;
  align-items: center;
  background: $white;
  z-index: 9;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  @media screen and (max-width: $tab) {
    padding: 10px 0 0;
    height: auto;
    background: #fff;
    box-shadow: 0 20px 30px rgba($black, 0.08);
  }

  .container {
    @media screen and (max-width: $tab) {
      max-width: 100% !important;
      padding: 0px 0px;
    }
  }

  &.fixed-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    background: $white;
    padding: 10px 0;
    min-height: inherit;
    height: auto;
    box-shadow: 0 0 20px rgb($black, 0.08);

    @media screen and (max-width: $tab) {
      padding: 10px 0 0;
    }

    .navbar {
      .navbar-brand {
        img {
          max-width: 150px;
          max-height: 65px;

          @media screen and (max-width: $tab) {
            max-width: 80px;
            max-height: 55px;
          }
        }
      }

      ul {
        li {
          a {
            color: $light-black !important;

            @media screen and (max-width: $tab) {
              color: $white !important;
            }

            font-size: 15.3px;

            &.login-btn {
              margin-left: 15px;

              @media screen and (max-width: $tab) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .navbar {
    padding: 0;

    .mobile-header {
      line-height: 0;

      @media screen and (max-width: $tab) {
        max-width: 95%;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px 15px 10px;
      }
    }

    .navbar-brand {
      padding: 0;
      margin: 0;
      width: 200px;

      &:focus {
        box-shadow: none;
        outline: none;
      }

      @media screen and (max-width: $tab) {
        padding: 0;
        margin: 0;
      }

      @media screen and (max-width: $min-tab) {
        width: 50px;
      }

      .image-ratio1-1 {
        transition: all 150ms ease-in-out;
        -webkit-transition: all 150ms ease-in-out;
      }

      img {
        max-width: 200px;
        max-height: 80px;
        -webkit-transition: all 0.2s ease-in-out 0s;
        -moz-transition: all 0.2s ease-in-out 0s;
        transition: all 0.2s ease-in-out 0s;

        @media screen and (max-width: $tab) {
          max-height: 70px;
        }

        @media screen and (max-width: $min-tab) {
          max-height: 50px;
        }

        @media screen and (max-width: $mob) {
          max-height: 48px;
        }

        &.logo-sroll {
          display: none;

          @media screen and (max-width: $tab) {
            display: block;
          }
        }
      }
    }

    .navbar-toggler {
      background: transparent;
      border: none;
      padding: 0;
      border-radius: 0;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      span.navbar-icon {
        background: $light-black;
        height: 4px;
        width: 35px;
        display: block;
        border-radius: 5px;

        @media screen and (max-width: $tab) {
          height: 2px;
          width: 31px;
        }

        @media screen and (max-width: $min-tab) {
          height: 1px;
          width: 25px;
        }

        &.two {
          margin: 5px 0;
        }
      }
    }

    .navbar-collapse {
      .collapse {
        .navbar-nav {
          @media screen and (max-width: $tab) {
            display: none;
          }
        }
      }
    }

    .navbar-collapse {
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $white;

            @media screen and (max-width: $tab) {
              text-align: left;
            }
          }
        }
      }

      @media screen and (max-width: $tab) {
        background: #1d252d;
        padding: 15px 35px !important;
        box-shadow: none;
      }

      @media screen and (max-width: $min-tab) {
        padding: 15px 25px !important;
      }
    }

    ul {
      &.navbar-nav {
        @media screen and (max-width: $tab) {
          display: inherit;
        }
      }

      li {
        margin: 0;

        &.dropdown {
          padding: 0;

          @media screen and (max-width: $tab) {
            margin-top: 0;
          }

          .dropdown-toggle {
            &:hover {
              background: transparent !important;
              color: #38b6ff !important;
              text-decoration: none;

              @media screen and (max-width: $tab) {
                color: #38b6ff !important;
              }
            }
            
            @media screen and (max-width: $tab) {
              padding: 0;
            }
          }
        }

        .nav-profile {
          width: 35px;
          overflow: hidden;
          border-radius: 50%;
        }

        .nav-name {
          p {
            margin: 0;
          }

          &:hover {
            color: #38b6ff !important;

            .caret {
              color: $teal !important;
            }

            @media screen and (max-width: $tab) {
              position: relative;
            }
          }
        }

        .dropdown-menu {
          left: auto !important;
          right: 0 !important;
          padding: 4px 0;
          min-width: 120px;
          border-radius: 7px;
          border: none;
          box-shadow: 0px 0px 5px rgba($black, 0.08);
          top: 50px;

          &:after {
            content: '';
            height: 10px;
            width: 10px;
            position: absolute;
            background: $white;
            right: 15px;
            top: -5px;
            z-index: -1;
            transform: rotate(45deg);

            @media screen and (max-width: $tab) {
              display: none;
            }
          }

          @media screen and (max-width: $tab) {
            padding: 0 0 0 20px;
            width: 100%;
            border: none;
            background: transparent;
            box-shadow: none;
          }

          h4 {
            font-size: 16px;
            margin: 0 0 5px;
          }

          p {
            font-size: 13px;
            margin: 0;
            padding-bottom: 8px;
            border-bottom: 1px solid $gray-25;
            white-space: nowrap;
          }

          li {
            margin: 0;

            a {
              padding: 5px 15px !important;
              color: $light-black !important;
              font-size: 13px;
              -webkit-transition: all 0.2s ease-in-out 0s;
              -moz-transition: all 0.2s ease-in-out 0s;
              transition: all 0.2s ease-in-out 0s;

              @media screen and (max-width: $tab) {
                padding: 6px 0 !important;
                color: $white !important;
              }

              &.active {
                background: #0DC0DD !important;

                @media screen and (max-width: $tab) {
                  color: #0DC0DD !important;
                  background: transparent !important;
                }
              }

              &:hover,
              &:active,
              &:focus {
                color: $white !important;
                cursor: pointer;
                outline: none;
                background: #0DC0DD;

                @media screen and (max-width: $tab) {
                  color: #0DC0DD !important;
                  background: transparent;
                }
              }
            }
          }
        }

        a {
          font-size: 18px;
          font-weight: 400;
          color: $black !important;
          padding: 8px 10px !important;
          letter-spacing: 0.2px;
          -webkit-transition: all 0.2s ease-in-out 0s;
          -moz-transition: all 0.2s ease-in-out 0s;
          transition: all 0.2s ease-in-out 0s;

          @media screen and (max-width: $min-desk) {
            font-size: 16px;
          }

          @media screen and (max-width: $tab) {
            padding: 5px 0 !important;
            color: $white !important;
            font-size: 14px;
          }

          &.login-btn {
            background: #1E4976;
            border-radius: 10px;
            padding: 5px 25px !important;
            display: block;
            font-size: 16px;
            font-weight: 500;
            box-shadow: 0 5px 5px rgba($black, 0.15);
            color: $white !important;
            margin-left: 30px;
            width: 95px;
            height: 32px;

            &:hover {
              background: #ffa710;
              color: $light-black !important;

              @media screen and (max-width: $tab) {
                background: transparent;
                color: $green-th !important;
              }
            }

            @media screen and (max-width: $tab) {
              margin-left: 0;
              background: transparent;
              padding: 5px 0 !important;
              box-shadow: none;
              font-size: 14px;
              color: #fff !important;
              font-weight: 400;
            }
          }

          &:hover {
            color: #38b6ff !important;

            .caret {
              color: $teal !important;
            }

            @media screen and (max-width: $tab) {
              position: relative;
            }
          }

          &.active {
            position: relative;
            color: #1e4976 !important;

            @media screen and (max-width: $tab) {
              background: transparent;
              color: #1e4976 !important;
            }

            &:before {
              content: '';
              height: 1px;
              background: #1e4976 !important;
              position: absolute;
              bottom: 0;
              left: 0;
              width: calc(100% - 0px);
              margin: 0 0px;

              @media screen and (max-width: $tab) {
                margin: 0;
                width: 100%;
                height: 0;
              }
            }
          }
        }
      }
    }
  }
}

footer.copyrights {
  background: #1E4976;
  padding: 10px 0;
  box-shadow: 0 0 20px rgba($black, 0.1);

  .email-btn {
    margin-top: 2px;
  }

  .bottom-links {
    text-align: right;

    @media screen and (max-width: $tab) {
      text-align: center;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0 -10px;
      padding: 0;
      justify-content: flex-end;

      @media screen and (max-width: $min-tab) {
        justify-content: center;
      }

      li {
        margin-right: 5px;

        .linkedIn:hover {
          content: url('/assets/images/linkedIn-blue.png');
          width: 32px;
          height: 30px;
        }

        .facebook:hover {
          content: url('/assets/images/facebook-blue.png');
          width: 31px;
          height: 30px;
        }
      }
    }
  }

  @media screen and (max-width: $tab) {
    padding: 12px 0;
  }

  @media screen and (max-width: $min-tab) {
    text-align: center;
  }

  @media screen and (max-width: $mob) {
    .email-btn {
      margin-top: 8px;
    }
  }

  img {

    max-width: 150px;

    @media screen and (max-width: $tab) {
      max-width: 100px;

      .email-btn {
        margin-top: 10px;
      }
    }

    @media screen and (max-width: $min-tab) {
      max-width: 90px;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    color: $white;
    font-weight: 400;

    @media screen and (max-width: $min-tab) {
      margin: 5px 0px;
      text-align: center;
    }
  }

  a {
    display: inline-block;
    color: $white;
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;

    @media screen and (max-width: $min-tab) {
      text-align: center;
    }

    &:hover {
      color: #38b6ff;
    }
  }
}

.home-page {
  .video-js .vjs-big-play-button {
    background: #FF8719 !important;
  }
}

.contact-us-dialog mat-dialog-container {
  border-radius: 20px;
  padding: 0px !important;
}