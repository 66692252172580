$li-gray: #e8e5e5;
$white: #ffffff !default;
$gray-25: #fffcf2 !default;
$gray-50: #efefef !default;
$gray-100: #a5a5a5 !default;
$gray-500: #cccccc !default;
$gray-600: #435664 !default;
$gray-700: #e3e3e3 !default;
$gray-800: #eeeeee !default;
$gray-808: #808080 !default;
$black: #000000 !default;
$green-th: #8ffe26 !default;
$light-black: #252f39 !default;
$dark-black: #1a2128 !default;
$br-color: #e9e9e9 !default;
$full-desk: 1920px;
$mid-desk: 1600px;
$desktop: 1400px;
$min-desk: 1366px;
$laptop: 1199px;
$max-tab: 1024px;
$tab: 991px;
$min-tab: 767px;
$mob: 575px;
$min-mob: 415px;
$m-mob: 350px;
$grays: () !default;

$grays: map-merge(
  (
    '50': $gray-50,
    '100': $gray-100,
    '600': $gray-600,
    '800': $gray-800,
    '700': $gray-700,
  ),
  $grays
);

$blue: #7ac8cf !default;
$indigo: #141a27 !default;
$purple: #6f42c1 !default;
$pink: #fc7171 !default;
$red: #ff2832 !default;
$orange: #286e91 !default;
$yellow: #fd9902 !default;
$yellow-6: #7ac8cf !default;
$green: #28a745 !default;
$teal: #705446 !default;
$cyan: #e1c391 !default;
$light-cyan: #f3e6cc !default;
$purpul: #7c1ae9 !default;
$purpul-h: #683986 !default;
$facebook: #3c66c4 !default;
$twitter: #08a0e9 !default;
$linkedin: #1686b0 !default;
$pintrest: #e6001a !default;
$google: #cf4231 !default;

$colors: () !default;

$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'yellow-6': $yellow-6,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray-dark': $gray-800,
    'dark-80': $gray-808,
    'dark-7': $gray-700,
    'purpul': $purpul,
    'purpul-h': $purpul-h,
  ),
  $colors
);

$primary: $blue !default;
$secondary: $indigo !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$warning-6: $yellow-6 !default;
$orange: $orange !default;
$danger: $red !default;
$x-light: $gray-50 !default;
$light: $gray-100 !default;
$m-dark: $gray-600 !default;
$dark: $gray-800 !default;
$dark-80: $gray-808 !default;
$dark-7: $gray-700 !default;
$purpul: $purpul !default;
$purpul-h: $purpul-h !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'warning-6': $warning-6,
    'orange': $orange,
    'danger': $danger,
    'x-light': $x-light,
    'm-dark': $m-dark,
    'dark': $dark,
    'dark-7': $dark-7,
    'dark-80': $dark-80,
    'purpul': $purpul,
    'purpul-h': $purpul-h,
  ),
  $theme-colors
);
$popins: 'Poppins', sans-serif;
$login-banner: url('~assets/images/login-banner.png');
$home-banner: url('~assets/images/home-banner.png');
$form-bg: url('~assets/images/form-bg.png');
$inner-bg: url('~assets/images/inner-banner.png');
