/**Fonts**/
@font-face {
  font-family: "icomoon";
  src: url("~assets/fonts/icomoon.eot?ig1nm4");
  src: url("~assets/fonts/icomoon.eot?ig1nm4#iefix") format("embedded-opentype"), url("~assets/fonts/icomoon.ttf?ig1nm4") format("truetype"), url("~assets/fonts/icomoon.woff?ig1nm4") format("woff"), url("~assets/fonts/icomoon.svg?ig1nm4#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/****/
.img-bg-box {
  background: #bcbcbc;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
}

.read-more {
  color: #8ffe26 !important;
  font-size: 12px;
}

.footer-cookies {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .footer-cookies {
    display: block;
  }
}
.footer-cookies p a {
  color: #8ffe26;
  text-decoration: underline;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #252f39;
  position: relative;
  padding: 100px 0 0;
}
@media screen and (max-width: 991px) {
  body {
    padding: 60px 0 0;
  }
}

.body-height {
  min-height: calc(100vh - 151px);
}
@media screen and (max-width: 767px) {
  .body-height {
    min-height: calc(100vh - 118px);
  }
}
@media screen and (max-width: 991px) {
  .body-height {
    min-height: calc(100vh - 149px);
  }
}
@media screen and (max-width: 575px) {
  .body-height {
    min-height: calc(100vh - 135px);
  }
}

a:focus,
*:focus {
  outline: none;
}

[class^=icon-],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border-top: 1px solid #e9e9e9;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  margin: 0;
  list-style: none;
}

.icon-info:before {
  content: "\e900";
}

.icon-angle-arrow-down:before {
  content: "\e901";
}

.icon-arrow:before {
  content: "\e902";
}

.icon-calendar:before {
  content: "\e903";
}

.icon-check:before {
  content: "\e904";
}

.icon-clock:before {
  content: "\e905";
}

.icon-close:before {
  content: "\e906";
}

.icon-close-circle:before {
  content: "\e907";
}

.icon-correct:before {
  content: "\e908";
}

.icon-doller:before {
  content: "\e909";
}

.icon-drop-down:before {
  content: "\e90a";
}

.icon-edit:before {
  content: "\e90b";
}

.icon-email:before {
  content: "\e90c";
}

.icon-facebook:before {
  content: "\e90d";
}

.icon-fancy-loader:before {
  content: "\e90e";
}

.icon-file-download:before {
  content: "\e90f";
}

.icon-filter:before {
  content: "\e910";
}

.icon-filter-line:before {
  content: "\e911";
}

.icon-forword:before {
  content: "\e912";
}

.icon-google-plus:before {
  content: "\e913";
}

.icon-gps:before {
  content: "\e914";
}

.icon-heart:before {
  content: "\e915";
}

.icon-heart-fill:before {
  content: "\e916";
}

.icon-hide-pass:before {
  content: "\e917";
}

.icon-home:before {
  content: "\e918";
}

.icon-instagram:before {
  content: "\e919";
}

.icon-loader:before {
  content: "\e91a";
}

.icon-locality:before {
  content: "\e91b";
}

.icon-mobile:before {
  content: "\e91c";
}

.icon-more:before {
  content: "\e91d";
}

.icon-next-arrow:before {
  content: "\e91e";
}

.icon-password:before {
  content: "\e91f";
}

.icon-phone:before {
  content: "\e920";
}

.icon-pin:before {
  content: "\e921";
}

.icon-place:before {
  content: "\e922";
}

.icon-play:before {
  content: "\e923";
}

.icon-refresh:before {
  content: "\e924";
}

.icon-search:before {
  content: "\e925";
}

.icon-send:before {
  content: "\e926";
}

.icon-share:before {
  content: "\e927";
}

.icon-ship:before {
  content: "\e928";
}

.icon-show-pass:before {
  content: "\e929";
}

.icon-star:before {
  content: "\e92a";
}

.icon-state:before {
  content: "\e92b";
}

.icon-trash:before {
  content: "\e92c";
}

.icon-twitter:before {
  content: "\e92d";
}

.icon-up-arrow:before {
  content: "\e92e";
}

.icon-upload-video:before {
  content: "\e92f";
}

.icon-user:before {
  content: "\e930";
}

.icon-user-b:before {
  content: "\e931";
}

.icon-video:before {
  content: "\e932";
}

.icon-view:before {
  content: "\e933";
}

.icon-view-product:before {
  content: "\e934";
}

.icon-www:before {
  content: "\e935";
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

/**Header**/
header {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  padding: 10px 0;
  display: flex;
  align-items: center;
  background: #252f39;
  z-index: 9;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
@media screen and (max-width: 991px) {
  header {
    padding: 10px 0 0;
    height: auto;
    background: #252f39;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.08);
  }
}
@media screen and (max-width: 991px) {
  header .container {
    max-width: 100% !important;
    padding: 0px 0px;
  }
}
header.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background: #252f39;
  padding: 0 0;
  min-height: inherit;
  height: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}
@media screen and (max-width: 991px) {
  header.fixed-header {
    padding: 10px 0 0;
  }
}
header.fixed-header .navbar .navbar-brand img {
  max-width: 150px;
  max-height: 65px;
}
@media screen and (max-width: 991px) {
  header.fixed-header .navbar .navbar-brand img {
    max-width: 80px;
    max-height: 55px;
  }
}
header.fixed-header .navbar ul li a {
  color: #ffffff !important;
  font-size: 15.3px;
}
header.fixed-header .navbar ul li a.login-btn {
  margin-left: 15px;
}
@media screen and (max-width: 991px) {
  header.fixed-header .navbar ul li a.login-btn {
    margin-left: 0;
  }
}
header .navbar {
  padding: 0;
}
header .navbar .mobile-header {
  line-height: 0;
}
@media screen and (max-width: 991px) {
  header .navbar .mobile-header {
    max-width: 95%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 15px 10px;
  }
}
header .navbar .navbar-brand {
  padding: 0;
  margin: 0;
  width: 200px;
}
header .navbar .navbar-brand:focus {
  box-shadow: none;
  outline: none;
}
@media screen and (max-width: 991px) {
  header .navbar .navbar-brand {
    padding: 0;
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  header .navbar .navbar-brand {
    width: 50px;
  }
}
header .navbar .navbar-brand .image-ratio1-1 {
  transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
}
header .navbar .navbar-brand img {
  max-width: 200px;
  max-height: 80px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
@media screen and (max-width: 991px) {
  header .navbar .navbar-brand img {
    max-height: 70px;
  }
}
@media screen and (max-width: 767px) {
  header .navbar .navbar-brand img {
    max-height: 50px;
  }
}
@media screen and (max-width: 575px) {
  header .navbar .navbar-brand img {
    max-height: 48px;
  }
}
header .navbar .navbar-brand img.logo-sroll {
  display: none;
}
@media screen and (max-width: 991px) {
  header .navbar .navbar-brand img.logo-sroll {
    display: block;
  }
}
header .navbar .navbar-toggler {
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
}
header .navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
header .navbar .navbar-toggler span.navbar-icon {
  background: #ffffff;
  height: 4px;
  width: 35px;
  display: block;
  border-radius: 5px;
}
@media screen and (max-width: 991px) {
  header .navbar .navbar-toggler span.navbar-icon {
    height: 2px;
    width: 31px;
  }
}
@media screen and (max-width: 767px) {
  header .navbar .navbar-toggler span.navbar-icon {
    height: 1px;
    width: 25px;
  }
}
header .navbar .navbar-toggler span.navbar-icon.two {
  margin: 5px 0;
}
@media screen and (max-width: 991px) {
  header .navbar .navbar-collapse .collapse .navbar-nav {
    display: none;
  }
}
header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #ffffff;
}
@media screen and (max-width: 991px) {
  header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    text-align: left;
  }
}
@media screen and (max-width: 991px) {
  header .navbar .navbar-collapse {
    background: #1d252d;
    padding: 15px 35px !important;
    box-shadow: none;
  }
}
@media screen and (max-width: 767px) {
  header .navbar .navbar-collapse {
    padding: 15px 25px !important;
  }
}
@media screen and (max-width: 991px) {
  header .navbar ul.navbar-nav {
    display: inherit;
  }
}
header .navbar ul li {
  margin: 0;
}
header .navbar ul li.dropdown {
  padding: 0;
}
@media screen and (max-width: 991px) {
  header .navbar ul li.dropdown {
    margin-top: 0;
  }
}
header .navbar ul li.dropdown .dropdown-toggle:hover {
  background: transparent !important;
  color: #ffffff !important;
  text-decoration: none;
}
@media screen and (max-width: 991px) {
  header .navbar ul li.dropdown .dropdown-toggle:hover {
    color: #ffffff !important;
  }
}
@media screen and (max-width: 991px) {
  header .navbar ul li.dropdown .dropdown-toggle {
    padding: 0;
  }
}
header .navbar ul li .nav-profile {
  width: 35px;
  overflow: hidden;
  border-radius: 50%;
}
header .navbar ul li .nav-name p {
  margin: 0;
}
header .navbar ul li .dropdown-menu {
  left: auto !important;
  right: 0 !important;
  padding: 4px 0;
  min-width: 120px;
  border-radius: 7px;
  border: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  top: 50px;
}
header .navbar ul li .dropdown-menu:after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  background: #ffffff;
  right: 15px;
  top: -5px;
  z-index: -1;
  transform: rotate(45deg);
}
@media screen and (max-width: 991px) {
  header .navbar ul li .dropdown-menu:after {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  header .navbar ul li .dropdown-menu {
    padding: 0 0 0 20px;
    width: 100%;
    border: none;
    background: transparent;
    box-shadow: none;
  }
}
header .navbar ul li .dropdown-menu h4 {
  font-size: 16px;
  margin: 0 0 5px;
}
header .navbar ul li .dropdown-menu p {
  font-size: 13px;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #fffcf2;
  white-space: nowrap;
}
header .navbar ul li .dropdown-menu li {
  margin: 0;
}
header .navbar ul li .dropdown-menu li a {
  padding: 5px 15px !important;
  color: #252f39 !important;
  font-size: 13px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
@media screen and (max-width: 991px) {
  header .navbar ul li .dropdown-menu li a {
    padding: 6px 0 !important;
    color: #ffffff !important;
  }
}
header .navbar ul li .dropdown-menu li a.active {
  background: #8ffe26 !important;
}
@media screen and (max-width: 991px) {
  header .navbar ul li .dropdown-menu li a.active {
    color: #8ffe26 !important;
    background: transparent !important;
  }
}
header .navbar ul li .dropdown-menu li a:hover, header .navbar ul li .dropdown-menu li a:active, header .navbar ul li .dropdown-menu li a:focus {
  color: #ffffff;
  cursor: pointer;
  outline: none;
  background: #8ffe26;
}
@media screen and (max-width: 991px) {
  header .navbar ul li .dropdown-menu li a:hover, header .navbar ul li .dropdown-menu li a:active, header .navbar ul li .dropdown-menu li a:focus {
    color: #8ffe26 !important;
    background: transparent;
  }
}
header .navbar ul li a {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff !important;
  padding: 8px 10px !important;
  letter-spacing: 0.2px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
@media screen and (max-width: 1366px) {
  header .navbar ul li a {
    font-size: 16px;
  }
}
@media screen and (max-width: 991px) {
  header .navbar ul li a {
    padding: 5px 0 !important;
    color: #ffffff !important;
    font-size: 14px;
  }
}
header .navbar ul li a.login-btn {
  background: #fd9902;
  border-radius: 35px;
  padding: 5px 25px !important;
  display: block;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  color: #ffffff !important;
  margin-left: 30px;
}
header .navbar ul li a.login-btn:hover {
  background: #8ffe26;
  color: #252f39 !important;
}
@media screen and (max-width: 991px) {
  header .navbar ul li a.login-btn:hover {
    background: transparent;
    color: #8ffe26 !important;
  }
}
@media screen and (max-width: 991px) {
  header .navbar ul li a.login-btn {
    margin-left: 0;
    background: transparent;
    padding: 5px 0 !important;
    box-shadow: none;
    font-size: 14px;
    color: #fff !important;
    font-weight: 400;
  }
}
header .navbar ul li a:hover {
  color: #8ffe26 !important;
}
header .navbar ul li a:hover .caret {
  color: #705446 !important;
}
@media screen and (max-width: 991px) {
  header .navbar ul li a:hover {
    position: relative;
  }
}
header .navbar ul li a.active {
  position: relative;
  color: #8ffe26 !important;
}
@media screen and (max-width: 991px) {
  header .navbar ul li a.active {
    background: transparent;
    color: #8ffe26 !important;
  }
}
header .navbar ul li a.active:before {
  content: "";
  height: 1px;
  background: #8ffe26;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 0px);
  margin: 0 0px;
}
@media screen and (max-width: 991px) {
  header .navbar ul li a.active:before {
    margin: 0;
    width: 100%;
    height: 0;
  }
}

/****/
.my_radio {
  position: relative;
}
.my_radio input[type=checkbox],
.my_radio input[type=radio] {
  position: absolute;
  visibility: hidden;
  height: 0;
  width: 0;
  margin: 0;
}
.my_radio input[type=radio]:checked ~ .radio::before {
  background: #8ffe26;
}
.my_radio input[type=radio]:checked ~ .radio {
  background: #e1c391;
}
.my_radio input[type=checkbox]:checked ~ .check,
.my_radio input[type=radio]:checked ~ .check {
  border: 1px solid #0DC0DD;
  background: #0DC0DD;
}
.my_radio input[type=checkbox]:checked ~ .radio {
  background: #e1c391;
}
.my_radio input[type=checkbox]:checked ~ .radio::before {
  background: #0DC0DD;
}
.my_radio input[type=checkbox]:checked ~ .check::before,
.my_radio input[type=radio]:checked ~ .check::before {
  border-color: #ffffff;
  opacity: 1;
}
.my_radio label {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  font-size: 14px;
  padding: 0 0 0 30px;
  height: 20px;
  color: #000000;
  font-weight: 400;
  margin: 0;
  z-index: 6;
  cursor: pointer;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}
@media screen and (max-width: 415px) {
  .my_radio label {
    font-size: 12px;
  }
}
.my_radio .check {
  display: block;
  position: absolute;
  border: 1px solid #a5a5a5;
  background: #ffffff;
  border-radius: 3px;
  height: 20px;
  width: 20px;
  top: 0;
  left: 2px;
  z-index: 4;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}
@media screen and (max-width: 575px) {
  .my_radio .check {
    height: 16px;
    width: 16px;
  }
}
.my_radio .check::before {
  display: block;
  position: absolute;
  content: "";
  width: 7px;
  height: 14px;
  border-right: 2px solid rgba(204, 204, 204, 0.5);
  border-bottom: 2px solid rgba(204, 204, 204, 0.5);
  transform: rotate(45deg);
  left: 6px;
  top: 0;
  opacity: 0;
  transition: all ease-in 150ms;
  -webkit-transition: all ease-in 150ms;
}
@media screen and (max-width: 575px) {
  .my_radio .check::before {
    width: 5px;
    height: 10px;
    left: 5px;
    top: 1px;
  }
}
.my_radio .radio {
  display: block;
  position: absolute;
  background: #cccccc;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  top: 2px;
  left: 2px;
  z-index: 4;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}
.my_radio .radio::before {
  display: block;
  content: "";
  text-align: center;
  height: 11px;
  width: 11px;
  background: #b2b2b2;
  margin: 0 auto;
  left: 0;
  top: 6px;
  right: 0;
  position: absolute;
  border-radius: 20px;
  transition: all ease-in 150ms;
  -webkit-transition: all ease-in 150ms;
}

/****/
.slide-banner img {
  position: absolute;
  top: 0;
  bottom: 0px;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .slide-banner {
    height: 100%;
    background-size: 100%;
  }
}
@media screen and (max-width: 575px) {
  .slide-banner {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 1366px) {
  .slide-banner:after {
    transform: skew(0deg, -1.5deg);
    height: 80px;
    bottom: -48px;
  }
}
@media screen and (max-width: 991px) {
  .slide-banner:after {
    height: 40px;
    bottom: -22px;
  }
}
@media screen and (max-width: 575px) {
  .slide-banner:after {
    display: none;
  }
}
.slide-banner .banner-content {
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0px auto;
  position: absolute;
  bottom: 75px;
}
@media screen and (min-width: 1400px) {
  .slide-banner .banner-content {
    margin-bottom: 45px;
  }
}
@media screen and (max-width: 991px) {
  .slide-banner .banner-content {
    max-width: 440px;
  }
}
@media screen and (max-width: 767px) {
  .slide-banner .banner-content {
    padding: 0 15px;
    bottom: 50px;
  }
}
@media screen and (max-width: 575px) {
  .slide-banner .banner-content {
    bottom: 35px;
  }
}
@media screen and (max-width: 350px) {
  .slide-banner .banner-content {
    bottom: 15px;
  }
}
.slide-banner .banner-content .icon-search {
  top: 23px;
  left: 17px;
}
@media screen and (max-width: 1366px) {
  .slide-banner .banner-content .icon-search {
    top: 17px;
    left: 15px;
  }
}
@media screen and (max-width: 991px) {
  .slide-banner .banner-content .icon-search {
    top: 17px;
  }
}
@media screen and (max-width: 767px) {
  .slide-banner .banner-content .icon-search {
    top: 12px;
  }
}
@media screen and (max-width: 575px) {
  .slide-banner .banner-content .icon-search {
    top: 8px;
  }
}
.slide-banner .banner-content .serch-in {
  max-width: 500px;
  width: 100%;
  margin: 10px auto 0px;
}
@media screen and (max-width: 767px) {
  .slide-banner .banner-content .serch-in {
    max-width: 400px;
    width: 100%;
  }
}
.slide-banner .banner-content .serch-in input {
  padding: 20px 50px;
}
@media screen and (max-width: 1366px) {
  .slide-banner .banner-content .serch-in input {
    padding: 16px 50px;
  }
}
@media screen and (max-width: 991px) {
  .slide-banner .banner-content .serch-in input {
    padding: 15px 50px 15px 50px;
  }
}
@media screen and (max-width: 767px) {
  .slide-banner .banner-content .serch-in input {
    padding: 10px 50px 10px 50px;
  }
}
@media screen and (max-width: 575px) {
  .slide-banner .banner-content .serch-in input {
    padding: 7px 50px 7px 50px;
  }
}
.slide-banner .banner-content h1 {
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  color: #8ffe26;
}
@media screen and (max-width: 991px) {
  .slide-banner .banner-content h1 {
    font-size: 30px;
    line-height: 26px;
  }
}
@media screen and (max-width: 767px) {
  .slide-banner .banner-content h1 {
    font-size: 25px;
    line-height: 26px;
  }
}
@media screen and (max-width: 575px) {
  .slide-banner .banner-content h1 {
    line-height: 18px;
    font-size: 18px;
  }
}
.slide-banner .banner-content h2 {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 25px;
  font-weight: bold;
  color: #000000;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .slide-banner .banner-content h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 767px) {
  .slide-banner .banner-content h2 {
    font-size: 25px;
    line-height: 40px;
    margin: 0 0 10px;
  }
}
@media screen and (max-width: 575px) {
  .slide-banner .banner-content h2 {
    margin: 0px;
    font-size: 18px;
    line-height: 20px;
  }
}

.serch-in {
  position: relative;
  max-width: 485px;
  width: 100%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .serch-in {
    max-width: 400px;
  }
}
@media screen and (max-width: 767px) {
  .serch-in {
    max-width: 360px;
  }
}
@media screen and (max-width: 575px) {
  .serch-in {
    position: relative;
  }
}
@media screen and (max-width: 415px) {
  .serch-in {
    margin: 0 auto;
    max-width: 360px;
  }
}
.serch-in span.icon-close {
  position: absolute;
  top: 22px;
  left: 25px;
  font-size: 17px;
  color: #2c2c2c;
  cursor: pointer;
}
@media screen and (max-width: 1366px) {
  .serch-in span.icon-close {
    top: 22px;
    left: 20px;
  }
}
@media screen and (max-width: 991px) {
  .serch-in span.icon-close {
    font-size: 17px;
    top: 18px;
    left: 23px;
  }
}
@media screen and (max-width: 767px) {
  .serch-in span.icon-close {
    top: 11px;
    left: 19px;
  }
}
@media screen and (max-width: 575px) {
  .serch-in span.icon-close {
    left: 11px;
    font-size: 13px;
    top: 14px;
  }
}
.serch-in span.icon-search {
  font-size: 22px;
  color: rgba(37, 47, 57, 0.36);
  position: absolute;
  top: 18px;
  left: 20px;
}
@media screen and (max-width: 991px) {
  .serch-in span.icon-search {
    top: 16px;
    left: 20px;
  }
}
@media screen and (max-width: 767px) {
  .serch-in span.icon-search {
    left: 15px;
    top: 11px;
  }
}
.serch-in span.icon-arrow {
  font-size: 18px;
  color: #252f39;
  position: absolute;
  top: 14px;
  right: 14px;
  height: 38px;
  width: 38px;
  border-radius: 28px;
  background: #8ffe26;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 32px;
}
@media screen and (max-width: 1366px) {
  .serch-in span.icon-arrow {
    top: 10px;
    right: 10px;
  }
}
@media screen and (max-width: 991px) {
  .serch-in span.icon-arrow {
    top: 10px;
    right: 10px;
  }
}
@media screen and (max-width: 767px) {
  .serch-in span.icon-arrow {
    top: 4px;
    right: 4px;
  }
}
@media screen and (max-width: 575px) {
  .serch-in span.icon-arrow {
    top: 5px;
    right: 5px;
    height: 30px;
    width: 30px;
  }
}
.serch-in span.icon-arrow:hover {
  background: #252f39;
  color: #ffffff;
  cursor: pointer;
}
.serch-in input {
  padding: 15px 50px 15px 50px;
  border-radius: 21.5px;
  width: 100%;
  box-shadow: none;
  outline: none;
  border: 1px solid #7d7d7d;
  font-size: 16px;
  color: #252f39;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background: white;
}
@media screen and (max-width: 991px) {
  .serch-in input {
    padding: 13px 15px 13px 50px;
  }
}
@media screen and (max-width: 767px) {
  .serch-in input {
    padding: 8px 30px 8px 45px;
  }
}
@media screen and (max-width: 575px) {
  .serch-in input {
    padding: 7px 36px 7px 27px;
  }
}
.serch-in input::placeholder {
  color: #252f39;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  opacity: 1;
}
.serch-in input::placeholder::first-letter {
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .serch-in input::placeholder {
    font-size: 13px;
  }
}
.serch-in input:focus {
  background-color: #ffffff;
}
.serch-in input:-ms-input-placeholder {
  color: #252f39;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}
.serch-in input:-ms-input-placeholder::first-letter {
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .serch-in input:-ms-input-placeholder {
    font-size: 13px;
  }
}
.serch-in input::-ms-input-placeholder {
  color: #252f39;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 25px;
  line-height: 16px;
}
.serch-in input::-ms-input-placeholder::first-letter {
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .serch-in input::-ms-input-placeholder {
    font-size: 13px;
  }
}
.serch-in input.open-suggestion-box {
  border-radius: 21.5px 21.5px 0 0;
  border-bottom: 1px solid #ff000000 !important;
}

.title {
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .title {
    margin-bottom: 5px;
  }
}
.title h2 {
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  word-break: break-word;
}
@media screen and (max-width: 991px) {
  .title h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 767px) {
  .title h2 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
  }
}
.title img {
  max-width: 70px;
  width: 100%;
}
.title h3 {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 42px;
}
@media screen and (max-width: 991px) {
  .title h3 {
    font-size: 22px;
    line-height: 36px;
  }
}
@media screen and (max-width: 767px) {
  .title h3 {
    line-height: 25px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 575px) {
  .title h3 {
    font-size: 20px;
  }
}
.title p {
  font-size: 20px;
  margin-bottom: 0;
  color: rgba(37, 47, 57, 0.62);
}
@media screen and (max-width: 1400px) {
  .title p {
    font-size: 16px;
  }
}
@media screen and (max-width: 575px) {
  .title p {
    font-size: 14px;
  }
}

.our-story {
  padding: 80px 0px;
}
@media screen and (max-width: 1366px) {
  .our-story {
    padding: 65px 0;
  }
}
@media screen and (max-width: 767px) {
  .our-story {
    text-align: center;
    padding: 45px 0;
  }
}
@media screen and (max-width: 575px) {
  .our-story {
    padding: 30px 0 40px;
  }
}
.our-story h3 {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 50px;
}
@media screen and (max-width: 991px) {
  .our-story h3 {
    font-size: 28px;
    line-height: 35px;
  }
}
@media screen and (max-width: 767px) {
  .our-story h3 {
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 28px;
  }
}
.our-story p {
  font-size: 14px;
  margin-bottom: 0;
  color: #252f39;
  line-height: 28px;
}
@media screen and (max-width: 991px) {
  .our-story p {
    font-size: 14px;
  }
}
.our-story p a {
  color: #8ffe26;
  font-size: 13px;
}
.our-story .story-img {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .our-story .story-img {
    padding-top: 20px;
  }
  .our-story .story-img .video,
.our-story .story-img #player-wrapper > div,
.our-story .story-img .video video {
    height: auto !important;
  }
}
.our-story .story-img .video {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -khtml-border-radius: 20px;
  overflow: hidden;
  background: #252f39;
}
.our-story .story-img .video div {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -khtml-border-radius: 20px;
}
.our-story .story-img .video img {
  width: 100%;
}
.our-story .story-img .video video {
  height: 325px;
  width: 100%;
}

.howitwork {
  padding: 50px 0;
  background: rgba(233, 233, 233, 0.4);
}
.howitwork .serch-in input {
  border-color: #7d7d7d;
  padding-right: 15px;
}
@media screen and (max-width: 575px) {
  .howitwork .serch-in input {
    padding-right: 45px;
  }
}
@media screen and (max-width: 991px) {
  .howitwork {
    padding: 35px 0;
  }
}
.howitwork .title {
  margin-bottom: 65px;
}
@media screen and (max-width: 991px) {
  .howitwork .title {
    margin-bottom: 10px;
  }
}
.howitwork .title p {
  margin: 20px auto 0;
  max-width: 930px;
}
@media screen and (max-width: 767px) {
  .howitwork .title p {
    margin-bottom: 10px;
  }
}
.howitwork .pro-box,
.howitwork .step-box {
  padding: 0 30px;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .howitwork .pro-box,
.howitwork .step-box {
    padding: 0 0px;
  }
}
@media screen and (max-width: 991px) {
  .howitwork .pro-box,
.howitwork .step-box {
    padding: 0 0 15px 0;
  }
}
.howitwork .pro-box .img img,
.howitwork .step-box .img img {
  max-width: 318px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .howitwork .pro-box .img img,
.howitwork .step-box .img img {
    max-width: 250px;
  }
}
.howitwork .pro-box .content,
.howitwork .step-box .content {
  padding: 15px;
  text-align: center;
}
.howitwork .pro-box .content h4,
.howitwork .step-box .content h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;
}
@media screen and (max-width: 991px) {
  .howitwork .pro-box .content h4,
.howitwork .step-box .content h4 {
    font-size: 18px;
  }
}
.howitwork .pro-box .content h4:after,
.howitwork .step-box .content h4:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  height: 3px;
  width: 50px;
  background: #8ffe26;
}
.howitwork.product-list {
  background: #ffffff;
  padding: 40px 0 10px;
}
@media screen and (max-width: 991px) {
  .howitwork.product-list {
    padding: 70px 0px;
  }
}
@media screen and (max-width: 767px) {
  .howitwork.product-list {
    padding: 30px 0px;
  }
}
.howitwork.product-list .title {
  margin-bottom: 35px;
}
@media screen and (max-width: 767px) {
  .howitwork.product-list .title {
    margin-bottom: 0px;
    margin-top: 25px;
  }
}
.howitwork.product-list .title h2 {
  font-weight: 600;
}
.howitwork.product-list .title h3 {
  margin-bottom: 0;
  font-weight: 600;
  color: #1e4976 !important;
}
.howitwork.product-list .col-md-4 {
  margin-bottom: 30px;
}
.howitwork.product-list .pro-box {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  overflow: hidden;
  border-radius: 5px;
  padding: 0;
  text-align: center;
  margin: 0 auto;
  position: relative;
  height: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.2s;
}
@media screen and (max-width: 991px) {
  .howitwork.product-list .pro-box {
    padding: 0 0 65px 0;
  }
}
.howitwork.product-list .pro-box:hover {
  transform: scale(1.02);
  transition: all ease-in-out 0.2s;
}
.howitwork.product-list .pro-box .img {
  padding-bottom: 56.25%;
  width: 100%;
  overflow: hidden;
  background: #bcbcbc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.howitwork.product-list .pro-box .img img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
}
.howitwork.product-list .pro-box .img ul {
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 8;
}
.howitwork.product-list .pro-box .img ul li a {
  color: #ababab;
  padding: 0;
  background: #ffffff;
  margin-left: 10px;
  font-size: 20px;
  height: 30px;
  width: 30px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.howitwork.product-list .pro-box .img ul li a span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.howitwork.product-list .pro-box .img ul li a:hover {
  text-decoration: none;
  color: #252f39;
}
.howitwork.product-list .pro-box .img ul li a .icon-heart-fill {
  color: #ff2832;
}
.howitwork.product-list .pro-box .hover-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  display: flex;
  align-items: flex-end;
  padding: 15px 20px 35px;
  justify-content: center;
  visibility: hidden;
  transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  opacity: 0;
  border-radius: 5px;
  background: rgba(37, 47, 57, 0);
  background: -moz-linear-gradient(top, rgba(37, 47, 57, 0) 0%, rgba(37, 47, 57, 0) 0%, rgba(37, 47, 57, 0) 48%, rgba(37, 47, 57, 0.58) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(0%, rgba(0, 0, 0, 0)), color-stop(48%, rgba(37, 47, 57, 0)), color-stop(100%, rgba(37, 47, 57, 0.58)));
  background: -webkit-linear-gradient(top, rgba(37, 47, 57, 0) 0%, rgba(37, 47, 57, 0) 0%, rgba(37, 47, 57, 0) 48%, rgba(37, 47, 57, 0.58) 100%);
  background: -o-linear-gradient(top, rgba(37, 47, 57, 0) 0%, rgba(37, 47, 57, 0) 0%, rgba(37, 47, 57, 0) 48%, rgba(37, 47, 57, 0.58) 100%);
  background: -ms-linear-gradient(top, rgba(37, 47, 57, 0) 0%, rgba(37, 47, 57, 0) 0%, rgba(37, 47, 57, 0) 48%, rgba(37, 47, 57, 0.58) 100%);
  background: linear-gradient(to bottom, rgba(37, 47, 57, 0) 0%, rgba(37, 47, 57, 0) 0%, rgba(37, 47, 57, 0) 48%, rgba(37, 47, 57, 0.58) 100%);
}
@media screen and (max-width: 991px) {
  .howitwork.product-list .pro-box .hover-box {
    background: transparent;
    bottom: 0;
    opacity: 1;
    visibility: visible;
    height: auto;
    width: auto;
    padding: 0 0 20px;
  }
}
.howitwork.product-list .pro-box .hover-box .btn {
  padding: 7px 15px;
  border-radius: 40px;
  width: 100%;
  max-width: 180px;
}
@media screen and (max-width: 767px) {
  .howitwork.product-list .pro-box .hover-box .btn {
    padding: 5px 8px;
    font-size: 14px;
  }
}
.howitwork.product-list .pro-box:hover {
  border-color: #89cadb;
}
.howitwork.product-list .pro-box:hover .hover-box {
  visibility: visible;
  opacity: 1;
}
.howitwork.product-list .pro-box .content h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  padding-bottom: 0;
}
@media screen and (max-width: 991px) {
  .howitwork.product-list .pro-box .content h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }
}
.howitwork.product-list .pro-box .content h4:after {
  display: none;
}
.howitwork.product-list .pro-box .content h5 {
  color: #38b6ff;
  font-size: 16px;
  margin: 0 0 7px;
}
@media screen and (max-width: 991px) {
  .howitwork.product-list .pro-box .content h5 {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.howitwork.product-list .pro-box .content p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(37, 47, 57, 0.6);
  margin: 0;
}
@media screen and (max-width: 1400px) {
  .howitwork.product-list .pro-box .content p {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .howitwork.product-list .pro-box .content p {
    font-size: 14px;
  }
}
.howitwork.product-list .product-spiner ngx-spinner .ngx-spinner-overlay {
  background: transparent !important;
  position: inherit !important;
}
.howitwork.product-list .product-spiner ngx-spinner .ngx-spinner-overlay .la-square-jelly-box {
  position: relative;
  top: 30px;
  left: 0;
  margin: auto;
}
.howitwork p {
  color: rgba(37, 47, 57, 0.62);
}
.howitwork .download-part {
  text-align: center;
}
.howitwork .download-part h5 {
  font-size: 20px;
  text-align: center;
}
.howitwork .download-part .btn {
  padding: 0;
  margin: 15px 10px 0;
}
@media screen and (max-width: 575px) {
  .howitwork .download-part .btn {
    margin: 15px 2px 0;
  }
}
.howitwork .download-part .btn:focus {
  outline: none;
  box-shadow: none;
}
@media screen and (max-width: 575px) {
  .howitwork .download-part .btn img {
    max-width: 125px;
  }
}

.getin-touch {
  padding: 50px 0;
}
@media screen and (max-width: 991px) {
  .getin-touch {
    padding: 20px 0 40px;
  }
}
@media screen and (max-width: 767px) {
  .getin-touch {
    padding: 20px 0;
  }
}
@media screen and (max-width: 575px) {
  .getin-touch {
    padding: 20px 0 0px;
  }
}
.getin-touch h3 {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 50px;
}
@media screen and (max-width: 991px) {
  .getin-touch h3 {
    font-size: 32px;
    line-height: 38px;
  }
}
@media screen and (max-width: 991px) {
  .getin-touch h3 {
    font-size: 22px;
    line-height: 28px;
  }
}
.getin-touch .get-form {
  max-width: 670px;
  margin: 0 auto;
  padding: 30px 70px;
  background: #8ffe26;
  border-radius: 20px;
}
@media screen and (max-width: 767px) {
  .getin-touch .get-form {
    padding: 30px;
    max-width: 530px;
  }
}
@media screen and (max-width: 575px) {
  .getin-touch .get-form {
    border-radius: 0;
    max-width: 100%;
    border: none;
    padding: 15px;
  }
}
.getin-touch .get-form h4 {
  font-size: 21px;
  margin: 0 0 30px;
  text-align: center;
  font-weight: 600;
}
@media screen and (max-width: 575px) {
  .getin-touch .get-form h4 {
    font-size: 20px;
  }
}
.getin-touch .get-form input {
  border-color: rgba(40, 167, 69, 0.2);
  color: #252f39;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
}
.getin-touch .get-form input::placeholder {
  color: rgba(37, 47, 57, 0.4);
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  opacity: 1;
}
.getin-touch .get-form input::placeholder::first-letter {
  text-transform: uppercase;
}
.getin-touch .get-form input:-ms-input-placeholder {
  color: rgba(37, 47, 57, 0.4);
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
}
.getin-touch .get-form input:-ms-input-placeholder::first-letter {
  text-transform: uppercase;
}
.getin-touch .get-form input::-ms-input-placeholder {
  color: rgba(37, 47, 57, 0.4);
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 25px;
  line-height: 14px;
}
.getin-touch .get-form input::-ms-input-placeholder::first-letter {
  text-transform: uppercase;
}
.getin-touch .get-form textarea {
  border-color: rgba(40, 167, 69, 0.2);
  resize: none;
  padding: 10px;
}
.getin-touch .get-form textarea::placeholder {
  color: rgba(37, 47, 57, 0.4);
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  opacity: 1;
}
.getin-touch .get-form textarea::placeholder::first-letter {
  text-transform: uppercase;
}
.getin-touch .get-form textarea:-ms-input-placeholder {
  color: rgba(37, 47, 57, 0.4);
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
}
.getin-touch .get-form textarea:-ms-input-placeholder::first-letter {
  text-transform: uppercase;
}
.getin-touch .get-form textarea::-ms-input-placeholder {
  color: rgba(37, 47, 57, 0.4);
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 25px;
  line-height: 14px;
}
.getin-touch .get-form textarea::-ms-input-placeholder::first-letter {
  text-transform: uppercase;
}

.btn.theme-btn {
  background: #252f39;
  color: #ffffff;
  padding: 7px 35px;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .btn.theme-btn {
    font-size: 14px;
    padding: 5px 30px;
  }
}
.btn.theme-btn:hover {
  background: #252f39;
  color: #ffffff;
}
.btn.btn-yellow {
  background: #fd9902;
  color: #ffffff;
  padding: 7px 35px;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 400;
}
.btn.btn-yellow:hover {
  background: #ffffff;
  color: #252f39;
}
.btn.navi-btn {
  background: #252f39;
  color: #8ffe26;
  padding: 7px 35px;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 500;
}
.btn.navi-btn:hover {
  background: #ffffff;
  color: #252f39;
}
.btn.border-btn {
  background: #ffffff;
  color: #252f39;
  border: 1px solid #252f39;
  border-radius: 50px;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}

.inner-head {
  background: url("~assets/images/home-banner.png");
  padding: 132px 0 25px;
  background-position: top -20px center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 991px) {
  .inner-head {
    padding: 40px 0 40px;
  }
}
@media screen and (max-width: 575px) {
  .inner-head {
    background-position: top 0 center;
  }
}
@media screen and (max-width: 415px) {
  .inner-head .inner-search {
    display: block !important;
    text-align: center;
  }
}
.inner-head .product-share-btn {
  background: #ffffff;
  color: #000000;
  border-radius: 50%;
  height: 65px;
  width: 65px;
  margin-left: 15px;
  min-width: 65px;
  font-size: 25px;
  line-height: 62px;
}
@media screen and (max-width: 991px) {
  .inner-head .product-share-btn {
    height: 50px;
    width: 50px;
    min-width: 50px;
    font-size: 20px;
    line-height: 42px;
  }
}
@media screen and (max-width: 767px) {
  .inner-head .product-share-btn {
    height: 37px;
    width: 37px;
    min-width: 37px;
    font-size: 18px;
    line-height: 40px;
    margin-left: 6px;
    padding: 0;
  }
}
.inner-head .product-share-btn.after-fliter {
  color: #8ffe26;
}
.inner-head .search-btn {
  background: #8ffe26;
  color: #252f39;
  border-radius: 50%;
  height: 65px;
  min-width: 65px;
  margin-left: 15px;
  font-size: 20px;
  line-height: 55px;
  padding: 4px 15px;
}
@media screen and (max-width: 991px) {
  .inner-head .search-btn {
    height: 50px;
    min-width: 50px;
    font-size: 16px;
    line-height: 38px;
    font-weight: 500;
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .inner-head .search-btn {
    height: 37px;
    min-width: 37px;
    font-size: 16px;
    line-height: 37px;
    margin-left: 6px;
    padding: 0;
  }
}
@media screen and (max-width: 415px) {
  .inner-head .search-btn {
    margin-left: 0;
  }
}

.product-search {
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .product-search {
    padding: 20px 0px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 575px) {
  .product-search {
    background-position: top 0 center;
  }
}
@media screen and (max-width: 575px) {
  .product-search .serch-in span.icon-search {
    left: 9px;
    top: 13px;
    font-size: 14px;
  }
}
.product-search .product-share-btn {
  position: relative;
  background: #252f39;
  color: #ffa710;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-left: 15px;
  min-width: 60px;
  font-size: 24px;
  line-height: 50px;
}
@media screen and (max-width: 991px) {
  .product-search .product-share-btn {
    height: 50px;
    width: 50px;
    min-width: 50px;
    font-size: 20px;
    line-height: 42px;
  }
}
@media screen and (max-width: 767px) {
  .product-search .product-share-btn {
    height: 33px;
    width: 33px;
    min-width: 33px;
    font-size: 16px;
    line-height: 34px;
    margin-left: 6px;
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .product-search .product-share-btn {
    margin-left: 3px;
  }
}
.product-search .product-share-btn.after-fliter {
  color: #a5a5a5;
  background-color: #252f39;
}
.product-search .product-share-btn .icon-check {
  color: #8ffe26;
  position: absolute;
  right: 9px;
  top: 9px;
}
@media screen and (max-width: 767px) {
  .product-search .product-share-btn .icon-check {
    right: 4px;
    top: 4px;
    font-size: 14px;
  }
}
.product-search .search-btn {
  background: #ffa710;
  color: #252f39;
  border-radius: 50%;
  height: 60px;
  min-width: 60px;
  width: 60px;
  margin-left: 15px;
  font-size: 18px;
  line-height: 50px;
  padding: 4px 15px;
}
@media screen and (max-width: 991px) {
  .product-search .search-btn {
    height: 50px;
    min-width: 50px;
    width: 50px;
    font-size: 16px;
    line-height: 38px;
    font-weight: 500;
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .product-search .search-btn {
    height: 33px;
    min-width: 33px;
    width: 33px;
    font-size: 14px;
    line-height: 33px;
    margin-left: 6px;
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .product-search .search-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 30px;
    min-width: 30px;
    width: 30px;
    font-size: 12px;
    /* line-height: 33px; */
    margin-left: 6px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 415px) {
  .product-search .search-btn {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .mobile-head {
    display: none;
  }
}

.about-img {
  text-align: center;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.about-img .video-js .vjs-big-play-button {
  left: 0;
  right: 0;
  top: 50% !important;
  margin: 0 auto;
}
.about-img img {
  margin: 0 auto;
  max-width: 100%;
}

.product-detail {
  padding: 35px 0;
  display: inline-block;
  width: 100%;
}
@media screen and (max-width: 991px) {
  .product-detail {
    padding: 70px 0;
  }
}
@media screen and (max-width: 767px) {
  .product-detail {
    padding: 30px 0;
  }
}
@media screen and (max-width: 767px) {
  .product-detail.details-inner .container {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .product-detail.details-inner .container .row {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .product-detail .details {
    padding: 10px 0 0 0;
  }
}
.product-detail .product-detail-dark {
  background: #252f39;
  padding: 20px;
  border-radius: 15px;
}
@media screen and (max-width: 767px) {
  .product-detail .product-detail-dark {
    padding: 10px 10px;
  }
}
@media screen and (max-width: 575px) {
  .product-detail .product-detail-dark {
    border-radius: 0px;
    padding: 10px 0px;
  }
}
.product-detail .product-detail-dark p {
  color: #e9e9e9;
  padding-top: 0px;
}
.product-detail .product-detail-dark p .icon-check {
  font-size: 17px;
  color: #8ffe26;
}
.product-detail .product-detail-dark .price-transparency-tab {
  color: #ffffff;
}
.product-detail .product-detail-dark .price-transparency-tab h5 {
  font-weight: 600;
  font-size: 20px;
}
.product-detail .product-detail-dark .price-transparency-tab h6 {
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}
.product-detail .product-detail-dark .price-transparency-tab a {
  font-size: 18px;
  display: block;
  padding: 6px 0 0 7px;
  text-decoration: none;
  color: #ffffff;
}
.product-detail .product-detail-dark .price-transparency-tab .cost-info {
  width: 250px;
  position: absolute;
  left: 0;
  top: 30px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  padding: 5px;
  border-radius: 5px;
  background: #ffffff;
  display: none;
  z-index: 2;
}
.product-detail .product-detail-dark .price-transparency-tab a:hover ~ .cost-info {
  display: block;
}
.product-detail .product-detail-dark .price-transparency-tab .cost-progress-bar {
  margin: auto;
  width: 100px;
  position: relative;
}
.product-detail .product-detail-dark .price-transparency-tab .cost-progress-bar mat-progress-spinner {
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 10px #9a9ea2;
}
.product-detail .product-detail-dark .price-transparency-tab .cost-progress-bar mat-progress-spinner svg circle {
  stroke: #8ffe26;
}
.product-detail .product-detail-dark .price-transparency-tab .cost-progress-bar .percent-text {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  top: 29px;
  line-height: 15px;
  color: white;
  z-index: 1;
  font-size: 10px;
}
.product-detail .product-detail-dark .price-transparency-tab .cost-progress-bar .percent-text .h5 {
  font-weight: 400;
}
.product-detail .simmer-animation {
  height: 202.5px;
  max-width: 360px;
  margin: 40px auto;
  width: 100%;
  left: 0;
  right: 0;
  border-radius: 25px;
}
.product-detail .simmer-animation.content-shimmer {
  max-width: 100%;
  height: 20px;
  border-radius: 0;
  margin-top: 0;
}
.product-detail .simmer-animation.tab-animation {
  max-width: 150px;
  height: 50px;
  border-radius: 50px;
  margin: 0 10px 10px;
}
.product-detail .simmer-animation span {
  font-size: 35px;
  background: #e9e9e9;
  color: #ffffff;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  line-height: 70px;
  transform: rotate(30deg);
  text-align: center;
}
.product-detail .details-img {
  padding-bottom: 56.25%;
  width: 100%;
  overflow: hidden;
  background: #bcbcbc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 15px;
}
.product-detail .details-img img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
}
.product-detail .details-img img:hover {
  cursor: pointer;
  box-shadow: 0px 0px 18px rgba(255, 255, 255, 0.3);
}
.product-detail .details {
  color: #ffffff;
}
.product-detail .details h2 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 0;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .product-detail .details h2 {
    font-size: 16px;
  }
}
.product-detail .details p {
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 25px;
}
@media screen and (max-width: 1400px) {
  .product-detail .details p {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .product-detail .details p {
    font-size: 14px;
    margin-bottom: 13px;
  }
}
.product-detail .details p span {
  color: #38b6ff;
}
.product-detail .details p:nth-last-child(1) {
  margin-bottom: 0;
}
.product-detail .details .product-detail-share-icon {
  display: flex;
}
.product-detail .details .product-detail-share-icon li {
  display: block;
}
.product-detail .details .product-detail-share-icon li a {
  color: #c5cbcf;
  font-size: 34px;
  padding: 0 5px;
  text-decoration: none;
  box-shadow: none;
  display: block;
  line-height: 0;
}
@media screen and (max-width: 767px) {
  .product-detail .details .product-detail-share-icon li a {
    font-size: 22px;
  }
}
.product-detail .details .product-detail-share-icon li a .icon-heart-fill {
  color: #ff2832;
}
.product-detail .detail-page {
  max-width: 1000px;
  position: relative;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
  background: #ffffff;
}
@media screen and (max-width: 1366px) {
  .product-detail .detail-page {
    max-width: 950px;
  }
}
@media screen and (max-width: 767px) {
  .product-detail .detail-page {
    margin: 0 auto 30px;
    max-width: 750px;
    height: auto;
  }
}
.product-detail .detail-page.video {
  height: auto !important;
  border-radius: 25px;
}
.product-detail .detail-page .video-custume-class {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  height: 100%;
}
.product-detail .detail-page .video-custume-class > div {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  overflow: hidden;
  height: 100% !important;
}
@media screen and (max-width: 767px) {
  .product-detail .detail-page .video-custume-class > div {
    width: 100%;
  }
}
.product-detail .detail-page .video-custume-class .video-js {
  min-width: 100% !important;
  min-height: 100%;
}
.product-detail .detail-page .video-custume-class .video-js .vjs-big-play-button {
  left: 0;
  margin: 0 auto;
  right: 0;
  top: 180px;
}
.product-detail .detail-page .video-custume-class .video-js .vjs-poster {
  border: 0px;
  outline: none;
}
.product-detail .detail-page video {
  width: 100%;
  height: 100%;
}
.product-detail .detail-page img {
  width: 100%;
}
.product-detail .tab-details {
  padding: 20px 0 0;
}
.product-detail .tab-details.recipe-detail-header {
  padding: 6px 0 !important;
}
.product-detail .tab-details.recipe-detail-header .mat-tab-header {
  display: none !important;
}
.product-detail .tab-details.recipe-detail-header .mat-expansion-panel {
  margin-bottom: 0px !important;
}
@media screen and (max-width: 991px) {
  .product-detail .tab-details {
    padding: 30px 0 0;
  }
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details {
    padding: 5px 0 0;
  }
}
.product-detail .tab-details .detail-box {
  border: 0px solid #414a53;
  padding: 0px;
  background: #1a2128;
  border-radius: 6px;
}
.product-detail .tab-details .detail-box.source-tab {
  cursor: default !important;
}
@media screen and (max-width: 767px) {
  .product-detail .tab-details .detail-box {
    padding: 10px;
  }
}
.product-detail .tab-details .detail-box .serving-size ul li {
  padding-left: 5px !important;
  font-size: 18px !important;
  padding-right: 58px !important;
  margin-bottom: 7px !important;
}
.product-detail .tab-details .detail-box .serving-size ul li::after {
  display: none;
}
.product-detail .tab-details .detail-box .serving-size hr {
  border-top: 1px solid #3c3c3c !important;
}
.product-detail .tab-details .detail-box .video-custume-class-img {
  padding-bottom: 56.25%;
  width: 100%;
  overflow: hidden;
  background: #bcbcbc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.product-detail .tab-details .detail-box .video-custume-class-img img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
}
.product-detail .tab-details .detail-box .source-image {
  width: 150px;
  height: 150px;
  overflow: hidden;
  min-width: 150px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}
.product-detail .tab-details .detail-box .source-image img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
.product-detail .tab-details .detail-box .farmer-video {
  width: 450px;
  background: #252f39;
  border-radius: 15px;
  overflow: hidden;
}
.product-detail .tab-details .detail-box .farmer-video video {
  width: 100%;
}
@media screen and (max-width: 991px) {
  .product-detail .tab-details .detail-box .farmer-video {
    width: 100%;
    width: 100%;
    margin: 0 auto 30px;
  }
}
.product-detail .tab-details .detail-box .farmer-video img {
  width: 100%;
}
.product-detail .tab-details .detail-box .farmer-details {
  width: 100%;
  padding-left: 25px;
  cursor: default;
}
@media screen and (max-width: 991px) {
  .product-detail .tab-details .detail-box .farmer-details {
    width: 100%;
    padding-left: 0;
  }
}
.product-detail .tab-details .detail-box .farmer-details h5 {
  font-size: 20px;
  font-weight: 500;
  color: #c0f0f7;
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details .detail-box .farmer-details h5 {
    font-size: 16px;
  }
}
.product-detail .tab-details .detail-box .farmer-details p {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 0px;
}
.product-detail .tab-details .detail-box .farmer-details p strong {
  font-weight: 600;
}
.product-detail .tab-details .detail-box .farmer-details p.location span.icon-place {
  font-size: 17px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.product-detail .tab-details .detail-box .farmer-details p.location label {
  margin: 0;
}
@media screen and (max-width: 1400px) {
  .product-detail .tab-details .detail-box .farmer-details p {
    font-size: 14px;
  }
}
.product-detail .tab-details .recipe-img-outer {
  width: 80px;
  height: 80px;
}
.product-detail .tab-details .recipe {
  padding: 0;
  background: transparent;
  width: 100%;
  cursor: default;
}
.product-detail .tab-details .recipe.undefined-cursor {
  cursor: pointer !important;
}
.product-detail .tab-details .recipe h3 {
  font-size: 20px;
  color: #c0f0f7;
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details .recipe h3 {
    font-size: 14px;
    position: absolute;
    top: -80px;
    left: 90px;
  }
}
.product-detail .tab-details .recipe h4 {
  font-size: 20px;
  color: #c0f0f7;
}
.product-detail .tab-details .recipe h5 {
  font-size: 16px;
  color: #fff;
}
.product-detail .tab-details .recipe .recipe-img-outer {
  width: 80px;
  margin-right: 10px;
}
.product-detail .tab-details .recipe .recipe-img {
  padding-top: 100%;
  width: 100%;
  overflow: hidden;
  background: #bcbcbc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  min-width: 80px;
  cursor: pointer;
}
.product-detail .tab-details .recipe .recipe-img img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
}
.product-detail .tab-details .recipe .recipe-img .discount-label {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  background: #8ffe26;
  color: #000;
  font-size: 12px !important;
  padding: 3px 5px 1px 6px !important;
  font-weight: bold;
  border-radius: 0 0 0px 10px;
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details .recipe .recipe-detail {
    width: 100%;
    padding: 13px 0 0;
  }
}
.product-detail .tab-details .recipe .recipe-detail h3 {
  color: #ffffff;
  margin: 0 0 10px;
  font-weight: bold;
  font-size: 16px;
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details .recipe .recipe-detail h3 {
    margin: 0 0 10px;
    font-size: 18px;
  }
}
.product-detail .tab-details .recipe .recipe-detail .nutrains ul {
  display: flex;
  align-content: center;
}
.product-detail .tab-details .recipe .recipe-detail .nutrains ul li {
  padding: 0 0;
  margin-right: 35px;
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details .recipe .recipe-detail .nutrains ul li {
    margin-right: 10px;
  }
}
.product-detail .tab-details .recipe .recipe-detail .nutrains ul li.discount-list {
  margin-right: 80px;
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details .recipe .recipe-detail .nutrains ul li.discount-list {
    margin-right: 30px;
  }
}
.product-detail .tab-details .recipe .recipe-detail .nutrains ul li h4 {
  color: #ffffff;
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 14px;
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details .recipe .recipe-detail .nutrains ul li h4 {
    font-size: 14px;
  }
}
.product-detail .tab-details .recipe .recipe-detail .nutrains ul li p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 0;
}
@media screen and (max-width: 1400px) {
  .product-detail .tab-details .recipe .recipe-detail .nutrains ul li p {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details .recipe .recipe-detail .nutrains ul li p {
    font-size: 13px;
  }
}
.product-detail .tab-details .mat-tab-header-pagination {
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
}
.product-detail .tab-details .mat-tab-header-pagination .mat-tab-header-pagination-chevron {
  border-color: #fff;
}
.product-detail .tab-details .mat-expansion-panel-header {
  height: auto !important;
  padding: 0;
  align-items: self-start !important;
}
.product-detail .tab-details .mat-expansion-indicator::after,
.product-detail .tab-details .mat-expansion-panel-header-description {
  color: rgba(255, 255, 255, 0.54);
}
.product-detail .tab-details .mat-expansion-panel-header-title {
  margin-right: 0;
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details .mat-expansion-panel-header-title {
    display: inline-block;
  }
}
.product-detail .tab-details mat-expansion-panel.mat-expansion-panel {
  padding: 17px;
  border: 0px solid #414a53;
  border-radius: 6px;
  background: #1a2128;
  margin: 0 5px 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}
.product-detail .tab-details mat-expansion-panel.mat-expansion-panel:last-child {
  margin-bottom: 6px;
}
@media screen and (max-width: 767px) {
  .product-detail .tab-details mat-expansion-panel.mat-expansion-panel {
    padding: 10px;
    margin: 0 0px 20px;
  }
}
.product-detail .tab-details mat-expansion-panel.mat-expansion-panel .percent-off span {
  color: #ffffff !important;
  font-weight: bold;
}
.product-detail .tab-details mat-expansion-panel.mat-expansion-panel .mat-tab-label {
  font-size: 13px !important;
  padding: 4px 21px !important;
  margin: 8px 10px !important;
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details mat-expansion-panel.mat-expansion-panel .mat-tab-label {
    font-size: 12px !important;
    padding: 1px 15px !important;
  }
}
.product-detail .tab-details .mat-expansion-panel .tracibility-tab .mat-tab-label {
  margin: 0px 10px !important;
}
.product-detail .tab-details .mat-expansion-panel-content {
  font-family: "Poppins", sans-serif;
}
.product-detail .tab-details .mat-expansion-panel-body {
  padding: 10px 0 0 0 !important;
}
.product-detail .tab-details .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.product-detail .tab-details .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.product-detail .tab-details .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: transparent;
}
.product-detail .tab-details .mat-tab-group {
  font-family: "Poppins", sans-serif;
}
.product-detail .tab-details .mat-tab-group .discount-label {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  background: #8ffe26;
  color: #000;
  font-size: 12px !important;
  padding: 3px 5px 1px 6px !important;
  font-weight: bold;
  border-radius: 0 0 0px 10px;
}
.product-detail .tab-details .mat-tab-group .mat-tab-body-content {
  overflow: hidden;
}
.product-detail .tab-details .mat-tab-group .mat-tab-header {
  padding-bottom: 20px;
  overflow: hidden;
}
.product-detail .tab-details .mat-tab-group .mat-tab-header .mat-tab-label-container {
  overflow-x: auto;
  padding-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .product-detail .tab-details .mat-tab-group .mat-tab-header {
    padding-bottom: 15px;
    overflow: hidden;
  }
}
.product-detail .tab-details .mat-tab-group .mat-tab-labels {
  justify-content: center;
}
.product-detail .tab-details .mat-tab-group .mat-tab-labels .mat-tab-label {
  background: transparent;
  height: auto;
  min-width: auto;
  border: 2px solid #e9e9e9;
  margin: 0 10px;
  border-radius: 50px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  padding: 7px 25px;
}
.product-detail .tab-details .mat-tab-group .mat-tab-labels .mat-tab-label .mat-ripple-element {
  display: none !important;
}
@media screen and (max-width: 767px) {
  .product-detail .tab-details .mat-tab-group .mat-tab-labels .mat-tab-label {
    padding: 5px 30px;
    margin: 0px 3px;
    font-size: 14px;
  }
}
.product-detail .tab-details .mat-tab-group .mat-tab-labels .mat-tab-label:hover {
  background: #ffde59;
  color: #252f39;
  border-color: #ffde59;
  opacity: 0.65;
}
.product-detail .tab-details .mat-tab-group .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background: #ffa710;
  opacity: 1;
  color: #252f39;
  border-color: #ffa710;
  position: relative;
  overflow: hidden;
}
.product-detail .tab-details .mat-tab-group .mat-tab-labels .mat-tab-label.mat-tab-label-active:after {
  content: "";
  position: absolute;
  height: 25px;
  width: 2px;
  top: 38px;
  background: #ffde59;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.product-detail .tab-details .mat-tab-group .mat-tab-header,
.product-detail .tab-details .mat-tab-group .mat-tab-nav-bar {
  border: none;
}
.product-detail .tab-details .mat-tab-group.mat-primary .mat-ink-bar,
.product-detail .tab-details .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  display: none;
}
.product-detail .tab-details .food-contet h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #8ffe26;
}
.product-detail .tab-details .food-contet.ingredients-items {
  border: 1px solid #414a53;
  padding: 20px !important;
  background: #1a2128;
  border-radius: 0;
  margin: 0 0 20px;
}
.product-detail .tab-details .food-contet.ingredients-items:last-child {
  margin: 0 0 0px;
}
.product-detail .tab-details .food-contet.ingredients-items ul li {
  padding-left: 22px;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}
.product-detail .tab-details .food-contet.ingredients-items ul li:last-child {
  margin-bottom: 0px !important;
}
.product-detail .tab-details .food-contet ul li {
  position: relative;
  padding-left: 25px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
  color: #ffffff;
}
.product-detail .tab-details .food-contet ul li:after {
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  border-radius: 5px;
  position: absolute;
  left: 5px;
  top: 5px;
}
.product-detail .tab-details .food-contet ul li h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
}
.product-detail .tab-details .food-contet ul li p {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1400px) {
  .product-detail .tab-details .food-contet ul li p {
    font-size: 14px;
  }
}
.product-detail .tab-details .food-contet.nutrition-data ul {
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
}
@media screen and (max-width: 575px) {
  .product-detail .tab-details .food-contet.nutrition-data ul {
    padding: 0px 0px 10px 0px;
    max-width: 100%;
  }
}
.product-detail .tab-details .food-contet.nutrition-data ul li {
  display: flex;
  align-items: flex-start;
  padding: 0 60px 0 25px;
  margin-bottom: 5px;
  flex: 0 0 50%;
  justify-content: space-between;
  max-width: 50%;
}
.product-detail .tab-details .food-contet.nutrition-data ul li:first-child {
  max-width: 50.1%;
  flex: 50.1%;
}
@media screen and (max-width: 767px) {
  .product-detail .tab-details .food-contet.nutrition-data ul li {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.product-detail .tab-details .food-contet.nutrition-data ul li span {
  width: 50%;
}
.product-detail .tab-details .food-contet.nutrition-data ul li span:nth-last-child(1) {
  text-align: right;
}
.product-detail .tab-details .food-contet.nutrition-data ul li .yes {
  background: #1E4976;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  color: #1a2128;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  overflow: hidden;
}
.product-detail .tab-details .food-contet.source-traceblity {
  background: transparent !important;
}
.product-detail .tab-details .food-contet.source-traceblity .traceblity-list {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  margin-bottom: 15px;
  padding-bottom: 10px;
  word-break: break-all;
}
.product-detail .tab-details .food-contet.source-traceblity .traceblity-list .date {
  font-weight: 500;
  font-size: 14px;
  display: flex;
}
.product-detail .tab-details .food-contet.source-traceblity .traceblity-list .date i {
  font-size: 17px;
  color: #8ffe26;
}
.product-detail .tab-details .food-contet.source-traceblity .traceblity-list .text-secondary {
  font-weight: 500;
  padding-left: 18px;
}
.product-detail .tab-details .food-contet.source-traceblity .traceblity-list:last-child {
  margin-bottom: 0px !important;
}
.product-detail .tab-details .brand {
  padding: 20px;
}
@media screen and (max-width: 767px) {
  .product-detail .tab-details .brand {
    padding: 0;
  }
}
.product-detail .tab-details .brand .brand-logo {
  min-width: 150px;
  border-radius: 10px;
  width: 150px;
  overflow: hidden;
}
.product-detail .tab-details .brand .brand-logo img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
.product-detail .tab-details .brand .brand-details {
  padding: 0 0 0 30px;
}
@media screen and (max-width: 767px) {
  .product-detail .tab-details .brand .brand-details {
    padding: 15px 0 0;
    width: 100%;
  }
}
.product-detail .tab-details .brand .brand-details strong {
  font-weight: 600;
  color: #252f39;
}
.product-detail .tab-details .brand .brand-details p {
  font-size: 16px;
  color: rgba(37, 47, 57, 0.8);
  font-weight: 400;
  margin-bottom: 10px;
}
@media screen and (max-width: 1400px) {
  .product-detail .tab-details .brand .brand-details p {
    font-size: 14px;
  }
}
.product-detail .tab-details .brand .brand-details .add {
  font-size: 16px;
}
.product-detail .tab-details .brand .brand-details .add strong {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.62);
  font-size: 14px;
}
.product-detail .tab-details .brand .brand-details .add p {
  display: flex;
  align-items: flex-start;
  color: #ffffff;
  font-size: 16px;
  word-break: break-word;
}
.product-detail .tab-details .brand .brand-details .add p span {
  margin-right: 5px;
  font-size: 20px;
  color: #ffffff;
}
.product-detail .tab-details .brand .brand-details .add p a {
  color: #ffffff;
}
.product-detail .tab-details .tracibility-date-list {
  padding-left: 30px;
}
.product-detail .tab-details .tracibility-date-list li {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  padding: 20px 10px;
}
.product-detail .tab-details .tracibility-date-list li:last-child::after {
  display: none;
}
.product-detail .tab-details .tracibility-date-list li::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  left: -11px;
  top: 35px;
  background: #38b6ff;
}
.product-detail .tab-details .tracibility-date-list li::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: -18px;
  top: 21px;
  background: #38b6ff;
}
.product-detail .tab-details .video-content {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}
.product-detail .tab-details .nutrition-box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 20px;
  height: 100%;
}
.product-detail .tab-details .nutrition-box .nutrition-data ul li {
  color: #9a9ea2;
  font-size: 16px;
  font-weight: 800;
}
.product-detail .tab-details .nutrition-box .nutrition-data ul li span:last-child {
  font-weight: 500;
}
.product-detail .tab-details .nutrition-box .nutrition-data hr {
  margin: 0 0 15px;
  border-color: #252f39;
  border-width: 5px;
}
.product-detail .tab-details .nutrition-box .nutrition-data .serving-size {
  padding: 16px 10px 16px;
  background: #000000;
  border-radius: 10px 10px 0 0;
}
.product-detail .tab-details .nutrition-box .nutrition-data .serving-size h2 {
  font-weight: 800;
  font-size: 20px;
  color: #ffffff;
}
.product-detail .tab-details .nutrition-box .nutrition-data .dieatry-icon-list {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 12px 0 0;
}
.product-detail .tab-details .nutrition-box .nutrition-data .dieatry-icon-list li {
  width: 50px;
  height: 50px;
  max-width: 50px !important;
  flex: auto !important;
  padding: 0 !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 20px;
}
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .product-detail .tab-details .nutrition-box .nutrition-data .dieatry-icon-list li {
    width: 38px;
    height: 38px;
    max-width: 38px !important;
  }
}
@media screen and (max-width: 1300px) {
  .product-detail .tab-details .nutrition-box .nutrition-data .dieatry-icon-list li {
    margin: 10px 5px;
  }
}
@media screen and (max-width: 991px) {
  .product-detail .tab-details .nutrition-box .nutrition-data .dieatry-icon-list li {
    margin: 10px 2px;
  }
}
.product-detail .tab-details .nutrition-box .nutrition-data .dieatry-icon-list li:nth-child(2) {
  border-color: #f18c24;
}
.product-detail .tab-details .nutrition-box .nutrition-data .dieatry-icon-list li:nth-child(3) {
  border-color: #3dbdd6;
}
.product-detail .tab-details .nutrition-box .nutrition-data .dieatry-icon-list li::after {
  display: none;
}
.product-detail .tab-details .nutrition-box .nutrition-data .dieatry-icon-list li img {
  width: 100%;
}
.product-detail .title h3 {
  font-size: 32px;
  margin-bottom: 0;
}
.product-detail .title p {
  font-size: 16px;
  color: #000000;
}
.product-detail .dietary-col {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
@media screen and (max-width: 767px) {
  .product-detail .dietary-col {
    flex: 100%;
    max-width: 100%;
  }
}

.about-page .aboutus-image {
  padding: 125px 0 0;
  height: 269px;
  background-image: url("/assets/images/meetourteam-background.png");
}
.about-page .aboutus-image h3 {
  font-weight: 500;
  font-size: 50px;
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .about-page .aboutus-image h3 {
    font-size: 32px;
  }
}
@media screen and (max-width: 991px) {
  .about-page .aboutus-image {
    height: 300px;
  }
}
@media screen and (max-width: 575px) {
  .about-page .aboutus-image {
    padding: 45px 0 0;
    height: 225px;
  }
}
.about-page .player-sect {
  padding: 0 0 50px;
  margin-top: -280px;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .about-page .player-sect {
    margin-top: -130px;
  }
}
@media screen and (max-width: 575px) {
  .about-page .player-sect {
    padding: 0 0 20px;
    margin-top: -115px;
  }
}
.about-page .player-sect .about-video {
  margin-bottom: 40px;
  overflow: hidden;
  border-radius: 10px;
}
@media screen and (max-width: 575px) {
  .about-page .player-sect .about-video {
    margin-bottom: 20px;
  }
}
.about-page .client-about-sect {
  padding: 60px 0;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .about-page .client-about-sect {
    padding: 20px 0 !important;
    text-align: center;
  }
}
.about-page .client-about-sect .row {
  align-items: center;
}
.about-page .client-about-sect h6 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
  color: #00f900;
}
@media screen and (max-width: 991px) {
  .about-page .client-about-sect h6 {
    font-size: 22px;
  }
}
.about-page .client-about-sect:nth-child(3) img, .about-page .client-about-sect:nth-child(5) img {
  border-right: 8px solid #2da34c;
}
@media screen and (max-width: 991px) {
  .about-page .client-about-sect:nth-child(3) img, .about-page .client-about-sect:nth-child(5) img {
    border-bottom: 8px solid #2da34c;
    border-right: 0;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 991px) {
  .about-page .client-about-sect:nth-child(4) .row {
    flex-direction: column-reverse;
  }
}
.about-page .client-about-sect:nth-child(4) img {
  border-left: 8px solid #2da34c;
}
@media screen and (max-width: 991px) {
  .about-page .client-about-sect:nth-child(4) img {
    border: 0;
    border-bottom: 8px solid #2da34c;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
.about-page .client-about-sect:nth-child(5) {
  padding-bottom: 125px;
}
.about-page p {
  font-size: 16px;
}
@media screen and (max-width: 575px) {
  .about-page p {
    font-size: 14px;
  }
}
.about-page p span {
  color: green;
}

.feedback {
  padding: 20px 0 20px 0;
  background: #8ffe26;
}
.feedback a.ap-btn {
  padding: 0 20px 0 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
@media screen and (max-width: 991px) {
  .feedback a.ap-btn {
    padding: 0 10px 0 0;
  }
}
@media screen and (max-width: 768px) {
  .feedback a.ap-btn {
    padding: 0 10px 20px 0;
  }
}
.feedback a.ap-btn:last-child {
  padding-right: 0;
}
.feedback a.ap-btn img {
  max-width: 156px;
  width: 100%;
  height: 100%;
}
.feedback .title {
  max-width: 270px;
  margin: 0 0 0;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .feedback .title {
    max-width: 100%;
  }
}
.feedback .title h3 {
  margin-bottom: 0;
  color: #ffffff;
}
.feedback .title h2 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 30px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 768px) {
  .feedback .title h2 {
    margin-bottom: 5px;
  }
}
.feedback form {
  max-width: 450px;
  margin: 0 auto;
}
.feedback form label {
  margin-bottom: 10px;
}
.feedback form textarea {
  background: #ffffff;
  height: 90px;
  border: none;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  resize: none;
  border: 1px solid #a5a5a5;
}
.feedback form textarea:focus {
  border: 1px solid #a5a5a5 !important;
  background: #ffffff;
  border: none;
  color: #252f39;
  font-size: 14px;
  font-weight: 400;
}
.feedback form .theme-btn:hover {
  background: #ffffff;
  color: #252f39;
}
.feedback .theme-btn {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.help-support {
  padding: 50px 0 0;
}
@media screen and (max-width: 991px) {
  .help-support {
    padding: 70px 0px 0px;
  }
}
@media screen and (max-width: 767px) {
  .help-support {
    padding: 30px 0px;
  }
}
.help-support .title {
  max-width: 700px;
  margin: 0 auto 0;
}
@media screen and (max-width: 991px) {
  .help-support .title {
    margin: 0 auto 15px;
  }
}
.help-support .title h3 {
  margin-bottom: 0;
}
.help-support .helpsection {
  padding: 0 0 10px;
}
@media screen and (max-width: 767px) {
  .help-support .helpsection {
    padding: 0;
  }
}
.help-support .helpsection .help-content {
  padding: 20px 20px;
}
@media screen and (max-width: 991px) {
  .help-support .helpsection .help-content {
    text-align: center;
    padding: 20px 0 0;
  }
}
.help-support .helpsection .help-content h2 {
  font-weight: 600;
  font-size: 30px;
  padding: 10px 70px 30px 0;
  margin: 0;
}
@media screen and (max-width: 991px) {
  .help-support .helpsection .help-content h2 {
    padding: 0 0 5px;
    font-size: 22px;
    line-height: 25px;
  }
}
.help-support .helpsection .help-content p {
  font-size: 16px;
}
@media screen and (max-width: 1400px) {
  .help-support .helpsection .help-content p {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .help-support .helpsection .help-content p {
    margin-bottom: 0;
  }
}
.help-support .helpsection .help-img {
  text-align: center;
}
.help-support .helpsection .help-img img {
  width: 100%;
  max-width: 250px;
}
@media screen and (max-width: 767px) {
  .help-support .helpsection .help-img img {
    max-width: 300px;
  }
}
@media screen and (max-width: 575px) {
  .help-support .helpsection .help-img img {
    max-width: 200px;
  }
}
.help-support .conatct-info ul {
  display: flex;
  align-items: center;
}
.help-support .conatct-info ul li {
  margin-right: 15px;
}
.help-support .conatct-info ul li a {
  border: 1px solid #e9e9e9;
  padding: 15px 0;
  background: #f8f8f8;
  border-radius: 2px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #252f39;
  text-decoration: none;
  font-size: 20px;
}
.help-support .conatct-info p {
  display: flex;
  font-size: 16px;
}
.help-support .conatct-info p strong {
  margin-right: 15px;
  font-weight: 500;
  min-width: 80px;
}
@media screen and (max-width: 1400px) {
  .help-support .conatct-info p {
    font-size: 14px;
  }
}
.help-support h4 {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 20px;
}
.help-support .support {
  max-width: 680px;
  margin: 0 auto;
}
.help-support .support .contact-form .form-group {
  margin-bottom: 13px;
}
.help-support .support .contact-form textarea {
  height: 80px;
  resize: none;
}
.help-support .support .form-control {
  border-color: #d1e4d1;
}
.help-support .support textarea {
  height: 120px;
  border-color: #d1e4d1;
}

.about-us ul {
  margin-left: 40px;
}
.about-us ul li {
  list-style-type: inherit;
}
.about-us .title {
  max-width: 700px;
  margin: 0 auto;
}
.about-us .title h3 {
  margin-bottom: 10px;
}
@media screen and (max-width: 991px) {
  .about-us .title h3 {
    margin-bottom: 15px;
    font-size: 25px;
    line-height: 28px;
  }
}
@media screen and (max-width: 767px) {
  .about-us .title h3 {
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 25px;
  }
}
.about-us .title p {
  font-size: 16px;
  color: #252f39;
  margin-bottom: 15px;
}
@media screen and (max-width: 1400px) {
  .about-us .title p {
    font-size: 14px;
  }
}
.about-us .details p {
  color: #252f39;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
}
.about-us .details li {
  list-style: initial;
  list-style-position: inside;
}
.about-us .details-img {
  padding: 0;
  border-radius: 25px;
  background: #252f39;
}
.about-us .details-img .video video {
  width: 100%;
  height: 280px;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .about-us .details-img .video video {
    height: 200px;
  }
}

.content-pages {
  padding: 60px 0 35px;
}
.content-pages .add-content ul {
  padding-left: 30px;
}
.content-pages .add-content ul li {
  padding-left: 0;
  margin: 0 0 15px;
  list-style: upper-alpha;
}
.content-pages .add-content h5 {
  font-size: 15px;
  margin: 0 0 10px;
}
.content-pages .add-content ol {
  padding: 10px 0 0 30px;
}
.content-pages .add-content ol li {
  list-style: decimal-leading-zero !important;
}
.content-pages .add-content a {
  color: #5dbf01;
}
@media screen and (max-width: 767px) {
  .content-pages {
    padding: 30px 0;
  }
}
.content-pages .title {
  max-width: 700px;
  margin: 0 auto 40px;
}
@media screen and (max-width: 991px) {
  .content-pages .title {
    margin-bottom: 15px;
  }
}
.content-pages .add-content p {
  font-size: 14px;
  line-height: 25px;
}
@media screen and (max-width: 1400px) {
  .content-pages .add-content p {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .content-pages .add-content p {
    font-size: 14px;
  }
}

.faq-row {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  padding: 15px;
  margin-bottom: 25px;
}

.faq-row h3 {
  font-size: 25px;
}

.mat-tab-label,
.mat-tab-link {
  font-family: "Poppins", sans-serif;
}

section.profile-section {
  padding: 35px 0 0;
  background: #f8f8f8;
}
section.profile-section .mat-tab-group.mat-primary .mat-ink-bar,
section.profile-section .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  display: none;
}
section.profile-section .mat-tab-label-active {
  border: 1px solid #e9e9e9;
  border-bottom: none;
  background: #ffffff !important;
  position: relative;
  z-index: 9;
  border-radius: 8px 8px 0 0;
  height: 40px;
  opacity: 1;
}
section.profile-section .mat-tab-label {
  height: 40px;
  font-weight: 400;
  font-size: 15px;
  min-width: inherit;
}
section.profile-section .mat-tab-header,
section.profile-section .mat-tab-nav-bar {
  border-bottom: none;
  background: #f8f8f8;
}
section.profile-section .mat-tab-body-wrapper {
  border-top: 1px solid #e9e9e9;
  margin: -1px 0 0;
  background: #ffffff;
}
section.profile-section .profile-box {
  max-width: 460px;
  margin: 0 auto;
}
section.profile-section .profile-box .profile {
  min-width: 100px;
  width: 100px;
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
  padding: 6px;
  border: 1px solid #e9e9e9;
}
section.profile-section .profile-box .profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  overflow: hidden;
}
section.profile-section .profile-box .profile-detail {
  padding-left: 20px;
}
section.profile-section .profile-box .profile-detail h2 {
  font-size: 38px;
  margin-bottom: 10px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  section.profile-section .profile-box .profile-detail h2 {
    font-size: 20px;
  }
}
section.profile-section .profile-box .profile-detail p {
  font-size: 14px;
  margin-bottom: 0;
}
@media screen and (max-width: 1400px) {
  section.profile-section .profile-box .profile-detail p {
    font-size: 13px;
    margin: 0;
  }
}
section.profile-section .profile-box .profile-detail label {
  font-size: 12px;
  margin: 0;
}
section.profile-section .profile-tab {
  background: #ffffff;
}
section.profile-section .profile-tab .edit-box {
  padding: 30px 15px;
  max-width: 650px;
  margin: 0 auto;
  min-height: 235px;
}
@media screen and (min-width: 1400px) {
  section.profile-section .profile-tab .edit-box {
    padding: 70px 17px;
  }
}
@media screen and (max-width: 575px) {
  section.profile-section .profile-tab .edit-box {
    padding: 20px 15px 30px;
  }
}
section.profile-section .profile-tab .edit-box.change-pass {
  max-width: 450px;
}
section.profile-section .profile-tab .edit-box.change-pass .profile-detail {
  padding: 0;
}
section.profile-section .profile-tab .edit-box .profile {
  min-width: 160px;
  width: 160px;
  height: 160px;
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  border: 1px solid #dedede;
}
@media screen and (max-width: 575px) {
  section.profile-section .profile-tab .edit-box .profile {
    margin: 0 auto 20px;
  }
}
section.profile-section .profile-tab .edit-box .profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
section.profile-section .profile-tab .edit-box .profile label {
  position: absolute;
  background: #8ffe26;
  color: #ffffff;
  bottom: 0;
  width: 100%;
  margin: 0;
  height: 25px;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  top: 5px;
  right: 5px;
}
section.profile-section .profile-tab .edit-box .profile label:hover {
  background: rgba(37, 47, 57, 0.62);
}
section.profile-section .profile-tab .edit-box .profile label input {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
section.profile-section .profile-tab .edit-box .profile-detail {
  padding-left: 20px;
}
@media screen and (max-width: 575px) {
  section.profile-section .profile-tab .edit-box .profile-detail {
    padding: 0;
    max-width: 400px;
    margin: 0 auto;
  }
}
section.profile-section .profile-tab .edit-box .profile-detail .btn.theme-btn {
  padding: 11px 40px;
}
section.profile-section .profile-tab .edit-box .profile-detail label {
  font-size: 12px;
  margin: 0;
}
@media screen and (min-width: 1400px) {
  section.profile-section .profile-tab .favorites-list {
    padding: 70px 17px;
  }
}
section.profile-section .mat-tab-body-content {
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

.location {
  position: relative;
  display: inline-block;
  padding-right: 30px;
}
.location button.location-btn {
  background: #38b6ff;
  color: #252f39;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.location button.location-btn:focus {
  outline: none;
  box-shadow: none;
}
.location button.location-btn.source-location {
  top: 0px;
}

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 9;
}

/******/
.simmer-animation {
  animation: 1s forwards infinite linear;
  -webkit-animation: 1s forwards infinite linear;
  animation-name: placeHolderShimmer;
  -webkit-animation-name: placeHolderShimmer;
  background: #f6f7f9;
  background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 1;
  left: 0;
  top: 0;
}

div.a {
  height: 40px;
  left: 40px;
  right: auto;
  top: 0;
  width: 8px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.slide-banner {
  position: relative;
  padding-bottom: 43.75%;
  clip-path: polygon(0 0, 100% 0%, 100% 94%, 0% 100%);
  background-color: #000000;
}
@media screen and (max-width: 991px) {
  .slide-banner {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .slide-banner {
    margin-top: 8px;
  }
}
.slide-banner .simmer-animation {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
}
.slide-banner .simmer-animation .home-search {
  width: 500px;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .slide-banner .simmer-animation .home-search {
    width: 100%;
    padding: 0 20px;
  }
}
.slide-banner .simmer-animation .s-search {
  width: 500px;
  height: 60px;
  background: #fff;
  margin: 0 auto;
  border-radius: 80px;
}
@media screen and (max-width: 575px) {
  .slide-banner .simmer-animation .s-search {
    width: 100%;
  }
}
.slide-banner .simmer-animation .text {
  width: 90%;
  height: 20px;
  margin: 0 auto 25px;
  background: #ffffff;
}
.slide-banner .simmer-animation .text.w-80 {
  width: 80%;
}
.slide-banner .simmer-animation .text.head {
  height: 20px;
  margin-bottom: 20px;
}

.video,
.story-img {
  height: 100%;
  position: relative;
}
.video .simmer-animation,
.story-img .simmer-animation {
  height: 325px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
}
.video .simmer-animation span,
.story-img .simmer-animation span {
  font-size: 35px;
  background: #e9e9e9;
  color: #ffffff;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  line-height: 70px;
  transform: rotate(30deg);
  text-align: center;
  position: absolute;
  top: calc(50% - 30px);
}

.pro-box,
.step-box {
  position: relative;
}
.pro-box .simmer-animation,
.step-box .simmer-animation {
  height: 400px;
  width: 100%;
  margin: 0 auto;
  opacity: 1;
  position: relative;
  padding: 15px 0;
}
.pro-box .simmer-animation .img-h,
.step-box .simmer-animation .img-h {
  height: 220px;
  width: 90%;
  border: 2px solid #fff;
  margin: 0 auto;
}
.pro-box .simmer-animation .sh-content,
.step-box .simmer-animation .sh-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 6;
}
.pro-box .simmer-animation .sh-content .text,
.step-box .simmer-animation .sh-content .text {
  width: 90%;
  height: 10px;
  margin: 0 auto 18px;
  background: #ffffff;
}
.pro-box .simmer-animation .sh-content .text.w-80,
.step-box .simmer-animation .sh-content .text.w-80 {
  width: 80%;
}
.pro-box .simmer-animation .sh-content .text.head,
.step-box .simmer-animation .sh-content .text.head {
  height: 20px;
  margin-bottom: 20px;
}

.story-code .simmer-animation {
  height: 180px;
  padding: 30px;
  border-radius: 10px;
  text-align: left;
}
.story-code .text {
  width: 45%;
  height: 18px;
  margin: 0 0 15px;
  background: #ffffff;
}
.story-code .text.w-80 {
  width: 75%;
  height: 32px;
  margin-bottom: 16px;
}
.story-code .text.head {
  height: 20px;
  margin-bottom: 20px;
}

.about-us .details-img .simmer-animation {
  height: 325px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
}
.about-us .details-img .simmer-animation span {
  font-size: 35px;
  background: #e9e9e9;
  color: #ffffff;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  line-height: 70px;
  transform: rotate(30deg);
  text-align: center;
}

.player-poster[data-poster] .play-wrapper[data-poster] svg {
  width: 40px;
}

/**Login**/
.login-page {
  height: 100vh;
  position: relative;
  margin: -101px 0 0 0;
  z-index: 5;
  background: #ffffff;
}
@media screen and (max-width: 991px) {
  .login-page {
    margin: -73px 0 0 0;
  }
}
.login-page .login-logo {
  padding: 20px 0 0px 60px;
}
@media screen and (max-width: 1024px) {
  .login-page .login-logo {
    padding: 20px 0 0 20px;
  }
}
@media screen and (max-width: 991px) {
  .login-page .login-logo {
    padding: 10px;
    margin: 0 auto 0;
  }
}
@media screen and (max-width: 767px) {
  .login-page .login-logo {
    text-align: center;
    padding: 0;
  }
}
.login-page .login-logo .mob-logo {
  display: none;
}
@media screen and (max-width: 767px) {
  .login-page .login-logo .mob-logo {
    display: inherit;
  }
}
@media screen and (max-width: 767px) {
  .login-page .login-logo img {
    display: none;
  }
}
.login-page .login-logo a.navbar-brand {
  margin: 0;
  padding: 0px;
  width: 80px;
}
@media screen and (max-width: 991px) {
  .login-page .login-logo a.navbar-brand {
    width: 100%;
  }
}
.login-page .login-logo a.navbar-brand img {
  max-width: 180px;
  max-height: 150px;
}
.login-page.signup-page .login-content {
  width: calc(100% - 430px);
}
@media screen and (max-width: 991px) {
  .login-page.signup-page .login-content {
    width: calc(100% - 340px);
  }
}
@media screen and (max-width: 767px) {
  .login-page.signup-page .login-content {
    width: 100%;
  }
}
.login-page.signup-page .user-form {
  width: 430px;
}
@media screen and (max-width: 991px) {
  .login-page.signup-page .user-form {
    position: relative;
    width: 340px;
  }
}
@media screen and (max-width: 767px) {
  .login-page.signup-page .user-form {
    width: 100%;
  }
}
.login-page.signup-page .user-form .loginnow {
  height: calc(100vh - 165px);
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 991px) {
  .login-page.signup-page .user-form .loginnow {
    height: calc(100vh - 136px);
  }
}
.login-page.signup-page .user-form .loginnow .form-box .btn {
  width: 150px;
}
.login-page.signup-page .user-form .loginnow .form-box p {
  font-size: 12px;
  color: #705446;
}
.login-page.signup-page .user-form .loginnow .form-box .signup-text {
  margin-top: 40px;
  padding: 20px 0 0 0;
}
.login-page.signup-page .user-form .loginnow .form-box .my_radio label {
  font-size: 12px !important;
  line-height: 22px;
  padding-left: 25px;
}
.login-page.signup-page .user-form .loginnow .form-box .my_radio label a {
  color: #252f39;
  text-decoration: underline;
}
.login-page.signup-page .user-form .loginnow .form-box .my_radio .check {
  height: 18px;
  width: 18px;
}
.login-page.signup-page .user-form .loginnow .form-box .my_radio .check:before {
  width: 6px;
  height: 12px;
  left: 4px;
}
.login-page .login-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: calc(100% - 430px);
  background: #252f39 url("~assets/images/login-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
}
@media screen and (max-width: 991px) {
  .login-page .login-content {
    width: calc(100% - 350px);
  }
}
@media screen and (max-width: 767px) {
  .login-page .login-content {
    width: 100%;
    height: auto;
    background: #252f39;
    position: sticky;
    top: 0;
    z-index: 9;
  }
}
.login-page .login-content .co-box {
  max-width: 540px;
  margin: 0 auto;
  height: calc(100% - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .login-page .login-content .co-box {
    display: none;
  }
}
.login-page .login-content .co-box p {
  font-size: 24px;
  color: #ffffff;
  line-height: 35px;
  margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .login-page .login-content .co-box p {
    padding: 0 15px;
  }
}
@media screen and (max-width: 575px) {
  .login-page .login-content .co-box p {
    font-size: 20px;
    line-height: 30px;
  }
}
.login-page .login-content .btn {
  border-radius: 8px;
  font-size: 16px;
  padding: 6px 40px;
  background: #fd9902;
  color: #ffffff;
  font-weight: 500;
  border-radius: 40px;
}
.login-page .user-form {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: 430px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .login-page .user-form {
    width: 350px;
  }
}
@media screen and (max-width: 767px) {
  .login-page .user-form {
    position: relative;
    height: auto;
    width: 100%;
    padding: 0;
  }
}
.login-page .user-form .loginnow {
  padding: 50px;
  width: 100%;
  background: #ffffff;
  overflow-y: auto;
  max-height: 100%;
}
@media screen and (max-width: 1366px) {
  .login-page .user-form .loginnow {
    padding: 15px 45px;
  }
}
@media screen and (max-width: 991px) {
  .login-page .user-form .loginnow {
    padding: 15px 25px;
    max-height: 100%;
  }
}
@media screen and (max-width: 767px) {
  .login-page .user-form .loginnow {
    padding: 30px 25px;
    max-width: 430px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 575px) {
  .login-page .user-form .loginnow {
    overflow: hidden;
    padding: 25px 25px 15px;
  }
}
@media screen and (max-width: 415px) {
  .login-page .user-form .loginnow {
    padding: 20px 15px 20px;
  }
}
.login-page .user-form .loginnow .form-box {
  width: 100%;
}
.login-page .user-form .loginnow .form-box .forgot-img {
  padding: 0 0 40px;
  max-width: 100%;
}
.login-page .user-form .loginnow .form-box .head {
  margin-bottom: 45px;
}
@media screen and (max-width: 991px) {
  .login-page .user-form .loginnow .form-box .head {
    margin-bottom: 30px;
  }
}
.login-page .user-form .loginnow .form-box .head h3 {
  font-size: 24px;
  font-weight: 500;
  color: #252f39;
  margin: 0;
}
@media screen and (max-width: 1600px) {
  .login-page .user-form .loginnow .form-box .head h3 {
    font-size: 25px;
  }
}
@media screen and (max-width: 991px) {
  .login-page .user-form .loginnow .form-box .head h3 {
    font-size: 22px;
  }
}
@media screen and (max-width: 575px) {
  .login-page .user-form .loginnow .form-box .head h3 {
    font-size: 18px;
  }
}
.login-page .user-form .loginnow .form-box .head a {
  font-size: 12px;
  color: #1E4976;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.login-page .user-form .loginnow .form-box .head a:hover {
  color: #0DC0DD;
}
.login-page .user-form .loginnow .form-box .head span {
  font-weight: 600;
  margin: 0 0 0 5px;
  transform: rotate(180deg);
}
.login-page .user-form .loginnow .form-box .form-group {
  position: relative;
  margin-bottom: 20px;
}
.login-page .user-form .loginnow .form-box .form-group label {
  font-size: 14px;
  margin: 0;
  color: #252f39;
  letter-spacing: 0.4px;
}
.login-page .user-form .loginnow .form-box .form-group .otp-inputs {
  margin: 0 -15px;
}
.login-page .user-form .loginnow .form-box .form-group .otp-inputs .form-group {
  padding: 0 15px;
}
.login-page .user-form .loginnow .form-box .form-group .otp-inputs .form-group input {
  text-align: center;
}
.login-page .user-form .loginnow .form-box p {
  color: #ff2832;
  margin-bottom: 2px;
}
.login-page .user-form .loginnow .form-box .links {
  text-align: right;
}
.login-page .user-form .loginnow .form-box .links a {
  color: #252f39;
  font-size: 14px;
  font-weight: 400;
}
@media screen and (max-width: 415px) {
  .login-page .user-form .loginnow .form-box .links a {
    font-size: 12px;
  }
}
.login-page .user-form .loginnow .form-box .signup-text {
  text-align: center;
  padding: 0;
}
@media screen and (max-width: 991px) {
  .login-page .user-form .loginnow .form-box .signup-text {
    padding: 10px 0 0 0;
  }
}
.login-page .user-form .loginnow .form-box .signup-text p {
  color: 14px;
  margin-bottom: 0;
  font-size: 14px;
  color: #252f39;
  padding: 15px 0 0 0;
}
.login-page .user-form .loginnow .form-box .signup-text p a {
  color: #1E4976;
}
.login-page .user-form .loginnow .form-box .signup-text p a:hover {
  color: #0DC0DD;
}
.login-page .user-form .loginnow .form-box .btn {
  border-radius: 50px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 15px;
  background: #0DC0DD;
  color: white;
}
@media screen and (max-width: 767px) {
  .login-page .user-form .loginnow .form-box .btn {
    padding: 8px 10px;
    max-width: 185px;
  }
}
.login-page .user-form .loginnow .form-box .btn:focus {
  outline: none;
  box-shadow: none;
}
.login-page .user-form .loginnow .form-box .btn:hover {
  background-color: #1E4976;
}
.login-page .user-form .loginnow .form-box .social-links {
  padding: 20px 0;
}
@media screen and (max-width: 991px) {
  .login-page .user-form .loginnow .form-box .social-links {
    padding: 0 0 10px;
  }
}
.login-page .user-form .loginnow .form-box .social-links h5 {
  text-align: center;
  position: relative;
  font-weight: 500;
  color: #252f39;
  font-size: 16px;
  margin: 10px 0 23px;
  text-transform: uppercase;
}
@media screen and (max-width: 991px) {
  .login-page .user-form .loginnow .form-box .social-links h5 {
    margin: 25px 0 25px;
  }
}
.login-page .user-form .loginnow .form-box .social-links h5:after {
  content: "";
  height: 1px;
  width: calc(50% - 18px);
  position: absolute;
  top: 50%;
  margin: -0.5 0 0 0;
  left: 0;
  background: #eeeeee;
}
.login-page .user-form .loginnow .form-box .social-links h5:before {
  content: "";
  height: 1px;
  width: calc(50% - 18px);
  position: absolute;
  top: 50%;
  margin: -0.5 0 0 0;
  right: 0;
  background: #eeeeee;
}
.login-page .user-form .loginnow .form-box .social-links .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  margin: 0 4px;
  padding: 8px 15px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
}
.login-page .user-form .loginnow .form-box .social-links .btn span {
  font-size: 16px;
  margin-right: 8px;
  display: inline-block;
}

#buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}
.form-group .input-error-border {
  border: solid 1px red !important;
}
.form-group .reset-link {
  padding: 2px 0 0;
}
.form-group .reset-link a {
  color: #fc9901;
  font-size: 13px;
  padding-left: 20px;
  position: relative;
}
.form-group .reset-link a [class^=icon-],
.form-group .reset-link a [class*=" icon-"] {
  position: absolute;
  left: 3px;
  top: 2px;
}
.form-group.ic-pass {
  position: relative;
}
.form-group.ic-pass span {
  position: absolute;
  top: 30px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}
.form-group.ic-pass input {
  padding-right: 35px;
}
.form-group label {
  font-size: 14px;
  margin: 0;
  color: #435664;
  letter-spacing: 0.3px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .form-group label {
    font-size: 14px;
  }
}
.form-group label.placeholder {
  font-size: 14px;
}
.form-group textarea {
  width: 100%;
  height: 70px;
  background: #ffffff;
  color: #252f39;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  padding: 15px;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
}
.form-group textarea:focus {
  outline: none;
  box-shadow: none;
  color: #252f39;
  border-color: #252f39;
}
.form-group input {
  width: 100%;
  padding: 6px 10px;
  font-size: 14px;
  height: 40px;
  background: #ffffff;
  color: #252f39;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
}
.form-group input:focus {
  outline: none;
  box-shadow: none;
  color: #252f39;
  border-color: #252f39;
}
.form-group input.with-icon {
  padding-right: 40px;
}
.form-group input:-webkit-autofill,
.form-group input:-webkit-autofill:hover,
.form-group input:-webkit-autofill:focus,
.form-group textarea:-webkit-autofill,
.form-group textarea:-webkit-autofill:hover,
.form-group textarea:-webkit-autofill:focus,
.form-group select:-webkit-autofill,
.form-group select:-webkit-autofill:hover,
.form-group select:-webkit-autofill:focus {
  border: 1px solid #252f39;
  -webkit-text-fill-color: #252f39;
  box-shadow: 0 0 0px 1000px #ffffff inset;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}
.form-group.error {
  position: relative;
  margin-bottom: 23px;
}
.form-group.error input {
  border-color: #ff2832;
}
.form-group .validation,
.form-group .validation_div {
  position: absolute;
}
.form-group .validation p,
.form-group .validation_div p {
  color: #ff2832;
  margin: 0;
  font-size: 11px !important;
}
.form-group .cn-no {
  width: 90px !important;
}
.form-group .cn-no input {
  border-radius: 0.25rem 0 0 0.25rem;
}
.form-group .ph-no {
  width: calc(100% - 90px) !important;
}
.form-group .ph-no input {
  border-radius: 0 0.25rem 0.25rem 0;
}
.form-group .pass-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #e1c391;
}
.form-group input::placeholder {
  color: #a5a5a5;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  opacity: 1;
}
.form-group input::placeholder::first-letter {
  text-transform: uppercase;
}
.form-group input:-ms-input-placeholder {
  color: #a5a5a5;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.form-group input:-ms-input-placeholder::first-letter {
  text-transform: uppercase;
}
.form-group input::-ms-input-placeholder {
  color: #a5a5a5;
  font-weight: 400;
  letter-spacing: 0.8px;
  font-size: 14px;
  line-height: 16px;
}
.form-group input::-ms-input-placeholder::first-letter {
  text-transform: uppercase;
}
.form-group textarea::placeholder {
  color: #a5a5a5;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}
.form-group textarea::placeholder::first-letter {
  text-transform: uppercase;
}
.form-group textarea:-ms-textarea-placeholder {
  color: #a5a5a5;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.form-group textarea:-ms-textarea-placeholder::first-letter {
  text-transform: uppercase;
}
.form-group textarea::-ms-textarea-placeholder {
  color: #a5a5a5;
  font-weight: 400;
  letter-spacing: 0.8px;
  font-size: 14px;
  line-height: 16px;
}
.form-group textarea::-ms-textarea-placeholder::first-letter {
  text-transform: uppercase;
}

.successpop {
  position: fixed;
  z-index: 9999;
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  right: 10px;
  top: 80px;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  display: none;
}
@media screen and (max-width: 415px) {
  .successpop {
    left: 10px;
    margin: 0 auto;
  }
}
.successpop.active {
  display: block;
}
.successpop img {
  margin: 0;
  min-width: 50px;
  width: 50px;
}
.successpop h4 {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.successpop span {
  font-size: 65px;
}
.successpop p {
  font-size: 14px;
  margin: 0;
}

/**footer**/
footer.copyright {
  background: #252f39;
  padding: 15px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
footer.copyright .bottom-links {
  text-align: right;
}
@media screen and (max-width: 991px) {
  footer.copyright .bottom-links {
    text-align: center;
  }
}
footer.copyright .bottom-links ul {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  padding: 0;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  footer.copyright .bottom-links ul {
    justify-content: center;
  }
}
footer.copyright .bottom-links ul li {
  position: relative;
}
footer.copyright .bottom-links ul li:after {
  content: "|";
  position: absolute;
  right: -2px;
  color: #fff;
}
footer.copyright .bottom-links ul li:nth-last-child(1):after {
  display: none;
}
footer.copyright .bottom-links ul li a {
  padding: 0 10px;
}
@media screen and (max-width: 991px) {
  footer.copyright {
    padding: 12px 0;
  }
}
@media screen and (max-width: 767px) {
  footer.copyright {
    text-align: center;
  }
}
footer.copyright img {
  max-width: 150px;
}
@media screen and (max-width: 991px) {
  footer.copyright img {
    max-width: 100px;
  }
}
@media screen and (max-width: 767px) {
  footer.copyright img {
    max-width: 90px;
  }
}
footer.copyright p {
  margin-bottom: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  footer.copyright p {
    margin: 5px 0px;
    text-align: center;
  }
}
footer.copyright a {
  display: inline-block;
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  footer.copyright a {
    text-align: center;
  }
}
footer.copyright a:hover {
  color: #8ffe26;
}

.fixed-footer-bottom {
  background-color: #252f39;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
@media screen and (max-width: 767px) {
  .fixed-footer-bottom {
    display: block;
    text-align: center;
    padding: 10px;
  }
}
@media screen and (max-width: 575px) {
  .fixed-footer-bottom {
    margin: 10px;
    border-radius: 7px;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.5);
  }
}
.fixed-footer-bottom p {
  color: #fff;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 300;
  margin-right: 20px;
}
.fixed-footer-bottom .cookies-btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .fixed-footer-bottom .cookies-btn-group {
    display: block;
    text-align: center;
    margin-top: 15px;
  }
}
.fixed-footer-bottom .cookies-btn-group .btn {
  background: #fd9902;
  color: #fff;
  font-size: 13px;
  border-radius: 35px;
  padding: 5px 25px;
  border: 1px solid #fd9902;
}
.fixed-footer-bottom .cookies-btn-group .btn:hover {
  background-color: #8ffe26;
  border-color: #8ffe26;
  color: #000;
}
.fixed-footer-bottom .cookies-btn-group .btn.decline {
  background-color: transparent;
  border: 1px solid #fd9902;
  color: #fff;
}
.fixed-footer-bottom .cookies-btn-group .btn.decline:hover {
  border-color: #8ffe26;
}

.no-data img {
  max-width: 160px;
  margin: 0 0 20px;
}

.feedback-dilog .mat-dialog-title {
  margin: -9px -24px 15px;
  display: block;
  padding: 0 24px 15px;
  border-bottom: 1px solid #e9e9e9;
  position: relative;
}
.feedback-dilog .mat-dialog-actions {
  margin: 0 -24px -24px;
  display: block;
  padding: 15px 24px;
  border-top: 1px solid #e9e9e9;
}
.feedback-dilog h2 {
  font-size: 22px;
  margin: 0;
  color: #404040;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.feedback-dilog a {
  color: #ccc;
  text-decoration: none;
}
.feedback-dilog a:focus, .feedback-dilog a:active {
  outline: none;
  box-shadow: none;
}
.feedback-dilog p {
  font-size: 14px;
  color: #707070;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 22px;
}
.feedback-dilog p:nth-last-child(1) {
  margin-bottom: 0;
}
.feedback-dilog label {
  font-size: 14px;
  color: #252f39;
  font-weight: 600;
  margin: 0;
}
.feedback-dilog .form-group label {
  color: rgba(37, 47, 57, 0.7);
}
.feedback-dilog .btn {
  padding: 4px 20px;
}

.filter {
  padding: 10px 0;
}
.filter h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}
.filter ul {
  padding: 0;
  margin: 0;
}
.filter ul li {
  margin: 0 0 15px;
}
.filter ul li label {
  font-weight: 400;
  font-size: 13px;
}

.social-list {
  display: flex !important;
  position: absolute;
}

.social-list {
  display: flex !important;
  position: absolute;
}

.fab-container {
  position: static;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.fab-container > div {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 5px;
}
.fab-container > div button {
  margin-bottom: 17px;
}

.fab-toggler {
  float: right;
  z-index: 100;
}

ul.social-list {
  top: 40px !important;
  right: 18px !important;
}
ul.social-list.recipe-social-list {
  top: -7px !important;
  right: 37px !important;
}
@media screen and (max-width: 575px) {
  ul.social-list.recipe-social-list {
    top: -52px !important;
    right: 0px !important;
    left: 0;
  }
}
ul.social-list.coupons-social-list {
  top: 0px !important;
  right: 37px !important;
}
@media screen and (max-width: 575px) {
  ul.social-list.coupons-social-list {
    top: -52px !important;
    right: 0px !important;
    left: 0;
  }
}

ul.social-list .fab-secondary {
  background: #fff;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 5px;
}

.fab-secondary .sb-facebook {
  background: #395693;
  color: #fff;
  border: none;
  width: 35px;
  height: 35px;
}

.fab-secondary .sb-twitter {
  background: #1c9cea;
  color: #fff;
  border: none;
  width: 35px;
  height: 35px;
}

.video-js .vjs-big-play-button {
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 40% !important;
}

.video-js:hover .vjs-big-play-button {
  background: rgba(143, 254, 38, 0.32) !important;
}
.video-js .vjs-big-play-button {
  left: 0 !important;
  right: 0 !important;
  top: calc(50% - 30px) !important;
  height: 60px !important;
  width: 60px !important;
  min-width: 60px !important;
  border-radius: 100% !important;
  background: #8ffe26 !important;
  line-height: 60px !important;
  border: none !important;
  margin: 0 auto;
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  font-size: 45px !important;
}

.vjs-error .vjs-error-display:before {
  display: none !important;
}

.mat-tab-body.mat-tab-body-active {
  animation: fade 0.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.lb-dataContainer {
  padding-top: 0px !important;
  background: #252f39;
}

.lb-data {
  padding: 5px 10px;
  color: #ccc;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.lightbox .lb-image {
  border-radius: 0px;
}

.video-js,
.vjs-playlist {
  min-width: 272px !important;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1270px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1470px;
  }
}

.lb-data .lb-close {
  background-size: 15px !important;
  position: relative;
  top: 7px !important;
}

.lb-data .lb-caption {
  color: #8ffe26 !important;
}

.color-white {
  color: #ffffff !important;
}

.product-detail-image {
  width: 500px;
}
@media screen and (max-width: 767px) {
  .product-detail-image {
    width: 100%;
  }
}
.product-detail-image.coupon-detail-image {
  width: 80px;
  height: 80px;
}
.product-detail-image .coupon-image-box {
  width: 80px;
  height: 80px;
}

.image-ratio1-1 {
  padding-bottom: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative !important;
  cursor: pointer;
}
.image-ratio1-1 img {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  max-width: 100% !important;
}

.image-ratio16-9 {
  padding-bottom: 56.25% !important;
  width: 100% !important;
  overflow: hidden !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative !important;
}
.image-ratio16-9 img,
.image-ratio16-9 .video-ratio {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  max-width: 100% !important;
}

@media (max-width: 768px) {
  .container {
    max-width: 95% !important;
  }
}
@media (max-width: 575px) {
  .container {
    max-width: 100% !important;
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-text-fill-color: #000 !important;
}

input:-moz-autofill {
  -moz-text-fill-color: #000 !important;
  -moz-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}

input:-o-autofill {
  -o-text-fill-color: #000 !important;
  -o-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}

input:-khtml-autofill {
  -khtml-text-fill-color: #000 !important;
  -khtml-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}

.postion-relative {
  position: relative;
}

@media screen and (max-width: 575px) {
  .recipe-share {
    margin-right: 10px;
    border-top: 1px solid #3d3d3d;
    padding-top: 10px;
    margin-top: 10px;
    width: 100%;
  }
}
.recipe-share .product-detail-share-icon {
  display: flex;
}
.recipe-share .product-detail-share-icon li {
  display: block;
}
.recipe-share .product-detail-share-icon li a {
  color: #c5cbcf;
  font-size: 25px;
  padding: 0 5px;
  text-decoration: none;
  box-shadow: none;
  display: block;
  line-height: 0;
}
@media screen and (max-width: 767px) {
  .recipe-share .product-detail-share-icon li a {
    font-size: 22px;
  }
}
.recipe-share .product-detail-share-icon li a .icon-heart-fill {
  color: #ff2832;
}

@media screen and (max-width: 991px) {
  .fav-recipes {
    padding: 30px 0 0;
  }
}
@media screen and (max-width: 575px) {
  .fav-recipes {
    padding: 5px 0 0;
  }
}
.fav-recipes .recipe-img-outer {
  width: 80px;
  height: 80px;
}
.fav-recipes .recipe {
  padding: 0;
  background: transparent;
  width: 100%;
  cursor: default;
}
.fav-recipes .recipe h3 {
  font-size: 16px;
  color: #8ffe26;
}
@media screen and (max-width: 575px) {
  .fav-recipes .recipe h3 {
    font-size: 14px;
    position: absolute;
    top: -80px;
    left: 90px;
  }
}
.fav-recipes .recipe .recipe-img-outer {
  width: 80px;
  margin-right: 10px;
}
.fav-recipes .recipe .recipe-img {
  padding-top: 100%;
  width: 100%;
  overflow: hidden;
  background: #bcbcbc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  min-width: 80px;
  cursor: pointer;
}
.fav-recipes .recipe .recipe-img img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
}
.fav-recipes .recipe .recipe-img .discount-label {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  background: #8ffe26;
  color: #000;
  font-size: 12px !important;
  padding: 3px 5px 1px 6px !important;
  font-weight: bold;
  border-radius: 0 0 0px 10px;
}
@media screen and (max-width: 575px) {
  .fav-recipes .recipe .recipe-detail {
    width: 100%;
    padding: 13px 0 0;
  }
}
.fav-recipes .recipe .recipe-detail h3 {
  color: #252f39;
  margin: 0 0 10px;
  font-weight: bold;
  font-size: 16px;
}
@media screen and (max-width: 575px) {
  .fav-recipes .recipe .recipe-detail h3 {
    margin: 0 0 10px;
    font-size: 18px;
  }
}
.fav-recipes .recipe .recipe-detail .nutrains ul {
  display: flex;
  align-content: center;
}
.fav-recipes .recipe .recipe-detail .nutrains ul li {
  padding: 0 0;
  margin-right: 35px;
}
@media screen and (max-width: 575px) {
  .fav-recipes .recipe .recipe-detail .nutrains ul li {
    margin-right: 10px;
  }
}
.fav-recipes .recipe .recipe-detail .nutrains ul li.discount-list {
  margin-right: 80px;
}
@media screen and (max-width: 575px) {
  .fav-recipes .recipe .recipe-detail .nutrains ul li.discount-list {
    margin-right: 30px;
  }
}
.fav-recipes .recipe .recipe-detail .nutrains ul li h4 {
  color: #ffffff;
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 14px;
}
@media screen and (max-width: 575px) {
  .fav-recipes .recipe .recipe-detail .nutrains ul li h4 {
    font-size: 14px;
  }
}
.fav-recipes .recipe .recipe-detail .nutrains ul li p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 0;
}
@media screen and (max-width: 1400px) {
  .fav-recipes .recipe .recipe-detail .nutrains ul li p {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .fav-recipes .recipe .recipe-detail .nutrains ul li p {
    font-size: 13px;
  }
}
.fav-recipes .mat-tab-header-pagination {
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
  background: #252f39;
}
.fav-recipes .mat-tab-header-pagination .mat-tab-header-pagination-chevron {
  border-color: #fff;
}
.fav-recipes .mat-expansion-panel-header {
  height: auto !important;
  padding: 0;
  align-items: self-start !important;
}
.fav-recipes .mat-expansion-panel-header:hover {
  background: transparent !important;
}
.fav-recipes .mat-expansion-indicator::after,
.fav-recipes .mat-expansion-panel-header-description {
  color: rgba(255, 255, 255, 0.54);
}
.fav-recipes .mat-expansion-panel-header-title {
  margin-right: 0;
}
@media screen and (max-width: 575px) {
  .fav-recipes .mat-expansion-panel-header-title {
    display: inline-block !important;
  }
}
.fav-recipes mat-expansion-panel.mat-expansion-panel {
  padding: 17px;
  border: 0px solid #414a53;
  border-radius: 6px;
  background: #1a2128;
  margin: 0 5px 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}
@media screen and (max-width: 767px) {
  .fav-recipes mat-expansion-panel.mat-expansion-panel {
    padding: 10px;
    margin: 0 0px 20px;
  }
}
.fav-recipes .mat-expansion-panel-content {
  font-family: "Poppins", sans-serif;
}
.fav-recipes .mat-expansion-panel-body {
  padding: 20px 0 0 0 !important;
}
.fav-recipes .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.fav-recipes .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.fav-recipes .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: transparent;
}
.fav-recipes .mat-tab-group {
  font-family: "Poppins", sans-serif;
}
.fav-recipes .mat-tab-group .mat-tab-body-content {
  overflow: hidden;
}
.fav-recipes .mat-tab-group.mat-primary .mat-ink-bar,
.fav-recipes .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  display: none;
}
.fav-recipes .food-contet h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #8ffe26;
}
.fav-recipes .food-contet.ingredients-items {
  border: 1px solid #414a53;
  padding: 25px !important;
  background: #1a2128;
  border-radius: 0;
  margin: 0 0 20px;
}
.fav-recipes .food-contet.ingredients-items ul li {
  padding-left: 22px;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}
.fav-recipes .food-contet ul li {
  position: relative;
  padding-left: 25px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
  color: #ffffff;
}
.fav-recipes .food-contet ul li:after {
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  border-radius: 5px;
  position: absolute;
  left: 5px;
  top: 5px;
}
.fav-recipes .food-contet ul li h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
}
.fav-recipes .food-contet ul li p {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1400px) {
  .fav-recipes .food-contet ul li p {
    font-size: 14px;
  }
}
.fav-recipes .food-contet.nutrition-data ul {
  padding: 10px 10px 0px;
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
}
@media screen and (max-width: 575px) {
  .fav-recipes .food-contet.nutrition-data ul {
    padding: 0px;
  }
}
.fav-recipes .food-contet.nutrition-data ul li {
  display: flex;
  align-items: flex-start;
  padding: 0 60px 0 25px;
  margin-bottom: 20px;
  flex: 0 0 50%;
  justify-content: space-between;
  max-width: 50%;
}
@media screen and (max-width: 767px) {
  .fav-recipes .food-contet.nutrition-data ul li {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.fav-recipes .food-contet.nutrition-data ul li span {
  width: 50%;
}
.fav-recipes .food-contet.nutrition-data ul li span:nth-last-child(1) {
  text-align: right;
}
.fav-recipes .food-contet.nutrition-data ul li .yes {
  background: #1E4976;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  color: #1a2128;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  overflow: hidden;
}
.fav-recipes .food-contet.source-traceblity {
  background: transparent !important;
}
.fav-recipes .food-contet.source-traceblity .traceblity-list {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  margin-bottom: 15px;
  padding-bottom: 10px;
  word-break: break-all;
}
.fav-recipes .food-contet.source-traceblity .traceblity-list .date {
  font-weight: 500;
  font-size: 14px;
  display: flex;
}
.fav-recipes .food-contet.source-traceblity .traceblity-list .date i {
  font-size: 17px;
  color: #8ffe26;
}
.fav-recipes .food-contet.source-traceblity .traceblity-list .text-secondary {
  font-weight: 500;
  padding-left: 18px;
}
.fav-recipes .food-contet.source-traceblity .traceblity-list:last-child {
  margin-bottom: 0px !important;
}
.fav-recipes .brand {
  padding: 20px;
}
@media screen and (max-width: 767px) {
  .fav-recipes .brand {
    padding: 0;
  }
}
.fav-recipes .brand .brand-logo {
  min-width: 150px;
  border-radius: 10px;
  width: 150px;
  overflow: hidden;
}
.fav-recipes .brand .brand-logo img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
.fav-recipes .brand .brand-details {
  padding: 0 0 0 30px;
}
@media screen and (max-width: 767px) {
  .fav-recipes .brand .brand-details {
    padding: 15px 0 0;
    width: 100%;
  }
}
.fav-recipes .brand .brand-details strong {
  font-weight: 600;
  color: #252f39;
}
.fav-recipes .brand .brand-details p {
  font-size: 16px;
  color: rgba(37, 47, 57, 0.8);
  font-weight: 400;
  margin-bottom: 10px;
}
@media screen and (max-width: 1400px) {
  .fav-recipes .brand .brand-details p {
    font-size: 14px;
  }
}
.fav-recipes .brand .brand-details .add {
  font-size: 16px;
}
.fav-recipes .brand .brand-details .add strong {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.62);
  font-size: 14px;
}
.fav-recipes .brand .brand-details .add p {
  display: flex;
  align-items: flex-start;
  color: #ffffff;
  font-size: 16px;
  word-break: break-word;
}
.fav-recipes .brand .brand-details .add p span {
  margin-right: 5px;
  font-size: 20px;
  color: #ffffff;
}
.fav-recipes .brand .brand-details .add p a {
  color: #ffffff;
}
.fav-recipes .mat-accordion > .mat-expansion-panel-spacing:last-child,
.fav-recipes .mat-accordion > :last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
  margin-bottom: 20px;
}

.up-arrow .icon-up-arrow::before {
  transform: rotate(180deg);
}

.mat-expansion-indicator::after {
  padding: 6px !important;
  border-width: 0 3px 3px 0 !important;
  border-color: #c5cbcf !important;
}

.mat-action-row {
  border: none !important;
}
@media screen and (max-width: 575px) {
  .mat-action-row {
    padding: 0px !important;
  }
}

.my-fav-tab .mat-tab-header {
  background: transparent !important;
  padding: 35px 0 0px !important;
}
.my-fav-tab .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
  background: transparent;
  height: auto;
  min-width: auto;
  border: 2px solid #e9e9e9;
  margin: 0 10px;
  border-radius: 50px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #000;
  padding: 7px 25px;
  overflow: hidden !important;
}
@media screen and (max-width: 575px) {
  .my-fav-tab .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
    padding: 7px 10px;
    font-size: 13px;
  }
}
.my-fav-tab .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background: #8ffe26 !important;
  opacity: 1;
  color: #252f39;
  border-color: #8ffe26;
  position: relative;
  overflow: hidden !important;
}
.my-fav-tab .mat-tab-body-wrapper {
  border-top: 0px !important;
}

.coupons-detail {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  padding: 13px;
  border-radius: 10px;
}
.coupons-detail h3 {
  color: #8ffe26;
  font-size: 25px;
}
.coupons-detail .discount-label-coupon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  background: #8ffe26;
  color: #000;
  font-size: 12px !important;
  padding: 3px 5px 1px 6px !important;
  font-weight: bold;
  border-radius: 0 0 0px 10px;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background: transparent !important;
}

.coupon-image {
  max-width: 150px;
  max-height: 120px;
}

.content-detail-source p {
  display: inline;
}

.disount-detail-list {
  margin-bottom: 20px;
}
.disount-detail-list h4 {
  font-size: 14px !important;
  margin-bottom: 6px;
}
.disount-detail-list p {
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.mat-expansion-panel-header {
  font-family: "Poppins", sans-serif !important;
}

.color-gray20 {
  color: #e9e9e9;
}

.coupon-detail-box h5 {
  position: relative;
}
@media screen and (max-width: 575px) {
  .coupon-detail-box h5 {
    position: absolute;
    top: 20px;
    left: 122px;
  }
}
@media screen and (max-width: 575px) {
  .coupon-detail-box .disount-detail-list {
    margin-top: 15px;
  }
}

.smartbanner {
  height: 100px !important;
  background: #252f39 !important;
}
.smartbanner .smartbanner__info {
  height: auto !important;
}
.smartbanner .smartbanner__info .smartbanner__info--title {
  color: #fff !important;
  font-weight: 700 !important;
}
.smartbanner .smartbanner__info .smartbanner__info--author,
.smartbanner .smartbanner__info .smartbanner__info--price {
  color: #ccc !important;
}
.smartbanner .smartbanner__icon {
  background-repeat: no-repeat;
  width: 55px;
  height: 55px;
  background-size: 55px 55px;
}
.smartbanner .smartbanner__button {
  color: #000 !important;
  background: #83e92a !important;
  border: 0 !important;
  min-width: 12%;
  border-radius: 50px;
  align-self: flex-end;
  margin-bottom: 10px;
  font-size: 14px !important;
  padding: 5px 10px !important;
  font-weight: 700 !important;
}
.smartbanner .smartbanner__exit {
  position: absolute !important;
  right: 9px;
  top: 10px;
  background: #ffffff !important;
  box-shadow: none !important;
  color: #000000 !important;
  width: 18px !important;
  height: 18px !important;
  border-radius: 14px !important;
  margin-right: 0 !important;
  padding: 0 !important;
}
.smartbanner .smartbanner__exit::after, .smartbanner .smartbanner__exit::before {
  background: #000000 !important;
  top: 3px !important;
  left: 8px !important;
  width: 2px !important;
  height: 11px !important;
}

/***** search option style ****/
.search-option {
  max-height: 400px !important;
  border-radius: 0 0 21.5px 21.5px !important;
  border: 1px solid #7d7d7d;
}
.search-option .mat-option {
  line-height: inherit;
  padding: 10px 30px;
  height: auto;
  border-top: 1px solid #7d7d7d;
}
.search-option .mat-option:first-child {
  border: none !important;
}
.search-option .mat-option .mat-option-text {
  display: flex;
  align-items: center;
}
.search-option .mat-option .mat-option-text .product-img {
  width: 100px;
  min-width: 100px;
  overflow: hidden;
  border-radius: 3px;
  margin-right: 10px;
  position: relative;
}
.search-option .mat-option .mat-option-text .product-img::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}
.search-option .mat-option .mat-option-text .product-img img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.search-option .mat-option .mat-option-text h6 {
  font-weight: 400;
  font-size: 16px;
  color: #313131;
  margin-bottom: 5px;
}
.search-option .mat-option .mat-option-text small {
  font-weight: 400;
  font-size: 12px;
  color: #32cd32;
}

.headers {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  padding: 10px 0;
  display: flex;
  align-items: center;
  background: #ffffff;
  z-index: 9;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
@media screen and (max-width: 991px) {
  .headers {
    padding: 10px 0 0;
    height: auto;
    background: #fff;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.08);
  }
}
@media screen and (max-width: 991px) {
  .headers .container {
    max-width: 100% !important;
    padding: 0px 0px;
  }
}
.headers.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background: #ffffff;
  padding: 10px 0;
  min-height: inherit;
  height: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}
@media screen and (max-width: 991px) {
  .headers.fixed-header {
    padding: 10px 0 0;
  }
}
.headers.fixed-header .navbar .navbar-brand img {
  max-width: 150px;
  max-height: 65px;
}
@media screen and (max-width: 991px) {
  .headers.fixed-header .navbar .navbar-brand img {
    max-width: 80px;
    max-height: 55px;
  }
}
.headers.fixed-header .navbar ul li a {
  color: #252f39 !important;
  font-size: 15.3px;
}
@media screen and (max-width: 991px) {
  .headers.fixed-header .navbar ul li a {
    color: #ffffff !important;
  }
}
.headers.fixed-header .navbar ul li a.login-btn {
  margin-left: 15px;
}
@media screen and (max-width: 991px) {
  .headers.fixed-header .navbar ul li a.login-btn {
    margin-left: 0;
  }
}
.headers .navbar {
  padding: 0;
}
.headers .navbar .mobile-header {
  line-height: 0;
}
@media screen and (max-width: 991px) {
  .headers .navbar .mobile-header {
    max-width: 95%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 15px 10px;
  }
}
.headers .navbar .navbar-brand {
  padding: 0;
  margin: 0;
  width: 200px;
}
.headers .navbar .navbar-brand:focus {
  box-shadow: none;
  outline: none;
}
@media screen and (max-width: 991px) {
  .headers .navbar .navbar-brand {
    padding: 0;
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  .headers .navbar .navbar-brand {
    width: 50px;
  }
}
.headers .navbar .navbar-brand .image-ratio1-1 {
  transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
}
.headers .navbar .navbar-brand img {
  max-width: 200px;
  max-height: 80px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
@media screen and (max-width: 991px) {
  .headers .navbar .navbar-brand img {
    max-height: 70px;
  }
}
@media screen and (max-width: 767px) {
  .headers .navbar .navbar-brand img {
    max-height: 50px;
  }
}
@media screen and (max-width: 575px) {
  .headers .navbar .navbar-brand img {
    max-height: 48px;
  }
}
.headers .navbar .navbar-brand img.logo-sroll {
  display: none;
}
@media screen and (max-width: 991px) {
  .headers .navbar .navbar-brand img.logo-sroll {
    display: block;
  }
}
.headers .navbar .navbar-toggler {
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
}
.headers .navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
.headers .navbar .navbar-toggler span.navbar-icon {
  background: #252f39;
  height: 4px;
  width: 35px;
  display: block;
  border-radius: 5px;
}
@media screen and (max-width: 991px) {
  .headers .navbar .navbar-toggler span.navbar-icon {
    height: 2px;
    width: 31px;
  }
}
@media screen and (max-width: 767px) {
  .headers .navbar .navbar-toggler span.navbar-icon {
    height: 1px;
    width: 25px;
  }
}
.headers .navbar .navbar-toggler span.navbar-icon.two {
  margin: 5px 0;
}
@media screen and (max-width: 991px) {
  .headers .navbar .navbar-collapse .collapse .navbar-nav {
    display: none;
  }
}
.headers .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #ffffff;
}
@media screen and (max-width: 991px) {
  .headers .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    text-align: left;
  }
}
@media screen and (max-width: 991px) {
  .headers .navbar .navbar-collapse {
    background: #1d252d;
    padding: 15px 35px !important;
    box-shadow: none;
  }
}
@media screen and (max-width: 767px) {
  .headers .navbar .navbar-collapse {
    padding: 15px 25px !important;
  }
}
@media screen and (max-width: 991px) {
  .headers .navbar ul.navbar-nav {
    display: inherit;
  }
}
.headers .navbar ul li {
  margin: 0;
}
.headers .navbar ul li.dropdown {
  padding: 0;
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li.dropdown {
    margin-top: 0;
  }
}
.headers .navbar ul li.dropdown .dropdown-toggle:hover {
  background: transparent !important;
  color: #38b6ff !important;
  text-decoration: none;
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li.dropdown .dropdown-toggle:hover {
    color: #38b6ff !important;
  }
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li.dropdown .dropdown-toggle {
    padding: 0;
  }
}
.headers .navbar ul li .nav-profile {
  width: 35px;
  overflow: hidden;
  border-radius: 50%;
}
.headers .navbar ul li .nav-name p {
  margin: 0;
}
.headers .navbar ul li .nav-name:hover {
  color: #38b6ff !important;
}
.headers .navbar ul li .nav-name:hover .caret {
  color: #705446 !important;
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li .nav-name:hover {
    position: relative;
  }
}
.headers .navbar ul li .dropdown-menu {
  left: auto !important;
  right: 0 !important;
  padding: 4px 0;
  min-width: 120px;
  border-radius: 7px;
  border: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  top: 50px;
}
.headers .navbar ul li .dropdown-menu:after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  background: #ffffff;
  right: 15px;
  top: -5px;
  z-index: -1;
  transform: rotate(45deg);
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li .dropdown-menu:after {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li .dropdown-menu {
    padding: 0 0 0 20px;
    width: 100%;
    border: none;
    background: transparent;
    box-shadow: none;
  }
}
.headers .navbar ul li .dropdown-menu h4 {
  font-size: 16px;
  margin: 0 0 5px;
}
.headers .navbar ul li .dropdown-menu p {
  font-size: 13px;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #fffcf2;
  white-space: nowrap;
}
.headers .navbar ul li .dropdown-menu li {
  margin: 0;
}
.headers .navbar ul li .dropdown-menu li a {
  padding: 5px 15px !important;
  color: #252f39 !important;
  font-size: 13px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li .dropdown-menu li a {
    padding: 6px 0 !important;
    color: #ffffff !important;
  }
}
.headers .navbar ul li .dropdown-menu li a.active {
  background: #0DC0DD !important;
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li .dropdown-menu li a.active {
    color: #0DC0DD !important;
    background: transparent !important;
  }
}
.headers .navbar ul li .dropdown-menu li a:hover, .headers .navbar ul li .dropdown-menu li a:active, .headers .navbar ul li .dropdown-menu li a:focus {
  color: #ffffff !important;
  cursor: pointer;
  outline: none;
  background: #0DC0DD;
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li .dropdown-menu li a:hover, .headers .navbar ul li .dropdown-menu li a:active, .headers .navbar ul li .dropdown-menu li a:focus {
    color: #0DC0DD !important;
    background: transparent;
  }
}
.headers .navbar ul li a {
  font-size: 18px;
  font-weight: 400;
  color: #000000 !important;
  padding: 8px 10px !important;
  letter-spacing: 0.2px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
@media screen and (max-width: 1366px) {
  .headers .navbar ul li a {
    font-size: 16px;
  }
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li a {
    padding: 5px 0 !important;
    color: #ffffff !important;
    font-size: 14px;
  }
}
.headers .navbar ul li a.login-btn {
  background: #1E4976;
  border-radius: 10px;
  padding: 5px 25px !important;
  display: block;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  color: #ffffff !important;
  margin-left: 30px;
  width: 95px;
  height: 32px;
}
.headers .navbar ul li a.login-btn:hover {
  background: #ffa710;
  color: #252f39 !important;
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li a.login-btn:hover {
    background: transparent;
    color: #8ffe26 !important;
  }
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li a.login-btn {
    margin-left: 0;
    background: transparent;
    padding: 5px 0 !important;
    box-shadow: none;
    font-size: 14px;
    color: #fff !important;
    font-weight: 400;
  }
}
.headers .navbar ul li a:hover {
  color: #38b6ff !important;
}
.headers .navbar ul li a:hover .caret {
  color: #705446 !important;
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li a:hover {
    position: relative;
  }
}
.headers .navbar ul li a.active {
  position: relative;
  color: #1e4976 !important;
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li a.active {
    background: transparent;
    color: #1e4976 !important;
  }
}
.headers .navbar ul li a.active:before {
  content: "";
  height: 1px;
  background: #1e4976 !important;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 0px);
  margin: 0 0px;
}
@media screen and (max-width: 991px) {
  .headers .navbar ul li a.active:before {
    margin: 0;
    width: 100%;
    height: 0;
  }
}

footer.copyrights {
  background: #1E4976;
  padding: 10px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
footer.copyrights .email-btn {
  margin-top: 2px;
}
footer.copyrights .bottom-links {
  text-align: right;
}
@media screen and (max-width: 991px) {
  footer.copyrights .bottom-links {
    text-align: center;
  }
}
footer.copyrights .bottom-links ul {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  padding: 0;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  footer.copyrights .bottom-links ul {
    justify-content: center;
  }
}
footer.copyrights .bottom-links ul li {
  margin-right: 5px;
}
footer.copyrights .bottom-links ul li .linkedIn:hover {
  content: url("/assets/images/linkedIn-blue.png");
  width: 32px;
  height: 30px;
}
footer.copyrights .bottom-links ul li .facebook:hover {
  content: url("/assets/images/facebook-blue.png");
  width: 31px;
  height: 30px;
}
@media screen and (max-width: 991px) {
  footer.copyrights {
    padding: 12px 0;
  }
}
@media screen and (max-width: 767px) {
  footer.copyrights {
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  footer.copyrights .email-btn {
    margin-top: 8px;
  }
}
footer.copyrights img {
  max-width: 150px;
}
@media screen and (max-width: 991px) {
  footer.copyrights img {
    max-width: 100px;
  }
  footer.copyrights img .email-btn {
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  footer.copyrights img {
    max-width: 90px;
  }
}
footer.copyrights p {
  margin-bottom: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  footer.copyrights p {
    margin: 5px 0px;
    text-align: center;
  }
}
footer.copyrights a {
  display: inline-block;
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  footer.copyrights a {
    text-align: center;
  }
}
footer.copyrights a:hover {
  color: #38b6ff;
}

.home-page .video-js .vjs-big-play-button {
  background: #FF8719 !important;
}

.contact-us-dialog mat-dialog-container {
  border-radius: 20px;
  padding: 0px !important;
}